import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material'; // Import Material-UI components

const BASE_URL = "https://buildfastapi.kollagroup.in/output/";

const DocumentTable = () => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    axios.get('https://buildfastapi.kollagroup.in/create-customer/api/v1/api/customer-docs')
      .then(response => {
        setDocuments(response.data);
      })
      .catch(error => {
        console.error('Error fetching documents:', error);
      });
  }, []);

  const handleApprove = (ucin) => {
    axios.post('https://buildfastapi.kollagroup.in/create-customer/api/v1/api/approve-doc', { ucin })
      .then(response => {
        alert(response.data.message);
        // Optionally, refetch or update the table
        setDocuments(prevDocs =>
          prevDocs.map(doc =>
            doc.ucin === ucin ? { ...doc, document_status: 'Approved' } : doc
          )
        );
      })
      .catch(error => {
        console.error('Error approving document:', error);
      });
  };

  // const handleFileOpen = (url) => {
  //   // Create a temporary anchor element
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.target = '_blank'; // Open in a new tab
  //   link.rel = 'noopener noreferrer'; // Security improvement
  //   link.download = ''; // Suggests downloading the file
  
  //   // Append the anchor to the body
  //   document.body.appendChild(link);
  
  //   // Trigger a click on the anchor
  //   link.click();
  
  //   // Remove the anchor from the body
  //   document.body.removeChild(link);
  // };
  
  const handleFileOpen = (url) => {
    // Forcing download
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'; // Open in a new tab
    link.rel = 'noopener noreferrer'; // Security improvement
    link.download = ''; // Suggests downloading the file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="mb-4 mt-1">
        <Typography
          style={{
            textAlign: "center",
            fontWeight: "500",
            color: "rgb(126, 110, 228)",
            fontSize: 21,
          }}
        >
          Document Management
        </Typography>
      </div>
      <TableContainer>
        <Table>
          <TableHead sx={{ borderTop: "1px solid lightgray" }}>
            <TableRow>
              <TableCell align={"start"} style={{ fontSize: 14, fontWeight: "600" }}>
                Customer Name
              </TableCell>
              <TableCell align={"left"} style={{ fontWeight: "600" }}>
                UCIN
              </TableCell>
              <TableCell align={"left"} style={{ fontWeight: "600" }}>
                Document URL
              </TableCell>
              <TableCell align={"left"} style={{ fontWeight: "600" }}>
                Status
              </TableCell>
              <TableCell align={"left"} style={{ fontWeight: "600" }}>
                Download
              </TableCell>
              <TableCell align={"left"} style={{ fontWeight: "600" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map(doc => (
              <TableRow
                hover
                key={doc.ucin}
                style={{
                  color: doc.document_status === "Pending" ? "inherit" : "green",
                }}
              >
                <TableCell align={"start"} style={{ fontSize: 12 }}>
                  {doc.customerName}
                </TableCell>
                <TableCell align={"start"} style={{ fontSize: 12 }}>
                  {doc.ucin}
                </TableCell>
                <TableCell align={"start"} style={{ fontSize: 12 }}>
                  {doc.document_path}
                </TableCell>
                <TableCell align={"start"} style={{ fontSize: 12 }}>
                  {doc.document_status}
                </TableCell>
                <TableCell align={"start"} style={{ fontSize: 12 }}>
          <a href={doc.document_path} download={doc.document_path.split('/').pop()}>
            <Button variant="outlined" color="primary"  onClick={() => handleFileOpen(doc.document_path)}>
              Download
            </Button>
          </a>
        </TableCell>
                <TableCell align={"start"} style={{ fontSize: 12 }}>
                  {doc.document_status === 'Pending' && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleApprove(doc.ucin)}
                    >
                      Approve
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DocumentTable;
