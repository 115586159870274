import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Menu, MenuItem, TableBody, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2';
import { FaEdit, FaMapMarkerAlt, FaMarker, FaTrash, FaUser, FaUserAlt } from 'react-icons/fa';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import moment from 'moment';


export default function StoreEntry() {

    const [managerName, setManagerName] = useState()
    const [projectName, setProjectName] = useState()
    const [desc, setDesc] = useState()
    const [location, setLocation] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [id, setId] = useState()

    const [islno, setIslno ] = useState('')
    const [ddate, setDdate] = useState('')
    const [product, setProduct] = useState('')
    const [uom, setUom] = useState('')
    const [qnty, setQnty] = useState()
    const [ ub, setUb] = useState('')



    const add_store = () => {
        axios.post("https://buildfastapi.kollagroup.in/pmaster/api/v1/add/storentry", {
            Issueno:islno,date:ddate,Product:product,UOM:uom,Quantity:qnty,Unit_Block:ub
        }).then((res) => {
            if (res.data.status === 200) {
                setOpen(false)
                get_alldata()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const [response, setResponse] = useState([])

    const get_basic = () => {
        axios.get("https://buildfastapi.kollagroup.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basic()
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
        setOpen1(open1)
    };

    const handleClose2 = () => {
        setAnchorEl(null);
        setOpen1(false);
    };

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_store()
        }

        setValidated(true);
    };

    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result.value) {
                basicDataDelete(id)
            }
        })
    }

    const basicDataDelete = (id) => {
        axios.post('https://buildfastapi.kollagroup.in/pmaster/api/v1/del-store', {
            Id:id
        }).then((res) => {
            if (res.data.status === 200) {
                get_alldata()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Basic is deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const editAlert = () => {
        setOpen1(false)
        handleCloseed()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Edit"
        }).then((result) => {
            if (result.value) {
                basicDataEdit(id)
            }
        })
    }

    const basicDataEdit = (id) => {
        axios.post("https://buildfastapi.kollagroup.in/pmaster/api/v1/store-edit", {
            Issueno:islno,date:ddate,Product:product,UOM:uom,Quantity:qnty,Unit_Block:ub,Id:id
        }).then((res) => {
            if (res.data.status === 200) {
                     setOpen(false)
                    get_alldata()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Basic is updated successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                
            }
        })
    }

    const [open1, setOpen1] = useState(false)

    const viewData = () => {
        axios.post('https://buildfastapi.kollagroup.in/pmaster/api/v1/view-storentry', {
            Id:id
        }).then((res) => {
            setIslno(res.data.Issueno)
            setDdate(res.data.date)
            setProduct(res.data.Product)
            setUom(res.data.UOM)
            setQnty(res.data.Quantity)
            setUb(res.data.Unit_Block)
            setOpen1(true)
        })
    }

    const searchBasic = (text) => {
        axios.post('https://buildfastapi.kollagroup.in/project/api/v1/search-basic', {
            text
        }).then((res) => {

            setResponse(res.data)

        })
    }

   
    const [opened, setOpened] = React.useState(false);

  const handleClickOpened = () => {
    setOpened(true);
  };

  const handleCloseed = () => {
    setOpened(false);
  };

  const [response1, setResponse1] = useState([])

  const get_prom = () => {
      axios.get("https://buildfastapi.kollagroup.in/pmaster/api/v1/get-prouom").then((res) => {
          if (res.data.status === 200) {
              setResponse1(res.data.data)
          }
      })
  }

  const [floor, setFloor] = useState([])

  const get_floor = (val) => {
      axios.get("https://buildfastapi.kollagroup.in/project/api/v1/get-floor").then((res) => {
          if (res.data.status === 200) {
              setFloor(res.data.data)
              console.log(res.data.data);
          }
      })
  }

  const [response2, setResponse2] = useState([])

  const get_alldata = () => {
      axios.get("https://buildfastapi.kollagroup.in/pmaster/api/v1/store-getall").then((res) => {
          if (res.data.status === 200) {
              setResponse2(res.data.data)
          }
      })
  }

  React.useEffect(() => {
      get_prom()
      get_floor()
      get_alldata()
     
  }, [])

    return (
        <div>

            <div >
                <Paper elevation={0} >

                    <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                        <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>

                        <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                        <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                            <div>

                                <input onChange={(e) => searchBasic(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search user..' />
                            </div>
                            <div>
                                <Button onClick={() => setOpen(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Store</Button>
                            </div>
                        </div>
                        <Table style={{ position: 'relative', top: '1.3rem' }}>
                            <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                <TableRow>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Issue no 
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Date
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Flat/Block
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                       Product
                                    </TableCell>
                                
                                    <TableCell

                                        align={"left"}
                                        style={{ top: 57, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Quantity
                                    </TableCell>
                                    <TableCell

                                        align={"left"}
                                        style={{ top: 57, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        UOM
                                    </TableCell>

                                    <TableCell

                                        align={"left"}
                                        style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    response2.map((item => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>
                                        <TableCell align={"start"}
                                            style={{ top: 57 }}>
                                            {item.Issueno}
                                        </TableCell>
                                        <TableCell align={"start"}
                                            style={{ top: 57, fontSize: 12 }}>{`${moment(item.date).format('DD/MM/yyyy')}`}</TableCell>
                                        <TableCell align={"start"}
                                            style={{ top: 57, fontSize: 12 }}>{item.Unit_Block}
                                        </TableCell>
                                        <TableCell align={"start"}
                                            style={{ top: 57, fontSize: 12 }}>
                                           {item.Product}
                                        </TableCell>
                                        <TableCell align={"start"}
                                            style={{ top: 57, fontSize: 12 }}>{item.Quantity}</TableCell>
                                        <TableCell align={"start"}
                                            style={{ top: 57, fontSize: 12 }}>{item.UOM}</TableCell>
                                        
                                       
                                             <TableCell align={"start"}
                                            style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                            <div style={{ display: 'flex' }} >
                                                {/* <Button  style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Delete</Button> */}
                                                <div>
                                                <IconButton
                                                        id="fade-button"
                                                        aria-controls={open2 ? 'fade-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open2 ? 'true' : undefined}
                                                        onClick={(e) => {
                                                            handleClick(e.currentTarget)
                                                            setId(item.Id)
                                                        }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="fade-menu"
                                                        MenuListProps={{
                                                            'aria-labelledby': 'fade-button',
                                                        }}
                                                        anchorEl={anchorEl}
                                                        open={open2}
                                                        onClose={handleClose2}
                                                        TransitionComponent={Fade}
                                                    >

                                                        <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                            handleClickOpened()
                                                            viewData(id)
                                                            }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>

                                                        <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} ><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    )))
                                }

                        
                                        {/* // );
                                    // })} */}
                            </TableBody>
                        </Table>
                        {
                            response2.length === 0 && (
                                <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                    <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                    <Typography>0 users</Typography>
                                </Box>
                            )
                        }
                    </TableContainer>
                </Paper>
            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Add Store</Typography>
                                    {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Addding project details will receive a privacy audit.</Typography> */}
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Issue no</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setIslno(e.target.value)}

                                            type="text"
                                            

                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid supplier name
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}> Date</Typography>
                                        <Form.Control
                                            required
                                            
                                            onChange={(e) => setDdate(e.target.value)}
                                            type="date"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid  date
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Product</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setProduct(e.target.value)}
                                            type="text"

                                           

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Product</option>
                                            {
                                                response1.map((data,index) => (
                                                    <React.Fragment key={index}>
                                                    {data.ProductName.split(',').map((block, blockIndex) => (
                                                        <option key={blockIndex}>{block.trim()}</option>
                                                    ))}
                                                    </React.Fragment>
                                                ))
                                            }
                                            
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid product
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Flat/blocks</Typography>
                                            <Form.Select
                                                required
                                                onChange={(e)=>setUb(e.target.value)}
                                                type="text"
                                                style={{ padding: '0.42rem', marginBottom: 14 }}


                                            >
                                                <option>Select Flat Name</option>
                                                
                                               

                                                {
                                                    floor.map((data,index) => (
                                                        <React.Fragment key={index}>
                                                        {data.selectType.split(',').map((block, blockIndex) => (
                                                            <option key={blockIndex} >{block.trim()}</option>
                                                        ))}
                                                        </React.Fragment>
                                                       
                                                    ))
                                                    
                                                }

                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter project name
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                  

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}> Quality</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setQnty(e.target.value)}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Description
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>UOM</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setUom(e.target.value)}
                                            type="text"

                                           

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select UOM</option>
                                            {
                                                response1.map((data,index) => (
                                                    <React.Fragment key={index}>
                                                    {data.UOM.split(',').map((block, blockIndex) => (
                                                        <option key={blockIndex}>{block.trim()}</option>
                                                    ))}
                                                    </React.Fragment>
                                                ))
                                            }
                                            
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid product
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={opened}
                    onClose={handleCloseed}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                            <Form noValidate >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Edit Store</Typography>
                                    {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Addding project details will receive a privacy audit.</Typography> */}
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Issue no</Typography>
                                        <Form.Control
                                            required
                                            value={islno}
                                            onChange={(e) => setIslno(e.target.value)}

                                            type="text"
                                            disabled

                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid supplier name
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}> Date</Typography>
                                        <Form.Control
                                            required
                                            disabled
                                            value={`${moment(ddate).format('DD/MM/yyyy')}`}
                                            onChange={(e) => setDdate(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid  date
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Product</Typography>
                                        <Form.Select
                                            required
                                            value={product}
                                            onChange={(e) => setProduct(e.target.value)}
                                            type="text"

                                           

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Product</option>
                                            {
                                                response1.map((data,index) => (
                                                    <React.Fragment key={index}>
                                                    {data.ProductName.split(',').map((block, blockIndex) => (
                                                        <option key={blockIndex}>{block.trim()}</option>
                                                    ))}
                                                    </React.Fragment>
                                                ))
                                            }
                                            
                                            
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid product
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Flat/blocks</Typography>
                                            <Form.Select
                                                required
                                                value={ub}
                                                onChange={(e)=>setUb(e.target.value)}
                                                type="text"
                                                style={{ padding: '0.42rem', marginBottom: 14 }}


                                            >
                                                <option>Select Flat Name</option>
                                                
                                               

                                                {
                                                    floor.map((data,index) => (
                                                        <React.Fragment key={index}>
                                                        {data.selectType.split(',').map((block, blockIndex) => (
                                                            <option key={blockIndex} >{block.trim()}</option>
                                                        ))}
                                                        </React.Fragment>
                                                       
                                                    ))
                                                    
                                                }

                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter project name
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                  

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}> Quality</Typography>
                                        <Form.Control
                                            required
                                            value={qnty}
                                            onChange={(e) => setQnty(e.target.value)}

                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Description
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>UOM</Typography>
                                        <Form.Select
                                            required
                                            value={uom}
                                            onChange={(e) => setUom(e.target.value)}
                                            type="text"

                                           

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select UOM</option>

                                            {
                                                response1.map((data,index) => (
                                                    <React.Fragment key={index}>
                                                    {data.UOM.split(',').map((block, blockIndex) => (
                                                        <option key={blockIndex}>{block.trim()}</option>
                                                    ))}
                                                    </React.Fragment>
                                                ))
                                            }
                                            
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid product
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button onClick={() => editAlert(id)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                                    </div>
                                    <div>
                                        <Button onClick={handleCloseed} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

        </div>
    );
}