import React, { useState,useEffect } from "react";
import "./index.css";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TableBody,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import axios from "axios";

const initialSpecifications = [
  {
    heading: "RCC Framed Structure",
    description:
      "R.C.C. wall and slab to withstand Wind & Seismic Loads. Super Structure :RCC walls as per structural design. Concrete blocks for non structural members (wherever needed)",
  },
  {
    heading: "Doors | Windows | Ventilators",
    description: `Main Door : 8'-0" high engineered wood frame with designed flush shutter of melamine matt polish fixed with reputed make hardware. 
Internal Doors : 7'-0" high engineered wood frame with designed flush shutter, veneer on both sides fixed with reputed make hardware. 
Toilet Doors :7'-0" high engineered wood frame with flush shutter Veneer on one side and laminate on other side fixed with reputed make hardware. 
French Doors :UPVC Sliding door with glass panel shutters and hardware of reputed make. 
Windows :UPVC window system with glass. 
Ventilators :UPVC ventilator including provision for exhaust fan.`,
  },
  {
    heading: "Painting",
    description:
      "Internal: Smooth putty finish with 2 Coats of premium Acrylic emulsion paint of reputed make over a coat of primer. External: Textured finish and 2 coats of weather proof exterior emulsion paint of reputed make.",
  },
  {
    heading: "Flooring",
    description: `Drawing, Living, Italian Marble 
Dining & Kitchen : 800x800 mm size of Vitrified tiles of standard and reputed make. 
Master Bed room :Italian Marble. 
Other Bed rooms :600x600 mm/ 800x800 mm size of Vitrified tiles of standard and reputed make. 
Toilets : Anti-skid tiles of standard and reputed make. 
Sit-out :Wood design Ceramic/Vitrified tiles. 
Utility :Anti-skid tile flooring. 
Staircase :Tandur Granite Step up tile flooring.
Corridors : Italian Marble.`,
  },
  {
    heading: "Kitchen",
    description:
      "Counter: Granite platform with stainless steel sink. (Shall be provided at extra cost) Utility Area: Dishwasher and washing machine provision in the utility/kitchen area.",
  },
  {
    heading: "Bath Rooms",
    description:
      "EWC with concealed flush tank with dual flush of reputed make. Single lever fixtures with wall mixer cum shower of reputed make. Provision for Geysers in all bathrooms. Chrome plated fittings of reputed make.",
  },
  {
    heading: "Dado",
    description: `Toilets : Designer Vitrified/Ceramic tiles for walls up to false ceiling height. 
Utility :Vitrified I Ceramic tiles up to 900mm near the wash area. 
Lift Lobby :Granite I large format Vitrified tiles.`,
  },
  {
    heading: "Facilities for Differently Abled",
    description: "Access ramps at all block entrances for differently abled.",
  },
  {
    heading: "Electrical",
    description: `3 Phase supply for each unit and individual meters. Miniature Circuit breakers (MCB) for each distribution boards of reputed make. 
Elegant modular electrical switches. Concealed conduit wiring with FRLS insulated copper cables in all rooms for light, fan and plug points. 
Split A/C points in all bedrooms, drawing & living with power points. Copper piping for air conditioning units for all flats. 
Power plug for chimney, refrigerator; microwave ovens, water purifier; mixer I grinders in Kitchen. Power plug for washing machine and dish washer in Utility I Kitchen Area.`,
  },
  {
    heading: "Telecom",
    description:
      "Telephone/Intercom facilities in Drawing & Master bedroom to all units connecting to security.",
  },
  {
    heading: "Cable TV & Internet",
    description:
      "Provision for cable connection in Drawing/Living & Master bedroom. Provision for Internet connection in Drawing/Living& Master bedroom to each apartment.",
  },
  {
    heading: "False Ceiling",
    description: "Toilets: Grid ceiling of reputed make.",
  },
  {
    heading: "Lifts",
    description: `High speed automatic passenger lifts with rescue device (ARD) with V3F for energy efficiency of reputed make with brushed SS walls and Granite flooring with CC Cameras inside the lifts. 
Dedicated lifts for Goods I Services of reputed make with painted metal walls and chequered Aluminium flooring with CC Cameras inside the lifts.`,
  },
  {
    heading: "Security, Surveillance",
    description:
      "Boom Barrier operated with automated access with RFID. CC cameras around the campus for surveillance during day & night.",
  },
  {
    heading: "WTP & STP",
    description:
      "Domestic water made available through a water softening plant for each unit (Non RO Plant). A Sewage treatment plant of adequate capacity as per norms which will be used for the landscaping and flushing purpose. Rainwater harvesting for recharging ground water as per norms.",
  },
  {
    heading: "Generator",
    description: "100% D.G Set backup with acoustic enclosure & A.M.F.",
  },
  {
    heading: "Parking",
    description: "Each apartment will have 1 or 2 car parks.",
  },
  {
    heading: "Fire Safety",
    description:
      "Fire hydrant and Fire Sprinkler System in all floors and basements as per NBC norms. Fire alarm and Public Address System in all floors and parking areas as per NBC norms.",
  },
  {
    heading: "Gas Supply",
    description: "Piped gas supply to all individual flats with meters.",
  },
  {
    heading: "Water Supply",
    description:
      "Hydro-Pneumatic system. Separate municipal water supply provided by GHMC.",
  },
  {
    heading: "Waste Management",
    description:
      "Garbage chutes will be provided at every floor level and centrally collected for better disposal.",
  },
  {
    heading: "Car Wash Facility",
    description: "Car wash facility will be provided in parking floors level.",
  },
];

const SpecificationsManager = ({ setSelectedSpecs }) => {
  const [specifications, setSpecifications] = useState(initialSpecifications);
  const [selected, setSelected] = useState([]);
  const [newSpec, setNewSpec] = useState({ heading: "", description: "" });
  const [showForm, setShowForm] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .get("https://buildfastapi.kollagroup.in/project/api/v1/initial-specifications")
      .then((response) => {
        console.log("API Response:", response.data)
        setSpecifications(response.data);
        setCount(response.data.length);
      })
      .catch((error) => {
        console.log("There was an error fetching specifications!", error);
      });
  }, []);

  const handleSelect = (index) => {
    const newSelected = [...selected];
    if (newSelected.includes(index)) {
      newSelected.splice(newSelected.indexOf(index), 1);
    } else {
      newSelected.push(index);
    }
    setSelected(newSelected);
    setSelectedSpecs(newSelected.map((i) => specifications[i]));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSpec({ ...newSpec, [name]: value });
  };

  const handleAddSpecification = () => {
    if (newSpec.heading && newSpec.description) {
      axios
        .post("https://buildfastapi.kollagroup.in/project/api/v1/specifications", newSpec)
        .then((response) => {
          setSpecifications([...specifications, response.data]);
          setNewSpec({ heading: "", description: "" });
          setShowForm(false);
          setCount(count + 1);
        })
        .catch((error) => {
          console.log("There was error while adding specification!", error);
        });
    }
  };

  const handleRemoveSpecification = (index) => {
    const specToRemove = specifications[index];

    axios
      .delete(`https://buildfastapi.kollagroup.in/project/api/v1/specifications/${specToRemove.id}`)

      .then(() => {
        const newSpecifications = specifications.filter((_, i) => i !== index);
        setSpecifications(newSpecifications);

        const newSelected = selected
          .filter((i) => i !== index)
          .map((i) => (i > index ? i - 1 : i));
        setSelected(newSelected);
        setSelectedSpecs(newSelected.map((i) => newSpecifications[i]));
        setCount(newSpecifications.length);
      })
      .catch((error) => {
        console.log("There was error removing specification!", error);
      });
  };

  const handleCountChange = (e) => {
    // const inputCount = Number(e.target.value);
    // const maxCount=specifications.length
    const newCount = Math.max(0, Number(e.target.value)); 
    setCount(newCount);
  };

  return (
    <div className="specifications-manager">
      <Typography style={{ marginBottom: 14, color: "rgb(163, 163, 165)" }}>
        <span style={{ fontSize: 17, color: "black" }}>
          No of Specifications:{" "}
        </span>
      </Typography>
      <input
        type="number"
        value={count}
        onChange={handleCountChange}
        placeholder="Enter number of specifications"
        style={{ width: "350px", height: "35px", marginBottom: "25px" }}
      />
      <div className="specifications-list">
      {Array.isArray(specifications) &&
        specifications.slice(0, count).map((spec, index) => (
          <div key={index} className="specification-item">
            <div className="specification-content">
              <h5
                onClick={() => handleSelect(index)}
                style={{ marginBottom: "30px" }}
              >
                {spec.heading} {selected.includes(index) ? "-" : "+"}
              </h5>
              {selected.includes(index) && <p>{spec.description}</p>}
            </div>
            <button
              type="button"
              onClick={() => handleRemoveSpecification(index)}
              className="remove-button"
            >
              Remove
            </button>
          </div>
        ))}
      </div>
      <div className="form-actions">
        <button
          type="button"
          onClick={() => setShowForm(!showForm)}
          className="add-button"
        >
          {showForm ? "Cancel" : "Add"}
        </button>
        {showForm && (
          <div className="add-specification-form">
            <input
              type="text"
              name="heading"
              value={newSpec.heading}
              onChange={handleInputChange}
              placeholder="Specification heading"
            />
            <input
              type="text"
              name="description"
              value={newSpec.description}
              onChange={handleInputChange}
              placeholder="Specification description"
              style={{ marginLeft: "15px" }}
            />
            <button
              type="button"
              onClick={handleAddSpecification}
              className="add-button"
            >
              Add Specification
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecificationsManager;
