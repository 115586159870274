import React, { useEffect } from 'react'
import { useState } from 'react';

export default function Uom({uomData}) {
    const [inPutlist, setInputList] = useState([
        {
            attachment: '',
        },
    ])
 
    const onChangeHandle = (e) => {

        const type = 'attachment'

        const list = [...inPutlist];
        list[index][type] = e
        setInputList(list)
        
        return

    }

    

    useEffect(()=>{

        uomData(inPutlist)

    },[inPutlist])

    const [index, setIndex] = useState(0)

    const handleaddclick = () => {

        setIndex(inPutlist.length)

        const _inPutlist = [...inPutlist]
        _inPutlist.push({
            attachment: "",
        })
        setInputList(_inPutlist)
    }

    const removeInputFieldsq = (index) => {
        const rows = [...inPutlist];
        rows.splice(1, 1);
        setInputList(rows);
    }

  return (
    <>
    <div style={{ borderRadius: 3, borderColor: 'rgb(210, 195, 195)', borderWidth: 0.9, borderStyle: 'solid', padding: 4, marginBottom: 5 }} >
                    <div style={{  width: '100%', borderRadius: 3, justifyContent: 'center', alignItems: 'center', elevation: 0, marginBottom: 1}} >
                        <p style={{ color: 'rgb(133, 100, 100)', fontWeight: '500', textTransform: 'uppercase' }} >UOM Name</p>
                        <p style={{color:'grey',marginTop:-20}}>you can add as many UOM you want</p>
                    </div>

                    <div style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }} >
                        {
                            inPutlist.map((data, index) => {

                                return (
                                    <input key={data.id} style={{ width: index === 0 ? '100%' : '100%', height: 42, borderRadius: 3, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', borderWidth: 0.5, borderColor: inPutlist[index].attachment === '' ? 'rgb(97, 125, 133)' : 'green', borderStyle: 'solid', marginTop: index === 0 ? 0 : 8 }} onChange={(e) => {
                                        onChangeHandle(e.target.value)
                                    }}/>
                                    //     {/* {
                                    //         inPutlist[index].attachment === '' ? <img src='https://th.bing.com/th/id/OIP.y5zjzm-c5ew0QLIVcg6e2AHaHa?w=180&h=180&c=7&r=0&o=5&pid=1.7' resizeMode='contain' style={{ height: 38, width: 38 }} /> : <div><p style={{ color: 'green', fontSize: 15, fontWeight: 500 }} >Saved</p></div>
                                    //     }
                                    // </button> */}
                                )
                            })
                        }
                    </div>

                    <div style={{ justifyContent: 'space-between', flexDirection: 'row',display:'flex',cursor:'pointer' }} >
                        <div onClick={() => {
                            handleaddclick(index)
                        }} style={{ width: '48.6%', height: 42, borderRadius: 3, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', borderWidth: 0.5, borderStyle: 'solid', marginTop: 8, backgroundColor: 'green' }}  >
                            <p style={{ fontWeight: 500, color: 'white',textAlign:'center',marginTop:8 }} >+ Add More</p>
                        </div>

                        {
                            inPutlist.length > 1 && (
                                <div onClick={() => {
                                    removeInputFieldsq(index)
                                    setIndex(index - 1)
                                }} style={{ width: '48.6%', height: 42, borderRadius: 3, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', borderWidth: 0.5, borderStyle: 'solid', marginTop: 8, backgroundColor: 'red' }}  >
                                    <p style={{ fontWeight: 500, color: 'white',textAlign:'center',marginTop:8 }} >Remove</p>
                                </div>
                            )
                        }
                    </div>

                    <p style={{ color: 'rgb(133, 100, 100)', fontSize: 14, fontWeight: 400, marginTop: 10, marginBottom: 10, marginLeft: 5 }} >It is necessary to add at least one UOM</p>
                </div>
    </>
    
  )
}
