import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box, Divider } from '@mui/material';

const CustomerDetailDialog = ({ open, onClose, customer }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Customer Details</DialogTitle>
      <DialogContent>
        {customer && (
          <Box sx={{ padding: 2 }}>
                <div className="d-flex flex-row aling-items-center justify-content-around">
                    <div>
            <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
              <strong>Name:</strong> {customer.customerName}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Email:</strong> {customer.email}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Mobile:</strong> {customer.mobileNumber}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Aadhar No:</strong> {customer.aadharNo}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Pan No:</strong> {customer.panNo}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Address:</strong> {customer.addres}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Permanent Address:</strong> {customer.curAddres}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Total Value:</strong> ₹{customer.totalValue}
            </Typography>
            </div>
            <div>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Balance:</strong> ₹{customer.balance}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Extra Amount:</strong> ₹{customer.extraAmount}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Booking ID:</strong> {customer.bookingId}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Project Name:</strong> {customer.ProjectName}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Tower:</strong> {customer.Tower}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Floor:</strong> {customer.floor}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Flat:</strong> {customer.flatNo}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Car Park:</strong> {customer.carPark}
            </Typography>
            </div>
            <Divider sx={{ marginY: 2 }} />
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              <strong>Payments:</strong>
              {customer.payments && customer.payments.map((payment, index) => (
                <div key={index} className="d-flex flex-row align-items-center">
                  <strong>{payment.paySource}</strong>: ₹{payment.finalAmount}
                  <div style={{ marginLeft: '8px' }}>
                    <strong>Status:</strong> {payment.paidStatus || "Pending"}
                  </div>
                </div>
              ))}
            </Typography>
            </div>
            {/* Add other fields as needed */}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerDetailDialog;
