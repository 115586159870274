// import { Typography } from "@mui/material";
// import axios from "axios";
// import { useEffect, useState } from "react";
// import { Button, Col, Form } from "react-bootstrap";
// import { TableBody, TablePagination} from '@mui/material';
// import Paper from "@mui/material/Paper";
// import Table from '@mui/material/Table';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

// const LeadReport = () => {
//   const [ttowerno, setTowardsTowerNumber] = useState("");
//   const [responset, setResponset] = useState([]);
//   const [prname, setPrName] = useState("");
//   const [projectName, setProjectName] = useState([]);
//   const [fetchLeadData, setLeadFetchedData] = useState([]);
//   const [selectedColumns, setSelectedColumns] = useState([
 
    // { id: 'name', label: 'Name', width: 200, isVisible: true },
    // { id: 'email', label: 'Email', width: 200, isVisible: true },
    // { id: 'mobile', label: 'Mobile', width: 150, isVisible: true },
    // { id: 'ProjectName', label: 'Project Name', width: 200, isVisible: true },
    // { id: 'occupation', label: 'Occupation', width: 150, isVisible: true },
    // { id: 'remarks', label: 'Remarks', width: 200, isVisible: true },
    // { id: 'address', label: 'Address', width: 200, isVisible: true },
    // { id: 'age', label: 'Age', width: 100, isVisible: true },
    // { id: 'aptsize', label: 'Apartment Size', width: 150, isVisible: true },
    // { id: 'area', label: 'Area', width: 150, isVisible: true },
    // { id: 'budget', label: 'Budget', width: 150, isVisible: true },
    // { id: 'callBackTab', label: 'Callback Tab', width: 150, isVisible: true },
    // { id: 'cbComment', label: 'Callback Comment', width: 200, isVisible: true },
    // { id: 'cbDate', label: 'Callback Date', width: 150, isVisible: true },
    // { id: 'channelPartner', label: 'Channel Partner', width: 200, isVisible: true },
    // { id: 'commentBox', label: 'Comment Box', width: 200, isVisible: true },
    // { id: 'commentSiteVisit', label: 'Comment Site Visit', width: 200, isVisible: true },
    // { id: 'cornor', label: 'Corner', width: 100, isVisible: true },
    // { id: 'cpId', label: 'Channel Partner ID', width: 150, isVisible: true },
    // { id: 'cpName', label: 'Channel Partner Name', width: 200, isVisible: true },
    // { id: 'dob', label: 'Date of Birth', width: 150, isVisible: true },
    // { id: 'empType', label: 'Employment Type', width: 150, isVisible: true },
    // { id: 'executive', label: 'Executive', width: 200, isVisible: true },
    // { id: 'facing', label: 'Facing', width: 100, isVisible: true },
    // { id: 'financeType', label: 'Finance Type', width: 150, isVisible: true },
    // { id: 'flag', label: 'Flag', width: 100, isVisible: true },
    // { id: 'flatdtls', label: 'Flat Details', width: 200, isVisible: true },
    // { id: 'gender', label: 'Gender', width: 100, isVisible: true },
    // { id: 'interestfloor', label: 'Interest Floor', width: 150, isVisible: true },
    // { id: 'intrestComment', label: 'Interest Comment', width: 200, isVisible: true },
    // { id: 'intrestDate', label: 'Interest Date', width: 150, isVisible: true },
    // { id: 'intrestTab', label: 'Interest Tab', width: 100, isVisible: true },
    // { id: 'isBooked', label: 'Is Booked', width: 100, isVisible: true },
    // { id: 'leadDate', label: 'Lead Date', width: 150, isVisible: true },
    // { id: 'location', label: 'Location', width: 200, isVisible: true },
    // { id: 'mobile', label: 'Mobile', width: 150, isVisible: true },
   
    // { id: 'notIntrstTab', label: 'Not Interested Tab', width: 150, isVisible: true },
    // { id: 'notintrstComment', label: 'Not Interested Comment', width: 200, isVisible: true },
    // { id: 'notintrstReason', label: 'Not Interested Reason', width: 200, isVisible: true },
    // { id: 'pinCode', label: 'Pin Code', width: 100, isVisible: true },
    // { id: 'pretime', label: 'Preferred Time', width: 150, isVisible: true },
    // { id: 'productIntrest', label: 'Product Interest', width: 200, isVisible: true },
    // { id: 'refMobile', label: 'Reference Mobile', width: 150, isVisible: true },
    // { id: 'refName', label: 'Reference Name', width: 200, isVisible: true },
    // { id: 'refucin', label: 'Reference Ucin', width: 200, isVisible: true },
    // { id: 'rmdate', label: 'RM Date', width: 150, isVisible: true },
    // { id: 'salesExecutive', label: 'Sales Executive', width: 200, isVisible: true },
    // { id: 'salesExecutiveEmail', label: 'Sales Executive Email', width: 200, isVisible: true },
    // { id: 'sitevistTab', label: 'Site Visit Tab', width: 100, isVisible: true },
    // { id: 'sourceType', label: 'Source Type', width: 150, isVisible: true },
    // { id: 'state', label: 'State', width: 150, isVisible: true },
    // { id: 'street', label: 'Street', width: 200, isVisible: true },
    // { id: 'ucon', label: 'UCON', width: 100, isVisible: true },
    // { id: 'updatedate', label: 'Update Date', width: 150, isVisible: true },
    // { id: 'updatetype', label: 'Update Type', width: 150, isVisible: true },
    // { id: 'visidate', label: 'Visit Date', width: 150, isVisible: true },
    // { id: 'wamobile', label: 'WA Mobile', width: 100, isVisible: true },
//   ]);
  

//   useEffect(() => {
//     // Reset column visibility when the tower changes
//     const updatedColumns = selectedColumns.map(col => ({ ...col, isVisible: true }));
//     setSelectedColumns(updatedColumns);
//   }, [prname]);

//   const getFloor = () => {
//     axios
//       .post("https://buildfastapi.kollagroup.in/project/api/v1/towers-getall")
//       .then((res) => {
//         if (res.data.status === 200) {
//           setResponset(res.data.data);
//           console.log(res.data.data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching floor data:", error);
//       });
//   };

//   const getProjectNames = () => {
//     axios
//       .get("https://buildfastapi.kollagroup.in/project/api/v1/get-basic")
//       .then((res) => {
//         setProjectName(res.data.data);
//         console.log(projectName);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     getFloor();
//     getProjectNames();
//   }, []);
//   const fetchLeadReportData = async () => {
//     try {
//       const response = await axios.get(`https://buildfastapi.kollagroup.in/api/v1/get-lead-report?projectname=${prname}`);
  
//       // Check if response status is in the 200 range
//       if (response.status >= 200 && response.status < 300) {
//         setLeadFetchedData(response.data); // Directly use response.data
//         console.log(response.data);
//       } else {
//         throw new Error('Error fetching lead report data');
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };
  
//   useEffect(() => {
//     if (prname) {
//       fetchLeadReportData();
//     }
//   }, [prname]);


// //   const downloadPDF = () => {
// //     const doc = new jsPDF({
// //       orientation: 'landscape',
// //     });
  
// //     const columns = selectedColumns
// //       .filter(col => col.isVisible)
// //       .map(col => ({
// //         header: col.label,
// //         dataKey: col.id,
// //       }));
  
// //     const rows = fetchLeadData.map(row => {
// //       const filteredRow = {};
// //       selectedColumns.forEach(col => {
// //         if (col.isVisible) {
// //           filteredRow[col.id] = row[col.id];
// //         }
// //       });
// //       return filteredRow;
// //     });
  
// //     doc.autoTable({
// //       columns,
// //       body: rows,
// //       margin: { top: 10, left: 10 },
// //       columnStyles: { 0: { cellWidth: 'auto' } },
// //     });
  
// //     doc.save('lead_report.pdf');
// //   };



// const downloadPDF = () => {
//     const doc = new jsPDF({
//       orientation: 'landscape',
//     });
  
//     // Filter and prepare columns
//     const columns = selectedColumns
//       .filter(col => col.isVisible)
//       .map(col => ({
//         header: col.label,
//         dataKey: col.id,
//       }));
  
//     // Prepare rows by filtering columns based on visibility
//     const rows = fetchLeadData.map(row => {
//       const filteredRow = {};
//       selectedColumns.forEach(col => {
//         if (col.isVisible) {
//           filteredRow[col.id] = row[col.id] || ''; // Ensure undefined values are handled
//         }
//       });
//       return filteredRow;
//     });
  
//     // Create table in PDF
//     doc.autoTable({
//       columns,
//       body: rows,
//       margin: { top: 10, left: 10 },
//       columnStyles: {
//         // Adjust column styles as needed
//         0: { cellWidth: '80' },
//         // Optionally add styles for other columns
//       },
//       theme: 'striped',
//       didDrawPage: (data) => {
//         // Add title and page number
//         doc.text('Lead Report', 14, 22);
//         doc.text(`Page ${data.pageNumber} of ${data.pageCount}`, doc.internal.pageSize.width - 30, doc.internal.pageSize.height - 10);
//       },
//     });
  
//     // Save the PDF document
//     doc.save('lead_report.pdf');
//   };

//   const handleColumnToggle = (columnId) => {
//     const updatedColumns = selectedColumns.map(col =>
//       col.id === columnId ? { ...col, isVisible: !col.isVisible } : col
//     );
//     setSelectedColumns(updatedColumns);
//   };



//   const [filterInput, setFilterInput] = useState('');

//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

  
  

//   return (

//     <>
//     <div
//       className="card mt-2 d-flex flex-row align-items-center"
//       style={{
//         border: "none",
//         paddingLeft: 15,
//         paddingRight: 15,
//         flexDirection: "row",
//         alignItems: "center",
//       }}
//     >
     
//       <div>
//       <label style={{fontSize:'13px',color:'gray'}}>Search Filter</label>
//       <br/>
//         <input
//           value={filterInput}
//           onChange={(e) => setFilterInput(e.target.value)}
//           style={{
//             height: "2.3rem",
//             color: "gray",
//             borderRadius: "0.3rem",
//             outline: "none",
//             width: "20rem",
//             border: "0.7px solid lightgray",
//           }}
//           placeholder="Search Details.."
//         />
//       </div>
//       <div>
//         <div className="d-flex flex-row align-items-center p-2 mt-3">
//           <Form.Group
//             as={Col}
//             md="12"
//             controlId="validationCustom01"
//             className="mr-2 p-2"
//           >
//             <Typography style={{ color: "gray", fontSize: 13 }}>
//               ProjectName
//             </Typography>
//             <Form.Select
//               required
//               onChange={(e) => setPrName(e.target.value)}
//               value={prname}
//               type="text"
//               style={{ padding: "0.42rem", marginBottom: 14 }}
//             >
//               <option>Select Project</option>
//               {projectName.map((item) => (
//                 <option key={item.ProjectName} value={item.ProjectName}>
//                   {item.ProjectName}
//                 </option>
//               ))}
//             </Form.Select>
//             <Form.Control.Feedback type="invalid">
//               Please Enter Valid Towards Flat Number
//             </Form.Control.Feedback>
//           </Form.Group>
//           {/* <Form.Group as={Col} md="10" controlId="validationCustom01">
//               <Typography style={{ color: 'gray', fontSize: 13 }}>Tower</Typography>
//               <Form.Select
//                 required
//                 value={ttowerno}
//                 onChange={(e) => setTowardsTowerNumber(e.target.value)}
//                 type="text"
//                 style={{ padding: "0.42rem", marginBottom: 14 }}
//               >
//                 <option>Select Tower </option>
//                 {responset
//                   .filter((item) => item.Towers.startsWith(prname)) // Filter towers that start with the project name
//                   .map((item, index) => (
//                     <option key={index}>{item.Towers}</option>
//                   ))}
//               </Form.Select>
//               <Form.Control.Feedback type="invalid">Please Enter Valid Towards Tower Number</Form.Control.Feedback>
//             </Form.Group> */}
//         </div>
//       </div>
//     </div>

// <TableContainer>
// <Table sx={{ minWidth: 650 }} aria-label="simple table" id="table-to-xls">
//   <TableHead>
//     <TableRow>
//       {selectedColumns.map((col, index) => (
//         col.isVisible && (
//           <TableCell key={index} style={{ minWidth: col.width, fontSize: 14, fontWeight: "600" }}>
//             <div style={{ display: 'flex', alignItems: 'center' }}>
//               {col.label}
//               <input
//                 type="checkbox"
//                 checked={col.isVisible}
//                 onChange={() => handleColumnToggle(col.id)}
//                 style={{ marginLeft: '0.5rem' }}
//               />
//             </div>
//           </TableCell>
//         )
//       ))}
//     </TableRow>
//   </TableHead>
//   <TableBody>
//     {fetchLeadData
//       .filter(row => selectedColumns.some(col => col.isVisible && row[col.id]?.toString().toLowerCase().includes(filterInput.toLowerCase())))
//       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//       .map((row, index) => (
//         <TableRow key={index}>
//           {selectedColumns.map((col, idx) => (
//             col.isVisible && (
//               <TableCell key={idx} style={{ fontSize: 12, minWidth: col.width }}>
//                 {row[col.id]}
//               </TableCell>
//             )
//           ))}
//         </TableRow>
//       ))}
//   </TableBody>
// </Table>
// </TableContainer>

// <TablePagination
// rowsPerPageOptions={[10, 25, 50]}
// component="div"
// count={fetchLeadData.filter(row => selectedColumns.some(col => col.isVisible && row[col.id]?.toString().toLowerCase().includes(filterInput.toLowerCase()))).length}
// rowsPerPage={rowsPerPage}
// page={page}
// onPageChange={handleChangePage}
// onRowsPerPageChange={handleChangeRowsPerPage}
// />

// <div className='d-flex flex-row align-items-center'>
//   <ReactHTMLTableToExcel
//     id="test-table-xls-button"
//     className="download-table-xls-button"
//     table="table-to-xls"
//     filename="lead_report"
//     sheet="Lead Report"
//     buttonText="Excel"
//     style={{ marginLeft: '2rem', height: '50px' }}
//   />

//   <Button
//     variant="contained"
//     color="primary"s
//     size="large"
//     style={{ marginLeft: '2rem', height: '50px' }}
//     onClick={downloadPDF}
//   >
//   PDF
//   </Button>
// </div>

// </>
//   );
// };

// export default LeadReport;


// import { Typography } from "@mui/material";
// import axios from "axios";
// import { useEffect, useState } from "react";
// import { Button, Col, Form } from "react-bootstrap";
// import { TableBody, TablePagination } from '@mui/material';
// import Paper from "@mui/material/Paper";
// import Table from '@mui/material/Table';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

// const LeadReport = () => {
//   const [ttowerno, setTowardsTowerNumber] = useState("");
//   const [responset, setResponset] = useState([]);
//   const [prname, setPrName] = useState("");
//   const [projectName, setProjectName] = useState([]);
//   const [fetchLeadData, setLeadFetchedData] = useState([]);
//   const [selectedColumns, setSelectedColumns] = useState([
//     { id: 'name', label: 'Name', width: 200, isVisible: true },
//     { id: 'email', label: 'Email', width: 200, isVisible: true },
//     { id: 'mobile', label: 'Mobile', width: 150, isVisible: true },
//     { id: 'ProjectName', label: 'Project Name', width: 200, isVisible: true },
//     { id: 'occupation', label: 'Occupation', width: 150, isVisible: true },
//     { id: 'remarks', label: 'Remarks', width: 200, isVisible: true },
//     { id: 'address', label: 'Address', width: 200, isVisible: true },
//     { id: 'age', label: 'Age', width: 100, isVisible: true },
//     { id: 'aptsize', label: 'Apartment Size', width: 150, isVisible: true },
//     { id: 'area', label: 'Area', width: 150, isVisible: true },
//     { id: 'budget', label: 'Budget', width: 150, isVisible: true },
//     { id: 'callBackTab', label: 'Callback Tab', width: 150, isVisible: true },
//     { id: 'cbComment', label: 'Callback Comment', width: 200, isVisible: true },
//     { id: 'cbDate', label: 'Callback Date', width: 150, isVisible: true },
//     { id: 'channelPartner', label: 'Channel Partner', width: 200, isVisible: true },
//     { id: 'commentBox', label: 'Comment Box', width: 200, isVisible: true },
//     { id: 'commentSiteVisit', label: 'Comment Site Visit', width: 200, isVisible: true },
//     { id: 'cornor', label: 'Corner', width: 100, isVisible: true },
//     { id: 'cpId', label: 'Channel Partner ID', width: 150, isVisible: true },
//     { id: 'cpName', label: 'Channel Partner Name', width: 200, isVisible: true },
//     { id: 'dob', label: 'Date of Birth', width: 150, isVisible: true },
//     { id: 'empType', label: 'Employment Type', width: 150, isVisible: true },
//     { id: 'executive', label: 'Executive', width: 200, isVisible: true },
//     { id: 'facing', label: 'Facing', width: 100, isVisible: true },
//     { id: 'financeType', label: 'Finance Type', width: 150, isVisible: true },
//     { id: 'flag', label: 'Flag', width: 100, isVisible: true },
//     { id: 'flatdtls', label: 'Flat Details', width: 200, isVisible: true },
//     { id: 'gender', label: 'Gender', width: 100, isVisible: true },
//     { id: 'interestfloor', label: 'Interest Floor', width: 150, isVisible: true },
//     { id: 'intrestComment', label: 'Interest Comment', width: 200, isVisible: true },
//     { id: 'intrestDate', label: 'Interest Date', width: 150, isVisible: true },
//     { id: 'intrestTab', label: 'Interest Tab', width: 100, isVisible: true },
//     { id: 'isBooked', label: 'Is Booked', width: 100, isVisible: true },
//     { id: 'leadDate', label: 'Lead Date', width: 150, isVisible: true },
//     { id: 'location', label: 'Location', width: 200, isVisible: true },
//     { id: 'mobile', label: 'Mobile', width: 150, isVisible: true },
   
//     { id: 'notIntrstTab', label: 'Not Interested Tab', width: 150, isVisible: true },
//     { id: 'notintrstComment', label: 'Not Interested Comment', width: 200, isVisible: true },
//     { id: 'notintrstReason', label: 'Not Interested Reason', width: 200, isVisible: true },
//     { id: 'pinCode', label: 'Pin Code', width: 100, isVisible: true },
//     { id: 'pretime', label: 'Preferred Time', width: 150, isVisible: true },
//     { id: 'productIntrest', label: 'Product Interest', width: 200, isVisible: true },
//     { id: 'refMobile', label: 'Reference Mobile', width: 150, isVisible: true },
//     { id: 'refName', label: 'Reference Name', width: 200, isVisible: true },
//     { id: 'refucin', label: 'Reference Ucin', width: 200, isVisible: true },
//     { id: 'rmdate', label: 'RM Date', width: 150, isVisible: true },
//     { id: 'salesExecutive', label: 'Sales Executive', width: 200, isVisible: true },
//     { id: 'salesExecutiveEmail', label: 'Sales Executive Email', width: 200, isVisible: true },
//     { id: 'sitevistTab', label: 'Site Visit Tab', width: 100, isVisible: true },
//     { id: 'sourceType', label: 'Source Type', width: 150, isVisible: true },
//     { id: 'state', label: 'State', width: 150, isVisible: true },
//     { id: 'street', label: 'Street', width: 200, isVisible: true },
//     { id: 'ucon', label: 'UCON', width: 100, isVisible: true },
//     { id: 'updatedate', label: 'Update Date', width: 150, isVisible: true },
//     { id: 'updatetype', label: 'Update Type', width: 150, isVisible: true },
//     { id: 'visidate', label: 'Visit Date', width: 150, isVisible: true },
//     { id: 'wamobile', label: 'WA Mobile', width: 100, isVisible: true },
//   ]);
//   const [viewMode, setViewMode] = useState('Full View'); // Added state for view mode

//   useEffect(() => {
//     // Reset column visibility when the project name changes
//     const updatedColumns = selectedColumns.map(col => ({ ...col, isVisible: viewMode === 'Full View' }));
//     setSelectedColumns(updatedColumns);
//   }, [prname, viewMode]);

//   const getFloor = () => {
//     axios
//       .post("https://buildfastapi.kollagroup.in/project/api/v1/towers-getall")
//       .then((res) => {
//         if (res.data.status === 200) {
//           setResponset(res.data.data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching floor data:", error);
//       });
//   };

//   const getProjectNames = () => {
//     axios
//       .get("https://buildfastapi.kollagroup.in/project/api/v1/get-basic")
//       .then((res) => {
//         setProjectName(res.data.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     getFloor();
//     getProjectNames();
//   }, []);

//   const fetchLeadReportData = async () => {
//     try {
//       const response = await axios.get(`https://buildfastapi.kollagroup.in/api/v1/get-lead-report?projectname=${prname}`);
//       if (response.status >= 200 && response.status < 300) {
//         setLeadFetchedData(response.data);
//       } else {
//         throw new Error('Error fetching lead report data');
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   useEffect(() => {
//     if (prname) {
//       fetchLeadReportData();
//     }
//   }, [prname]);

//   const downloadPDF = () => {
//     const doc = new jsPDF({
//       orientation: 'landscape',
//     });

//     const columns = selectedColumns
//       .filter(col => col.isVisible)
//       .map(col => ({
//         header: col.label,
//         dataKey: col.id,
//       }));

//     const rows = fetchLeadData.map(row => {
//       const filteredRow = {};
//       selectedColumns.forEach(col => {
//         if (col.isVisible) {
//           filteredRow[col.id] = row[col.id] || '';
//         }
//       });
//       return filteredRow;
//     });

//     doc.autoTable({
//       columns,
//       body: rows,
//       margin: { top: 10, left: 10 },
//       columnStyles: {
//         0: { cellWidth: '80' },
//       },
//       theme: 'striped',
//       didDrawPage: (data) => {
//         doc.text('Lead Report', 14, 22);
//         doc.text(`Page ${data.pageNumber} of ${data.pageCount}`, doc.internal.pageSize.width - 30, doc.internal.pageSize.height - 10);
//       },
//     });

//     doc.save('lead_report.pdf');
//   };

//   const handleColumnToggle = (columnId) => {
//     const updatedColumns = selectedColumns.map(col =>
//       col.id === columnId ? { ...col, isVisible: !col.isVisible } : col
//     );
//     setSelectedColumns(updatedColumns);
//   };

//   const [filterInput, setFilterInput] = useState('');
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <>
//       <div
//         className="card mt-2 d-flex flex-row align-items-center"
//         style={{
//           border: "none",
//           paddingLeft: 15,
//           paddingRight: 15,
//           flexDirection: "row",
//           alignItems: "center",
//         }}
//       >
//         <div>
//           <label style={{ fontSize: '13px', color: 'gray' }}>Search Filter</label>
//           <br />
//           <input
//             value={filterInput}
//             onChange={(e) => setFilterInput(e.target.value)}
//             style={{
//               height: "2.3rem",
//               color: "gray",
//               borderRadius: "0.3rem",
//               outline: "none",
//               width: "20rem",
//               border: "0.7px solid lightgray",
//             }}
//             placeholder="Search Details.."
//           />
//         </div>
//         <div>
//           <div className="d-flex flex-row align-items-center p-2 mt-3">
//             <Form.Group as={Col} md="12" controlId="validationCustom01" className="mr-2 p-2">
//               <Typography style={{ color: "gray", fontSize: 13 }}>
//                 ProjectName
//               </Typography>
//               <Form.Select
//                 required
//                 onChange={(e) => setPrName(e.target.value)}
//                 value={prname}
//                 type="text"
//                 style={{ padding: "0.42rem", marginBottom: 14 }}
//               >
//                 <option>Select Project</option>
//                 {projectName.map((item) => (
//                   <option key={item.ProjectName} value={item.ProjectName}>
//                     {item.ProjectName}
//                   </option>
//                 ))}
//               </Form.Select>
//               <Form.Control.Feedback type="invalid">
//                 Please Enter Valid Project Name
//               </Form.Control.Feedback>
//             </Form.Group>
//             {/* View Mode Selector */}
//             <Form.Group as={Col} md="12" controlId="viewMode">
//               <Typography style={{ color: "gray", fontSize: 13 }}>
//                 View Mode
//               </Typography>
//               <Form.Select
//                 onChange={(e) => setViewMode(e.target.value)}
//                 value={viewMode}
//                 style={{ padding: "0.42rem", marginBottom: 14 }}
//               >
//                 <option value="Full View">Full View</option>
//                 <option value="Customized View">Customized View</option>
//               </Form.Select>
//             </Form.Group>
//           </div>
//         </div>
//       </div>

//       <TableContainer component={Paper}>
//         <Table sx={{ minWidth: 650 }} aria-label="simple table" id="table-to-xls">
//           <TableHead>
//             <TableRow>
//               {viewMode === 'Customized View' && (
//                 selectedColumns.map((col, index) => (
//                   <TableCell key={index} style={{ minWidth: col.width, fontSize: 14, fontWeight: "600" }}>
//                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                       {col.label}
//                       <input
//                         type="checkbox"
//                         checked={col.isVisible}
//                         onChange={() => handleColumnToggle(col.id)}
//                         style={{ marginLeft: '0.5rem' }}
//                       />
//                     </div>
//                   </TableCell>
//                 ))
//               )}
//               {viewMode === 'Full View' && (
//                 selectedColumns.map((col, index) => (
//                   col.isVisible && (
//                     <TableCell key={index} style={{ minWidth: col.width, fontSize: 14, fontWeight: "600" }}>
//                       {col.label}
//                     </TableCell>
//                   )
//                 ))
//               )}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {fetchLeadData
//               .filter(row => selectedColumns.some(col => col.isVisible && row[col.id]?.toString().toLowerCase().includes(filterInput.toLowerCase())))
//               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//               .map((row, index) => (
//                 <TableRow key={index}>
//                   {selectedColumns.map((col, idx) => (
//                     col.isVisible && (
//                       <TableCell key={idx} style={{ fontSize: 12, minWidth: col.width }}>
//                         {row[col.id]}
//                       </TableCell>
//                     )
//                   ))}
//                 </TableRow>
//               ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       <TablePagination
//         rowsPerPageOptions={[10, 25, 50]}
//         component="div"
//         count={fetchLeadData.filter(row => selectedColumns.some(col => col.isVisible && row[col.id]?.toString().toLowerCase().includes(filterInput.toLowerCase()))).length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />

//       <div className='d-flex flex-row align-items-center'>
//         <ReactHTMLTableToExcel
//           id="test-table-xls-button"
//           className="download-table-xls-button"
//           table="table-to-xls"
//           filename="lead_report"
//           sheet="Lead Report"
//           buttonText="Excel"
//           style={{ marginLeft: '2rem', height: '50px' }}
//         />

//         <Button
//           variant="contained"
//           color="danger"
//           size="large"
//           style={{ marginLeft: '2rem', height: '50px' }}
//           onClick={downloadPDF}
//         >
//           PDF
//         </Button>
//       </div>
//     </>
//   );
// };

// export default LeadReport;


import { Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, Dropdown, Row, Container } from "react-bootstrap";
import { TableBody, TablePagination } from '@mui/material';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const LeadReport = () => {
  const [ttowerno, setTowardsTowerNumber] = useState("");
  const [responset, setResponset] = useState([]);
  const [prname, setPrName] = useState("");
  const [projectName, setProjectName] = useState([]);
  const [fetchLeadData, setLeadFetchedData] = useState([]);
  const [viewMode, setViewMode] = useState('Full View');
  const [selectedColumns, setSelectedColumns] = useState([
    { id: 'name', label: 'Name', width: 200, isVisible: true },
    { id: 'email', label: 'Email', width: 200, isVisible: true },
    { id: 'mobile', label: 'Mobile', width: 150, isVisible: true },
    { id: 'ProjectName', label: 'Project Name', width: 200, isVisible: true },
    { id: 'occupation', label: 'Occupation', width: 150, isVisible: true },
    { id: 'remarks', label: 'Remarks', width: 200, isVisible: true },
    { id: 'address', label: 'Address', width: 200, isVisible: true },
    { id: 'age', label: 'Age', width: 100, isVisible: true },
    { id: 'aptsize', label: 'Apartment Size', width: 150, isVisible: true },
    { id: 'area', label: 'Area', width: 150, isVisible: true },
    { id: 'budget', label: 'Budget', width: 150, isVisible: true },
    { id: 'callBackTab', label: 'Callback Tab', width: 150, isVisible: true },
    { id: 'cbComment', label: 'Callback Comment', width: 200, isVisible: true },
    { id: 'cbDate', label: 'Callback Date', width: 150, isVisible: true },
    { id: 'channelPartner', label: 'Channel Partner', width: 200, isVisible: true },
    { id: 'commentBox', label: 'Comment Box', width: 200, isVisible: true },
    { id: 'commentSiteVisit', label: 'Comment Site Visit', width: 200, isVisible: true },
    { id: 'cornor', label: 'Corner', width: 100, isVisible: true },
    { id: 'cpId', label: 'Channel Partner ID', width: 150, isVisible: true },
    { id: 'cpName', label: 'Channel Partner Name', width: 200, isVisible: true },
    { id: 'dob', label: 'Date of Birth', width: 150, isVisible: true },
    { id: 'empType', label: 'Employment Type', width: 150, isVisible: true },
    { id: 'executive', label: 'Executive', width: 200, isVisible: true },
    { id: 'facing', label: 'Facing', width: 100, isVisible: true },
    { id: 'financeType', label: 'Finance Type', width: 150, isVisible: true },
    { id: 'flag', label: 'Flag', width: 100, isVisible: true },
    { id: 'flatdtls', label: 'Flat Details', width: 200, isVisible: true },
    { id: 'gender', label: 'Gender', width: 100, isVisible: true },
    { id: 'interestfloor', label: 'Interest Floor', width: 150, isVisible: true },
    { id: 'intrestComment', label: 'Interest Comment', width: 200, isVisible: true },
    { id: 'intrestDate', label: 'Interest Date', width: 150, isVisible: true },
    { id: 'intrestTab', label: 'Interest Tab', width: 100, isVisible: true },
    { id: 'isBooked', label: 'Is Booked', width: 100, isVisible: true },
    { id: 'leadDate', label: 'Lead Date', width: 150, isVisible: true },
    { id: 'location', label: 'Location', width: 200, isVisible: true },
    { id: 'mobile', label: 'Mobile', width: 150, isVisible: true },
    { id: 'notIntrstTab', label: 'Not Interested Tab', width: 150, isVisible: true },
    { id: 'notintrstComment', label: 'Not Interested Comment', width: 200, isVisible: true },
    { id: 'notintrstReason', label: 'Not Interested Reason', width: 200, isVisible: true },
    { id: 'pinCode', label: 'Pin Code', width: 100, isVisible: true },
    { id: 'pretime', label: 'Preferred Time', width: 150, isVisible: true },
    { id: 'productIntrest', label: 'Product Interest', width: 200, isVisible: true },
    { id: 'refMobile', label: 'Reference Mobile', width: 150, isVisible: true },
    { id: 'refName', label: 'Reference Name', width: 200, isVisible: true },
    { id: 'refucin', label: 'Reference Ucin', width: 200, isVisible: true },
    { id: 'rmdate', label: 'RM Date', width: 150, isVisible: true },
    { id: 'salesExecutive', label: 'Sales Executive', width: 200, isVisible: true },
    { id: 'salesExecutiveEmail', label: 'Sales Executive Email', width: 200, isVisible: true },
    { id: 'sitevistTab', label: 'Site Visit Tab', width: 100, isVisible: true },
    { id: 'sourceType', label: 'Source Type', width: 150, isVisible: true },
    { id: 'state', label: 'State', width: 150, isVisible: true },
    { id: 'street', label: 'Street', width: 200, isVisible: true },
    { id: 'ucon', label: 'UCON', width: 100, isVisible: true },
    { id: 'updatedate', label: 'Update Date', width: 150, isVisible: true },
    { id: 'updatetype', label: 'Update Type', width: 150, isVisible: true },
    { id: 'visidate', label: 'Visit Date', width: 150, isVisible: true },
    { id: 'wamobile', label: 'WA Mobile', width: 100, isVisible: true },
  ]);
  const [selectedColumnIds, setSelectedColumnIds] = useState(selectedColumns.filter(col => col.isVisible).map(col => col.id));

  useEffect(() => {
    const updatedColumns = selectedColumns.map(col => ({
      ...col,
      isVisible: viewMode === 'Full View' ? true : selectedColumnIds.includes(col.id)
    }));
    setSelectedColumns(updatedColumns);
  }, [viewMode, selectedColumnIds]);

  const getFloor = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/project/api/v1/towers-getall")
      .then((res) => {
        if (res.data.status === 200) {
          setResponset(res.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching floor data:", error);
      });
  };

  const getProjectNames = () => {
    axios
      .get("https://buildfastapi.kollagroup.in/project/api/v1/get-basic")
      .then((res) => {
        setProjectName(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFloor();
    getProjectNames();
  }, []);

  const fetchLeadReportData = async () => {
    try {
      const response = await axios.get(`https://buildfastapi.kollagroup.in/api/v1/get-lead-report?projectname=${prname}`);
      if (response.status >= 200 && response.status < 300) {
        setLeadFetchedData(response.data);
      } else {
        throw new Error('Error fetching lead report data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (prname) {
      fetchLeadReportData();
    }
  }, [prname]);

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    const columns = selectedColumns
      .filter(col => col.isVisible)
      .map(col => ({
        header: col.label,
        dataKey: col.id,
      }));

    const rows = fetchLeadData.map(row => {
      const filteredRow = {};
      selectedColumns.forEach(col => {
        if (col.isVisible) {
          filteredRow[col.id] = row[col.id] || '';
        }
      });
      return filteredRow;
    });

    doc.autoTable({
      columns,
      body: rows,
      margin: { top: 10, left: 10 },
      columnStyles: {
        0: { cellWidth: '80' },
      },
      theme: 'striped',
      didDrawPage: (data) => {
        doc.text('Lead Report', 14, 22);
        doc.text(`Page ${data.pageNumber} of ${data.pageCount}`, doc.internal.pageSize.width - 30, doc.internal.pageSize.height - 10);
      },
    });

    doc.save('lead_report.pdf');
  };

  const handleColumnSelection = (colId) => {
    setSelectedColumnIds(prev =>
      prev.includes(colId) ? prev.filter(id => id !== colId) : [...prev, colId]
    );
  };

  const [filterInput, setFilterInput] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const userType = sessionStorage.getItem("userType");
    const projectNames = sessionStorage.getItem("ProjectName");

    // Filter projectNamesss based on userType and ProjectName
    const filtered = projectName.filter(project => {
      if (userType === "Admin") {
        return project.ProjectName === projectNames;
      }
      return true; // Show all projects for non-admins
    });

    setFilteredProjects(filtered);
  }, [projectName]);


  return (
    <>
      <div
        className="card mt-2 d-flex flex-row align-items-center"
        style={{
          border: "none",
          paddingLeft: 15,
          paddingRight: 15,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div>
          <label style={{ fontSize: '13px', color: 'gray' }}>Search Filter</label>
          <br />
          <input
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            style={{
              height: "2.3rem",
              color: "gray",
              borderRadius: "0.3rem",
              outline: "none",
              width: "20rem",
              border: "0.7px solid lightgray",
            }}
            placeholder="Search Details.."
          />
        </div>
        <div>
          <div className="d-flex flex-row align-items-center p-2 mt-3">
            <Form.Group as={Col} md="12" controlId="validationCustom01" className="mr-2 p-2">
              <Typography style={{ color: "gray", fontSize: 13 }}>
                ProjectName
              </Typography>
              <Form.Select
                required
                onChange={(e) => setPrName(e.target.value)}
                value={prname}
                type="text"
                style={{ padding: "0.42rem", marginBottom: 14 }}
              >
                <option>Select Project</option>
                {filteredProjects.map((item) => (
                  <option key={item.ProjectName} value={item.ProjectName}>
                    {item.ProjectName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please Enter Valid Project Name
              </Form.Control.Feedback>
            </Form.Group>
            {/* View Mode Selector */}
            <Form.Group as={Col} md="12" controlId="viewMode">
              <Typography style={{ color: "gray", fontSize: 13 }}>
                View Mode
              </Typography>
              <Form.Select
                onChange={(e) => setViewMode(e.target.value)}
                value={viewMode}
                style={{ padding: "0.42rem", marginBottom: 14 }}
              >
                <option value="Full View">Full View</option>
                <option value="Customized View">Customized View</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row align-items-center">
        {viewMode === 'Customized View' && (
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Select Columns
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: '800px', overflowX: 'auto' }}>
              <Container>
                <Row>
                  {selectedColumns.map((col, index) => (
                    <Col key={col.id} xs={6} md={4} lg={3}>
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${col.id}`}
                        label={col.label}
                        checked={selectedColumnIds.includes(col.id)}
                        onChange={() => handleColumnSelection(col.id)}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id="table-to-xls">
          <TableHead>
            <TableRow>
              {selectedColumns
                .filter(col => col.isVisible)
                .map((col, index) => (
                  <TableCell key={index} style={{ minWidth: col.width, fontSize: 14, fontWeight: "600" }}>
                    {col.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchLeadData
              .filter(row => selectedColumns.some(col => col.isVisible && row[col.id]?.toString().toLowerCase().includes(filterInput.toLowerCase())))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  {selectedColumns
                    .filter(col => col.isVisible)
                    .map((col, idx) => (
                      <TableCell key={idx} style={{ fontSize: 12, minWidth: col.width }}>
                        {row[col.id]}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={fetchLeadData.filter(row => selectedColumns.some(col => col.isVisible && row[col.id]?.toString().toLowerCase().includes(filterInput.toLowerCase()))).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <div className='d-flex flex-row align-items-center'>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="lead_report"
          sheet="Lead Report"
          buttonText="Excel"
          style={{ marginLeft: '2rem', height: '50px' }}
        />

        <Button
          variant="contained"
          color="danger"
          size="large"
          style={{ marginLeft: '2rem', height: '50px' }}
          onClick={downloadPDF}
        >
          PDF
        </Button>
      </div>
    </>
  );
};

export default LeadReport;

