import * as React from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import styled from "@mui/system/styled";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button, FormGroup, Typography } from "@mui/material";
import axios from "axios";
import { FaCaretLeft, FaRupeeSign } from "react-icons/fa";
import Swal from "sweetalert2";
import Row from "react-bootstrap/esm/Row";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  padding: theme.spacing(1),
  borderRadius: "4px",
  textAlign: "auto",
}));

export default function CreateBilling() {
  const [email, setEmail] = React.useState();
  const [mobileNumber, setMobile] = React.useState();
  const [customerName, setCustomerName] = React.useState();
  const [budget, setBudget] = React.useState();
  const [flatImage, setFlatImage] = React.useState();
  const [ProjectName, setProjectName] = React.useState();
  const [flat, setFlat] = React.useState();
  const [towers, setTowers] = React.useState();
  const [facingAmmount, setFacingAmmount] = React.useState("0");
  const [floorAmount, setFloorAmmount] = React.useState("0");
  const [selectfloor, setSelectfloor] = useState([]);
  const [billing, setBilling] = React.useState("");
  const [bulkbilling, setBulkBilling] = React.useState("");
  const [individualbilling, setIndividualBilling] = React.useState("");

  const [paymentType, setPaymentType] = useState("");
  const [installments, setInstallments] = useState([]);

  //   const handleBillingCheckboxChange = (e) => {
  //       setBilling(e.target.value);
  //   };

  const handlePaymentTypeChange = (e) => {
    const selectedPaymentType = e.target.value;
    setPaymentType(selectedPaymentType);
    if (selectedPaymentType === "RERA") {
      setInstallments([
        "1st Installment",
        "2nd Installment",
        "3rd Installment",
        "4th Installment",
        "5th Installment",
        "6th Installment",
        "7th Installment",
        "8th Installment",
        "9th Installment",
        "10th Installment",
      ]);
    } else if (selectedPaymentType === "PRE EMI") {
      setInstallments([
        "1st Installment",
        "2nd Installment",
        "3rd Installment",
      ]);
    }
  };

  const printbil = () => {
    window.print();
  };

  const select_floor = (value4, value1) => {
    axios
      .post("https://buildfastapi.kollagroup.in/project/api/v1/select-floor", {
        projectName: ProjectName,
        value4: towers,
        value1: block,
      })
      .then((res) => {
        setSelectfloor(res.data);
        setTowers(value4);
        setBlock(value1);
        console.log(res.data);
      });
  };

  const [response2, setResponse2] = React.useState([]);

  const get_basic = () => {
    axios
      .get("https://buildfastapi.kollagroup.in/project/api/v1/get-basic")
      .then((res) => {
        if (res.data.status === 200) {
          setResponse2(res.data.data);
        }
      });
  };

  React.useEffect(() => {
    get_basic();
    customerObj();
  }, []);

  const [customerRes, setCustomerResponse] = React.useState([]);

  const customerObj = () => {
    axios
      .get(
        "https://buildfastapi.kollagroup.in/create-customer/api/v1/customers"
      )
      .then((res) => {
        setCustomerResponse(res.data);
      });
  };

  const bookCustomer = () => {
    const formData = new FormData();

    formData.append("customerName", customerName);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append(
      "budget",
      parseInt(basicPrice) * parseInt(sqf) +
        parseInt(facingAmmount) +
        parseInt(floorAmount)
    );
    formData.append("unitNo", flat);
    formData.append("file", flatImage);
    formData.append("flatSize", sqf);
    formData.append("flatFace", facing);
    formData.append("floor", floor);
    formData.append("block", block);
    formData.append("projectName", ProjectName);
    formData.append("facingAmount", facingAmmount);
    formData.append("floorAmount", floorAmount);

    axios
      .post(
        "https://buildfastapi.kollagroup.in/create-customer/api/v1/customer",
        formData
      )
      .then((res) => {
        if (res.data.status === 200) {
          handleClose();
          setCustomerBookingInfo([]);
          setCustomer([]);
          setResponse2([]);
          setResponse3([]);
          setResponse4([]);
          setResponse4([]);
          setResponse5([]);
          setTimeout(() => window.location.reload(), 600);
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Customer is booked successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            handleClose();
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  const [customerBookingInfo, setCustomerBookingInfo] = React.useState([]);

  const createCustomer = (projectName) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/create-customer/api/v1/filter-customer-info",
        {
          projectName,
        }
      )
      .then((res) => {
        setCustomerBookingInfo(res.data);
      });
  };

  const [customer, setCustomer] = React.useState([]);

  const customerData = (id) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/create-customer/api/v1/filter-customer",
        {
          id,
        }
      )
      .then((res) => {
        setCustomer(res.data);
        setCustomerName(res.data[0].name);
        setMobile(res.data[0].mobile);
        setEmail(res.data[0].email);
        setBudget(res.data[0].budget);
      });
  };

  const [block, setBlock] = React.useState("");
  const [tower, setTower] = React.useState("");
  const [floor, setFloor] = React.useState("");
  const [sqf, setSqf] = React.useState();
  const [facing, setFacing] = React.useState("");
  const [fType, setFType] = React.useState("");

  const [response3, setResponse3] = React.useState([]);

  const get_block = (value) => {
    // setResponse3('')

    axios
      .post(
        "https://buildfastapi.kollagroup.in/project/api/v1/projectwise-blocks",
        {
          value,
        }
      )
      .then((res) => {
        setResponse3(res.data);
        // setProjectName(value)
      });
  };

  const [response4, setResponse4] = React.useState([]);

  // https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor

  const get_floor = (value1) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor",
        {
          value1,
        }
      )
      .then((res) => {
        setResponse4(res.data);
        setTower(value1);
        //select_floor();
      });
  };

  const [response7, setResponse7] = React.useState([]);

  // "https://buildfastapi.kollagroup.in/project/api/v1/select-unit"

  const get_unit = (value1) => {
    axios
      .post("https://buildfastapi.kollagroup.in/project/api/v1/select-unit", {
        value: value1,
      })
      .then((res) => {
        setResponse7(res.data);
        setFloor(value1);
        console.log(res.data);
      });
  };

  // const get_flat = (value1, value2, value3) => {

  //     axios.post("https://buildfastapi.kollagroup.in/create-customer/api/v1/blockwise-flat", {
  //         value1, value2, value3
  //     }).then((res) => {

  //         setResponse5(res.data)
  //         setFloor(value1)
  //     })
  // }

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValidated(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} varient="filled" {...props} />;
  }

  const [validated, setValidated] = useState(false);

  const formValidation = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // else{
    //     billingDataEdit()
    // }
    setValidated(true);
  };

  const editAlert = () => {
    setOpen(false);
    handleClose();
    Swal.fire({
      icon: "warning",
      title: "Warning !",
      text: "Are you sure to edit this data",
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes",
    });
  };

  const [response6, setResponse6] = React.useState([]);
  const [Floorr, setFloorr] = React.useState("");
  const [unit, setUnit] = React.useState("");

  const get_customer = (value1, value2, value3) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/create-customer/api/v1/filter-book-customer",
        {
          value1,
          value2,
          value3,
        }
      )
      .then((res) => {
        setResponse6(res.data);
        setFloor(value1);
        // setSqf(res.data[0].UnitSize)
        // setFType(res.data[0].UnitType)
        // setFacing(res.data[0].UnitFacing)
        setFloorr(value1);
      });
  };

  const [basicPrice, setBasicPrice] = React.useState([]);
  const get_pricing = (text) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/create-customer/api/v1/basic-ammount",
        {
          text,
        }
      )
      .then((res) => {
        setBasicPrice(res.data[0].Basic_Amount);
        handleClickOpen();
      });
  };
  const [Instalment, setInstalment] = React.useState();

  const handleBillingCheckboxChange = (event) => {
    setBilling(event.target.value);
    setBulkBilling("");
    setIndividualBilling("");
  };

  const [responset, setResponset] = useState([]);

  const get_tower = (value1) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/project/api/v1/projectwise-towers",
        {
          value1,
        }
      )
      .then((res) => {
        setResponset(res.data);
        console.log(res.data);
      });
  };

  const [bunit, setBunit] = useState("");
  const [binstall, setBinstall] = useState("");
  const [binv, setBinv] = useState("");
  const [bdate, setBdate] = useState("");

  // const addbil = () => {
  //   axios
  //     .post(
  //       "https://buildfastapi.kollagroup.in/create-customer/api/v1/addbilling",
  //       {
  //         Unitno: bunit,
  //         Inv_value: binstall,
  //         Inv_no: binv,
  //         Inv_date: bdate,
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       alert("added ");
  //     });
  // };

  // const editAlert = () => {
  //     setOpen3(false)
  //     handleClose2()
  //     Swal.fire({
  //         icon: 'warning',
  //         title: "Warning !",
  //         text: "Are you sure to edit this data",
  //         confirmButtonColor: "green",
  //         cancelButtonColor: "#d33",
  //         showCancelButton: true,
  //         confirmButtonText: "Edit"
  //     }).then((result) => {
  //         if (result.value) {
  //             leadDataEdit()
  //         }
  //     })
  // }
  // const editAlert  = () => {

  // }

  // const billingData = {
  //   loan: paymentType,
  //   installment: Instalment,
  //   date: bdate,
  //   floor,
  // };

  // const addbil = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://buildfastapi.kollagroup.in/create-customer/api/v1/api/addBilling",
  //       billingData
  //     );
  //     alert(response.data.message);
  //   } catch (error) {
  //     console.error("Error adding billing:", error);
  //     alert("Failed to add billing information");
  //   }
  // };

  // https://buildfastapi.kollagroup.in/create-customer/api/v1/api/addBilling

  // const billingData = {
  //   loan: paymentType,
  //   installment: Instalment,
  //   date: bdate,
  // };

  // // Only include floor if paymentType is not Customized
  // if (paymentType !== "Customized") {
  //   billingData.floor = floor;
  // }

  // const addbil = async () => {
  //   if (!Instalment) {
  //     alert("Please select a valid installment");
  //     return;
  //   }

  //   try {
  //     console.log("Billing Data:", billingData);
  //     const response = await axios.post(
  //       "http://localhost:3003/api/addBilling",
  //       billingData
  //     );
  //     alert(response.data.message);
  //   } catch (error) {
  //     if (error.response) {
  //       console.error("Backend error response:", error.response.data);
  //       alert(
  //         `Failed to add billing information: ${error.response.data.error}`
  //       );
  //     } else {
  //       console.error("Error adding billing:", error);
  //       alert("Failed to add billing information");
  //     }
  //   }
  // };

  const [selectedInstallment, setSelectedInstallment] = React.useState("");

  const handleSelectChange = (e) => {
    setSelectedInstallment(e.target.value);
  };

  // const addbil = async () => {
  //   let billingData = {
  //     loan: paymentType,
  //     installment: Instalment,
  //     date: bdate,
  //   };

  //   if (!selectedInstallment) {
  //     alert('Please select an installment.');
  //     return;
  //   }

  //   // Only include floor if paymentType is not Customized
  //   if (paymentType !== "Customized") {
  //     billingData.floor = floor;
  //   }

  //   if (paymentType === "Customized") {
  //     // Find the selected installment label
  //     const selectedInst = installments.find((inst) => inst.selected);
  //     if (!selectedInst) {
  //       alert('Please select an installment.');
  //       return;
  //     }
  //     billingData = {
  //       ...billingData,
  //       selectedInstallment: selectedInstallment, // Send only the selected installment label
  //     };
  //    ; // Optionally set state for selected installment
  //   }

  //   try {
  //     console.log("Billing Data:", billingData);
  //     const response = await axios.post(
  //       "http://localhost:3003/api/addBilling",
  //       billingData,

  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     alert(response.data.message);
  //   } catch (error) {
  //     if (error.response) {
  //       console.error("Backend error response:", error.response.data);
  //       alert(
  //         `Failed to add billing information: ${error.response.data.error}`
  //       );
  //     } else {
  //       console.error("Error adding billing:", error);
  //       alert("Failed to add billing information");
  //     }
  //   }
  // };

  // https://buildfastapi.kollagroup.in/create-customer/api/v1/api/addBilling

  //16-08-2024

  // const addbil = async () => {
  //   let billingData = {
  //     loan: paymentType,
  //     installment: Instalment,
  //     date: bdate,
  //   };

  //   // Only include floor if paymentType is not Customized
  //   if (paymentType !== "Customized") {
  //     billingData.tower = tower;
  //   }

  //   if (paymentType === "Customized") {
  //     billingData = {
  //       ...billingData,
  //       selectedInstallment: selectedInstallment,
  //     };
  //   }

  //   // https://buildfastapi.kollagroup.in/create-customer/api/v1/api/addBilling

  //   try {
  //     console.log("Billing Data:", billingData);
  //     const response = await axios.post(
  //       "http://localhost:4000/api/addBilling",
  //       billingData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     alert(response.data.message);
  //   } catch (error) {
  //     if (error.response) {
  //       console.error("Backend error response:", error.response.data);
  //       alert(`Failed to add billing information: ${error.response.data.error}`);
  //     } else {
  //       console.error("Error adding billing:", error);
  //       alert("Failed to add billing information");
  //     }
  //   }
  // };
  const [gstPercentage, setGstPercentage] = useState("0.00");

  //16-08-2024 working

  // const addbil = async () => {
  //   // Construct the billing data based on paymentType
  //   let billingData = {
  //     loan: paymentType,
  //     installment: Instalment,
  //     date: bdate,
  //   };

  //   // Include 'tower' only if paymentType is not 'Customized'
  //   if (paymentType !== "Customized") {
  //     billingData.tower = tower;
  //   }

  //   // If paymentType is 'Customized', include 'selectedInstallment'
  //   if (paymentType === "Customized") {
  //     billingData = {
  //       ...billingData,
  //       selectedInstallment: selectedInstallment,
  //     };
  //   }

  //   // URL of the API endpoint
  //   const apiUrl = "http://localhost:4000/api/addBilling";

  //   try {
  //     console.log("Billing Data:", billingData); // Log the billing data

  //     // Make the POST request to the API
  //     const response = await axios.post(apiUrl, billingData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     // Handle successful response
  //     if (response.data.message) {
  //       alert(response.data.message); // Show success message from the response
  //     } else {
  //       alert("Billing information added successfully!");

  //     }

  //     setPaymentType('');
  //     setInstalment('');
  //     setBdate('');
  //     setTower('');
  //     setSelectedInstallment('');
  //   } catch (error) {
  //     // Handle errors based on the type of error
  //     if (error.response) {
  //       // API returned an error response
  //       console.error("Backend error response:", error.response.data);

  //       // Handle specific error messages from the API
  //       switch (error.response.status) {
  //         case 400:
  //           alert(`Bad Request: ${error.response.data.error || "Invalid request data"}`);
  //           break;
  //         case 404:
  //           alert(`Not Found: ${error.response.data.error || "Resource not found"}`);
  //           break;
  //         case 500:
  //           alert(`Server Error: ${error.response.data.error || "An error occurred on the server"}`);
  //           break;
  //         default:
  //           alert(`Error: ${error.response.data.error || "An unknown error occurred"}`);
  //       }
  //     } else if (error.request) {
  //       // Request was made but no response was received
  //       console.error("No response received:", error.request);
  //       alert("No response from server. Please try again later.");
  //     } else {
  //       // Something else caused the error
  //       console.error("Error setting up request:", error.message);
  //       alert("An unexpected error occurred. Please try again.");
  //     }
  //   }
  // };

  const addbil = async () => {
    // Construct the billing data based on paymentType
    let billingData = {
      ProjectName:Pname,
      loan: paymentType,
      installment: Instalment,
      date: bdate,
      gstPercentage: gstPercentage, // Include GST percentage
    };

    // Include 'tower' only if paymentType is not 'Customized'
    if (paymentType !== "Customized") {
      billingData.tower = tower;
    }

    // If paymentType is 'Customized', include 'selectedInstallment'
    if (paymentType === "Customized") {
      billingData = {
        ...billingData,
        selectedInstallment: selectedInstallment,
        cId:id
      };
    }

    // https://buildfastapi.kollagroup.in/billing/api/v1/api/addBilling http://localhost:4000/api/addBilling

    // URL of the API endpoint

    const apiUrl = "http://localhost:4000/api/addBilling";

    
    try {
      console.log("Billing Data:", billingData); // Log the billing data

      // Make the POST request to the API
      const response = await axios.post(apiUrl, billingData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Handle successful response
      if (response.data.message) {
        alert(response.data.message); // Show success message from the response
      } else {
        alert("Billing information added successfully!");
      }

      // Clear form fields
      setPaymentType("");
      setInstalment("");
      setBdate("");
      setTower("");
      setSelectedInstallment("");
      setGstPercentage(""); // Clear GST percentage field
    } catch (error) {
      // Handle errors based on the type of error
      if (error.response) {
        // API returned an error response
        console.error("Backend error response:", error.response.data);

        // Handle specific error messages from the API
        switch (error.response.status) {
          case 400:
            alert(
              `Bad Request: ${
                error.response.data.error || "Invalid request data"
              }`
            );
            break;
          case 404:
            alert(
              `Not Found: ${error.response.data.error || "Resource not found"}`
            );
            break;
          case 500:
            alert(
              `Server Error: ${
                error.response.data.error || "An error occurred on the server"
              }`
            );
            break;
          default:
            alert(
              `Error: ${
                error.response.data.error || "An unknown error occurred"
              }`
            );
        }
      } else if (error.request) {
        // Request was made but no response was received
        console.error("No response received:", error.request);
        alert("No response from server. Please try again later.");
      } else {
        // Something else caused the error
        console.error("Error setting up request:", error.message);
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  const Pname = sessionStorage.getItem("ProjectName");
  console.log(Pname);

  const [ucinValue, setUcinNumber] = useState("");
  const [customerNameB, setCustomerNameB] = useState("");

  // const handleUcinChange = async (e) => {
  //   const { value } = e.target;
  //   setUcinNumber(value);

  //   try {
  //     const response = await fetch(`http://localhost:3003/ucin/customers?ucin=${value}`);
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch customer details');
  //     }
  //     const data = await response.json();
  //     const customer =data;
  //     console.log(data)

  //     if (customer) {
  //       setCustomerNameB(customer.customerName)
  //       const instalmentResponse = await axios.get(`http://localhost:3003/api/installments?customer_id=${customer.id}`);
  //       const instalmentData = instalmentResponse.data;
  //       const processedInstallments = instalmentData.installments.map((inst) => ({
  //         ...inst,
  //         label: `${inst.installmentLabel} - ${inst.dueAmount}`
  //       }));

  //       setInstallments(processedInstallments);
  //       setPaymentType("Customized");

  //     }

  //   } catch (error) {
  //     console.error('Error fetching customer details:', error);
  //   }
  // };

  const [id,setId]=useState('')

  const handleUcinChange = async (e) => {
    const { value } = e.target;
    setUcinNumber(value);

    try {
      const response = await fetch(
        `https://buildfastapi.kollagroup.in/create-customer/api/v1/ucin/customers?ucin=${value}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch customer details");
      }

      const data = await response.json();
      const customer = data;

      console.log(data);

      if (customer) {
        setCustomerNameB(customer.customerName);
        setId(customer.id)

        try {
          const installmentResponse = await axios.get(
            `https://buildfastapi.kollagroup.in/create-customer/api/v1/api/installments?customer_id=${customer.id}`
          );
          const installmentData = installmentResponse.data;

          const processedInstallments = installmentData.installments.map(
            (inst, index) => ({
              ...inst,
              installmentLabel: `installment${index + 1}`,
              label: `Installment ${index + 1} - ${inst.dueAmount}`,
            })
          );

          const labels = processedInstallments.map((inst) => inst.label);

          // setInstallments(labels);
          // console.log(labels)

          setInstallments(processedInstallments);
          console.log(processedInstallments);
          setPaymentType("Customized");
        } catch (installmentError) {
          console.error("Error fetching installments:", installmentError);
        }
      } else {
        setCustomerNameB(""); // Clear customer name if no customer found
        setInstallments([]); // Clear installments if no customer found
        setPaymentType("");
      }
    } catch (error) {
      console.error("Error fetching customer details:", error);
      setCustomerNameB(""); // Clear customer name if an error occurs
      setInstallments([]); // Clear installments if an error occurs
      setPaymentType("");
    }
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Item className="card col-10 mx-auto">
            <Typography style={{ fontSize: 21, color: "rgb(126, 110, 228)" }}>
              <FaCaretLeft
                onClick={() => window.location.reload()}
                style={{
                  marginTop: "-0.3rem",
                  cursor: "pointer",
                  color: "rgb(126, 110, 228)",
                }}
              />
              Generate Bill
            </Typography>
            <Form noValidate validated={validated} onSubmit={formValidation}>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <label style={{ color: "gray", fontSize: 13 }}>Billing</label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Individual Billing"
                      name="billing"
                      type={type}
                      id={`inline-${type}-1`}
                      value="individualBilling"
                      checked={billing === "individualBilling"}
                      onChange={handleBillingCheckboxChange}
                    />
                    <Form.Check
                      inline
                      label="Bulk Billing"
                      name="billing"
                      type={type}
                      id={`inline-${type}-3`}
                      value="BulkBilling"
                      checked={billing === "BulkBilling"}
                      onChange={handleBillingCheckboxChange}
                    />
                  </div>
                ))}
                <Form.Control.Feedback type="invalid">
                  Please select billing option
                </Form.Control.Feedback>
              </Form.Group>

              {billing === "BulkBilling" && (
                <>
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 14 }}>
                      Project Name
                    </Typography>
                    <Form.Select
                      required
                      onChange={(e) => get_block(e.target.value)}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Project Name</option>
                      {response2
                        .filter((item) => item.ProjectName === Pname)
                        .map((data) => (
                          <option
                            key={data.ProjectName}
                            value={data.ProjectName}
                          >
                            {data.ProjectName}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid manager
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Block
                    </Typography>
                    <Form.Select
                      required
                      onChange={(e) => get_tower(e.target.value)}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Block Name</option>
                      {response3.map((data, index) => (
                        <React.Fragment key={index}>
                          {data.Blocks.split(",").map((block, blockIndex) => (
                            <option key={blockIndex}>{block.trim()}</option>
                          ))}
                        </React.Fragment>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter project name
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Tower
                    </Typography>
                    <Form.Select
                      required
                      onChange={(e) => get_floor(e.target.value)}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Tower Name</option>
                      {responset.map((data, index) => (
                        <React.Fragment key={index}>
                          {data.Towers.split(",").map((tower, towerIndex) => (
                            <option key={towerIndex}>{tower.trim()}</option>
                          ))}
                        </React.Fragment>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter project name
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Floor
                    </Typography>
                    <Form.Select
                      required
                      onChange={(e) => {
                        get_unit(e.target.value);
                      }}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Floor</option>
                      {[
                        ...new Set(response4.map((data) => data.floor.trim())),
                      ].map((floor, index) => (
                        <option key={index}>{floor}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter project name
                    </Form.Control.Feedback>
                  </Form.Group> */}
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Payment Type
                    </Typography>
                    <Form.Select
                      required
                      onChange={handlePaymentTypeChange}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Payment Type</option>
                      <option value="RERA">RERA</option>
                      <option value="PRE EMI">PRE EMI</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select payment type
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Installments
                    </Typography>
                    <Form.Select
                      required
                      onChange={(e) => {
                        const value = e.target.value;
                        console.log("Selected Installment:", value); // Debugging log
                        setInstalment(value);
                      }}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Installment</option>
                      {installments.map((installment, index) => (
                        <option key={index} value={installment}>
                          {installment}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter project name
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustomGst">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      GST Percentage
                    </Typography>
                    <Form.Control
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="Enter GST Percentage"
                      required
                      onChange={(e) => setGstPercentage(e.target.value)}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid GST percentage
                    </Form.Control.Feedback>
                  </Form.Group>

                  <FormGroup as={Col} md="6" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: "13" }}>
                      Date
                    </Typography>
                    <Form.Control
                      type="date"
                      required
                      onChange={(e) => setBdate(e.target.value)}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                  </FormGroup>
                </>
              )}

              {billing === "individualBilling" && (
                <>
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      UCIN Number
                    </Typography>
                    <Form.Control
                      required
                      placeholder="Enter UCIN Number"
                      // onChange={(e) => getCustomerDetails(e.target.value)}
                      value={ucinValue}
                      onChange={handleUcinChange}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter UCIN Number
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Customer Name
                    </Typography>
                    <Form.Control
                      required
                      value={customerNameB}
                      readOnly
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Customer Name
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Payment Type
                    </Typography>
                    <Form.Control
                      required
                      value={paymentType}
                      readOnly
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Payment Type
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Installment
                    </Typography>
                    <Form.Select
                      required
                      value={selectedInstallment}
                      onChange={handleSelectChange}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Installment</option>
                      {installments.map((installment) => (
                        <option key={installment.id} value={installment.label}>
                          {installment.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Installment
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustomGst">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      GST Percentage
                    </Typography>
                    <Form.Control
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="Enter GST Percentage"
                      required
                      onChange={(e) => setGstPercentage(e.target.value)}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid GST percentage
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Date
                    </Typography>
                    <Form.Control
                      type="date"
                      required
                      onChange={(e) => setBdate(e.target.value)}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid date.
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={addbil}
              >
                Generate Bill
              </Button>
            </Form>
          </Item>
        </Grid>
      </Grid>
      <div>
        <Dialog
          fullScreen={fullScreen}
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <div
            style={{
              position: "relative",
              backgroundColor: "rgb(126, 110, 228)",
              width: "39rem",
              padding: "1rem",
            }}
          >
            <Typography style={{ color: "white", fontSize: "1.1rem" }}>
              Send Instalment -----------------!
            </Typography>
            {response6.map((data) => {
              let color = "";
              let widths = "";

              if (data.lead === "Not Customer") {
                color = "red";
                widths = false;
              }

              if (data.lead === "Customer") {
                color = "green";
                widths = true;
              }

              let downPayment = "";
              let inst1 = "";
              let inst2 = "";
              let inst3 = "";

              downPayment = data.budget * (30 / 100);
              inst1 = data.budget * (20 / 100);
              inst2 = data.budget * (20 / 100);
              inst3 = data.budget * (30 / 100);

              return (
                <div
                  key={data.id}
                  style={{
                    border: "1px solid white",
                    borderRadius: "0.3rem",
                    marginBottom: 12,
                  }}
                >
                  <div style={{ marginTop: 10, padding: 4 }}>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "1.1rem",
                        borderBottom: "1px solid white",
                      }}
                    >
                      Customer Details
                    </Typography>
                    <br />
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "0.8rem",
                        marginBottom: 6,
                      }}
                    >
                      Customer Name: <span>{data.customerName}</span>
                    </Typography>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "0.8rem",
                        marginBottom: 6,
                      }}
                    >
                      Customer Email: <span>{data.email}</span>
                    </Typography>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "0.8rem",
                        marginBottom: 6,
                      }}
                    >
                      Customer Mobile: <span>{data.mobileNumber}</span>
                    </Typography>
                  </div>

                  <div style={{ marginTop: 10, padding: 4 }}>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "1.1rem",
                        borderBottom: "1px solid white",
                      }}
                    >
                      Instalments Pricing
                    </Typography>
                    <br />
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "0.8rem",
                        marginBottom: 6,
                      }}
                    >
                      Down Payment:{" "}
                      <span>
                        <FaRupeeSign
                          style={{ fontSize: 11, marginTop: "-0.08rem" }}
                        />
                        {downPayment} /-
                      </span>
                    </Typography>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "0.8rem",
                        marginBottom: 6,
                      }}
                    >
                      1st Instalment:{" "}
                      <span>
                        <FaRupeeSign
                          style={{ fontSize: 11, marginTop: "-0.08rem" }}
                        />
                        {inst1} /-
                      </span>
                    </Typography>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "0.8rem",
                        marginBottom: 6,
                      }}
                    >
                      2st Instalment:{" "}
                      <span>
                        <FaRupeeSign
                          style={{ fontSize: 11, marginTop: "-0.08rem" }}
                        />
                        {inst2} /-
                      </span>
                    </Typography>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "0.8rem",
                        marginBottom: 6,
                      }}
                    >
                      3st Instalment:{" "}
                      <span>
                        <FaRupeeSign
                          style={{ fontSize: 11, marginTop: "-0.08rem" }}
                        />
                        {inst3} /-
                      </span>
                    </Typography>
                  </div>
                </div>
              );
            })}

            <Typography
              style={{
                color: "white",
                fontSize: "0.9rem",
                paddingTop: 3,
                position: "absolute",
                right: 18,
                top: 13,
              }}
            >
              Send:{" "}
              <span
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => bookCustomer()}
              >
                Yes
              </span>{" "}
              /{" "}
              <span
                style={{ color: "lightgray", cursor: "pointer" }}
                onClick={() => handleClose()}
              >
                No
              </span>
            </Typography>
          </div>
        </Dialog>
      </div>
    </Box>
  );
}
