import axios from 'axios'
import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import TopCard from './TopCard';
import AreaChartPage from './AreaChart';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'Auto',
    color: theme.palette.text.secondary,
}));

const ProjectStatustAndGraf = () => {

    return (
        <Box sx={{ flexGrow: 1, }}>
             <Typography style={{ fontSize: 21, color: 'rgb(126, 110, 228)', fontWeight: '700', marginBottom: 10 }} >Dashboard / Home</Typography>
            <TopCard></TopCard>
            <Grid container spacing={2}>
                <Grid style={{ height: '8.6rem', marginBottom: '0.8rem', }} item xs={4}>
                    <div className='topCard'>
                        <Item elevation={0} sx={{ height: '7.2rem', position: 'relative' }}>
                            <Typography style={{ marginBottom: 3 }}>Monthly Sales</Typography>
                            <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem', backgroundColor: 'rgb(213, 208, 246)' }}>
                                <GroupOutlinedIcon style={{ position: 'relative', color: 'rgb(126, 110, 228)' }}   > </GroupOutlinedIcon>
                            </div>
                            <Typography color='green'>65</Typography>
                            <br />
                            <Typography>Total Monthly Sales</Typography>
                        </Item>
                    </div>
                </Grid>
                <Grid style={{ height: '8.15rem', marginBottom: '0.8rem' }} item xs={4}>
                    <div className='topCard'>
                        <Item elevation={0} sx={{ height: '7.2rem', position: 'relative' }}>
                            <Typography style={{ marginBottom: 3 }}>Profit</Typography>
                            <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem', backgroundColor: 'rgb(213, 208, 246)' }}>
                                <GroupOutlinedIcon style={{ position: 'relative', color: 'rgb(126, 110, 228)' }}   > </GroupOutlinedIcon>
                            </div>
                            <Typography color='green'>3620k</Typography>
                            <br />
                            <Typography style={{ fontSize: 11 }}>Total Profit</Typography>
                        </Item>
                    </div>
                </Grid>
                <Grid style={{ height: '8.15rem', marginBottom: '0.8rem' }} item xs={4}>
                    <div className='topCard'>
                        <Item elevation={0} sx={{ height: '7.2rem', position: 'relative' }}>
                            <Typography style={{ marginBottom: 3 }}>Payments</Typography>
                            <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem', backgroundColor: 'rgb(213, 208, 246)' }}>
                                <GroupOutlinedIcon style={{ position: 'relative', color: 'rgb(126, 110, 228)' }}   > </GroupOutlinedIcon>
                            </div>
                            <Typography color='green'>7930k</Typography>
                            <br />
                            <Typography style={{ fontSize: 11 }}>Total Payments</Typography>
                        </Item>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid style={{ height: '15rem', marginBottom: '0.8rem', }} item xs={4}>
                    <Grid container spacing={2}>
                        <Grid style={{ height: '15.3rem', marginBottom: '0.8rem', }} item xs={6}>
                            <div className='topCard'>
                                <Item elevation={0} sx={{ height: '15rem', position: 'relative' }}>

                                </Item>
                            </div>
                        </Grid>
                        <Grid style={{ height: '15rem', marginBottom: '0.8rem', }} item xs={6}>
                            <div className='topCard'>
                                <Item elevation={0} sx={{ height: '15rem', position: 'relative' }}>

                                </Item>
                            </div>
                        </Grid>
                        <Grid style={{ height: '14rem', marginBottom: '0.8rem', }} item xs={12}>
                            <div className='topCard'>
                                <Item elevation={0} sx={{ height: '9.8rem', position: 'relative' }}>

                                </Item>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ height: '26.3rem', marginBottom: '0.8rem' }} item xs={8}>
                    <div className='topCard'>
                        <Item elevation={0} sx={{ height: '26rem', position: 'relative' }}>
                            <AreaChartPage></AreaChartPage>
                        </Item>
                    </div>
                </Grid>
                <Grid style={{ height: '27.5rem' }} item xs={12}>
                    <div className='topCard'>
                        <Item elevation={0} sx={{ height: '26rem', position: 'relative' }}>
                        </Item>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}


export default ProjectStatustAndGraf