
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Typography, Paper } from '@mui/material';
import { jsPDF } from 'jspdf';
import { Button, Col, Form, Dropdown, Row, Container } from "react-bootstrap";
import 'jspdf-autotable';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const FloorReport = () => {
  const [ttowerno, setTowardsTowerNumber] = useState('');
  const [responset, setResponset] = useState([]);
  const [filterInput, setFilterInput] = useState('');
  const [prname, setPrName] = useState('');
  const [projectName, setProjectName] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([
    { id: 'floor', label: 'Floor', width: 160, isVisible: true },
    { id: 'unit_name', label: 'Unit Name', width: 170, isVisible: true },
    { id: 'status', label: 'Status', width: 0, isVisible: true },
    { id: 'unitsizeNum', label: 'Unit Size', width: 0, isVisible: true },
    { id: 'Facing', label: 'Facing', width: 0, isVisible: true },
    { id: 'cornor', label: 'Corner', width: 0, isVisible: true },
    { id: 'totalvalue', label: 'Total Value', width: 0, isVisible: true },
    { id: 'facingAmount', label: 'Facing Amount', width: 0, isVisible: true },
    { id: 'cornerAmount', label: 'Corner Amount', width: 0, isVisible: true },
    { id: 'BasePrice', label: 'Base Price', width: 0, isVisible: true },
    { id: 'advMaitanceAmt', label: 'Advance Maintenance Amount', width: 0, isVisible: true },
    { id: 'carpousAmt', label: 'Car Parking Amount', width: 0, isVisible: true },
    { id: 'cautionNonRefAmt', label: 'Caution Non Refundable Amount', width: 0, isVisible: true },
    { id: 'cautionRefAmt', label: 'Caution Refundable Amount', width: 0, isVisible: true },
    { id: 'clubHouseAmt', label: 'Clubhouse Amount', width: 0, isVisible: true },
    { id: 'waterElectricityAmt', label: 'Water Electricity Amount', width: 0, isVisible: true },
    { id: 'floorRiseAmount', label: 'Floor Rise Amount', width: 0, isVisible: true },
  ]);

  const [viewMode, setViewMode] = useState('Full View');
  
  const [selectedColumnIds, setSelectedColumnIds] = useState(selectedColumns.filter(col => col.isVisible).map(col => col.id));

  useEffect(() => {
    const updatedColumns = selectedColumns.map(col => ({
      ...col,
      isVisible: viewMode === 'Full View' ? true : selectedColumnIds.includes(col.id)
    }));
    setSelectedColumns(updatedColumns);
  }, [viewMode, selectedColumnIds]);

  
  
  useEffect(() => {
    getFloor();
    getProjectNames();
  }, []);

  useEffect(() => {
    if (viewMode === 'Full View') {
      setSelectedColumns(prevColumns => prevColumns.map(col => ({ ...col, isVisible: true })));
    }
  }, [viewMode]);

  const getFloor = () => {
    axios
      .post('https://buildfastapi.kollagroup.in/project/api/v1/towers-getall')
      .then((res) => {
        if (res.data.status === 200) {
          setResponset(res.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching floor data:', error);
      });
  };

  const getProjectNames = () => {
    axios
      .get('https://buildfastapi.kollagroup.in/project/api/v1/get-basic')
      .then((res) => {
        setProjectName(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

//   const fetchData = async () => {
//     try {
//       const response = await fetch(
//         `https://buildfastapi.kollagroup.in/api/v1/api/project-details?ProjectName=${prname}&TowerNumber=${ttowerno}`
//       );
//       if (!response.ok) {
//         throw new Error('Failed to fetch data');
//       }
//       const data = await response.json();
//       setFetchedData(data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

  useEffect(() => {
    if (ttowerno && prname) {
    //   fetchData();
      get_floors(ttowerno)
    }
  }, [ttowerno, prname]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    const columns = selectedColumns
      .filter(col => col.isVisible)
      .map((col) => ({
        header: col.label,
        dataKey: col.id,
        width: col.width,
      }));

    const rows = fetchedData.map((row) => {
      const filteredRow = {};
      selectedColumns.forEach((col) => {
        if (col.isVisible) {
          filteredRow[col.id] = row[col.id];
        }
      });
      return filteredRow;
    });

    doc.autoTable({
      columns,
      body: rows,
      margin: { top: 10, left: 10 },
      columnStyles: { 0: { cellWidth: 'auto' } },
    });

    doc.save('tablepdf.pdf');
  };

  const handleColumnToggle = (columnId) => {
    setSelectedColumns(prevColumns =>
      prevColumns.map(col =>
        col.id === columnId ? { ...col, isVisible: !col.isVisible } : col
      )
    );
  };

  const handleColumnSelection = (colId) => {
    setSelectedColumnIds(prev =>
      prev.includes(colId) ? prev.filter(id => id !== colId) : [...prev, colId]
    );
  };


  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const userType = sessionStorage.getItem("userType");
    const projectNames = sessionStorage.getItem("ProjectName");

    // Filter projectNamesss based on userType and ProjectName
    const filtered = projectName.filter(project => {
      if (userType === "Admin") {
        return project.ProjectName === projectNames;
      }
      return true; // Show all projects for non-admins
    });

    setFilteredProjects(filtered);
  }, [projectName]);


  

  const [response4, setResponse4] = React.useState([]);

  console.log(response4)

  // https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor

  const get_floors = (ttowerno) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor",
        {
          value1: ttowerno,
        }
      )
      .then((res) => {
        setResponse4(res.data);
        // setTower(value1);
        //select_floor();
      });
  };

useEffect(()=>{
    get_floors()
},[ttowerno])

  const [selectedFloor, setSelectedFloor] = useState('');

  const handleFloorChange =async (e) => {
    const floor = e.target.value;
    setSelectedFloor(floor);

  }


  const [flatStatus, setFlatStatus] = useState([]);
  console.log(flatStatus)
  const [soldCount, setSoldCount] = useState(0);
  const [unsoldCount, setUnsoldCount] = useState(0);
  const [error, setError] = useState('');

  // Function to fetch flat status
  const fetchFlatStatus = async () => {
    if (!prname || !ttowerno || !selectedFloor) {
      setError('All fields (projectName, tower, floor) are required.');
      return;
    }

    try {
      const response = await axios.post('https://buildfastapi.kollagroup.in/project/api/v1/api/getFlatStatus', {
        projectName:prname,
        tower:ttowerno,
        floor:selectedFloor
      });

      // Handle the response data
      setFlatStatus(response.data.data);
      setSoldCount(response.data.counts.sold);
      setUnsoldCount(response.data.counts.unsold);
      setError(''); // Clear error on successful request
    } catch (err) {
      setError('Error fetching data from the server');
      console.error(err);
    }
  };

  // Using useEffect to call the function when the component mounts or params change
  useEffect(() => {
    if (prname && ttowerno && selectedFloor) {
      fetchFlatStatus();
    }
  }, [prname, ttowerno, selectedFloor]); 



  const exportToPDF = () => {
    const doc = new jsPDF();

    // Add a title or header
    doc.setFontSize(18);
    doc.text('Flat Status Report', 14, 10);
    doc.setFontSize(12);

    // Prepare table data for jsPDF AutoTable
    const tableData = flatStatus.map((flat) => [
      flat.flatNo,
      flat.unitSize,
      flat.Facing,
      flat.mort_checked === 1 ? 'Yes' : 'No',
      flat.flatStatus
    ]);

    // Define the columns
    const columns = ['Unit Name', 'Unit Size', 'Facing', 'Mortgage', 'Flat Status'];

    // Add the table to the PDF
    doc.autoTable({
      head: [columns],
      body: tableData,
      startY: 30, // Position the table below the title
      theme: 'striped', // Optional: Adds a striped theme to the table
      styles: {
        fontSize: 10,
      }
    });

    // Save the generated PDF
    doc.save('flat-status-report.pdf');
  };

  return (
    <>
      <div className="card mt-2" style={{ border: 'none', paddingLeft: 15, paddingRight: 15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div>
          <label style={{ fontSize: '13px', color: 'gray' }}>Search Filter</label>
          <br />
          <input
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '20rem', border: '0.7px solid lightgray' }}
            placeholder="Search Details.."
          />
        </div>
        <div>
          <div className="d-flex flex-row align-items-center p-2 mt-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01" className="mr-2 p-2">
              <Typography style={{ color: 'gray', fontSize: 13 }}>ProjectName</Typography>
              <Form.Select
                required
                onChange={(e) => setPrName(e.target.value)}
                value={prname}
                type="text"
                style={{ padding: '0.42rem', marginBottom: 14 }}
              >
                <option>Select Project</option>
                {filteredProjects.map((item) => (
                  <option key={item.ProjectName} value={item.ProjectName}>
                    {item.ProjectName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please Enter Valid Project Name</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Typography style={{ color: 'gray', fontSize: 13 }}>Tower</Typography>
              <Form.Select
                required
                value={ttowerno}
                onChange={(e) => setTowardsTowerNumber(e.target.value)}
                type="text"
                style={{ padding: "0.42rem", marginBottom: 14 }}
              >
                <option>Select Tower</option>
                {responset
                  .filter((item) => item.Towers.startsWith(prname))
                  .map((item, index) => (
                    <option key={index}>{item.Towers}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please Enter Valid Tower Number</Form.Control.Feedback>
            </Form.Group>
       
            
            <Form.Group as={Col} md="4" controlId="validationCustom01" className='mr-2 p-2'>
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Floor
                  </Typography>
                  <Form.Select
                    required
                  onChange={handleFloorChange}
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  >
                    <option>Select Floor</option>
                    {[
                      ...new Set(response4.map((data) => data.floor.trim())),
                    ].map((floor, index) => (
                      <option key={index}>{floor}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please Enter project name
                  </Form.Control.Feedback>
                </Form.Group>
            {/* <Form.Group as={Col} md="5" controlId="viewMode">
              <Typography style={{ color: 'gray', fontSize: 13 }}>View Mode</Typography>
              <Form.Select
                onChange={(e) => setViewMode(e.target.value)}
                value={viewMode}
                style={{ padding: '0.42rem', marginBottom: 14 }}
              >
                <option value="Full View">Full View</option>
                <option value="Customized View">Customized View</option>
              </Form.Select>
            </Form.Group> */}
          </div>
        </div>
      </div>

    
      <div className="d-flex flex-row align-items-center">
        {viewMode === 'Customized View' && (
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Select Columns
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: '800px', overflowX: 'auto' }}>
              <Container>
                <Row>
                  {selectedColumns.map((col, index) => (
                    <Col key={col.id} xs={6} md={4} lg={3}>
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${col.id}`}
                        label={col.label}
                        checked={selectedColumnIds.includes(col.id)}
                        onChange={() => handleColumnSelection(col.id)}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      <TableContainer component={Paper}>
      <Table id="table-to-xls">
        <TableHead>
          <TableRow>
            <TableCell>Unit Name</TableCell>
            <TableCell>Unit Size</TableCell>
            <TableCell>Facing</TableCell>
            <TableCell>Mortgage</TableCell>
            <TableCell>Flat Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {flatStatus.map((flat, index) => (
            <TableRow key={index}>
              <TableCell>{flat.flatNo}</TableCell>
              <TableCell>{flat.unitSize}</TableCell>
              <TableCell>{flat.Facing}</TableCell>
              <TableCell>{flat.mort_checked === 1 ? 'Yes' : 'No'}</TableCell>
              <TableCell 
                sx={{
                  color: flat.flatStatus === 'sold' ? 'green' : flat.flatStatus === 'unsold' ? 'red' : 'black',
                  fontWeight: 'bold'
                }}
              >
                {flat.flatStatus}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={fetchedData
          .filter(row => selectedColumns.some(col => col.isVisible && row[col.id]?.toString().toLowerCase().includes(filterInput.toLowerCase())))
          .length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <div className='d-flex flex-row align-items-center'>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Excel"
          style={{ display: 'none' }} // Hide default button
        />

        <Button
          variant="contained"
          color="danger"
          size="large"
          style={{ marginLeft: '2rem', height: '50px' }}
          onClick={exportToPDF}
        >
          PDF
        </Button>
      </div>
    </>
  );
};

export default FloorReport;
