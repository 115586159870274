import react, { useState } from 'react'
import { FaFacebook, FaFacebookF, FaInstagram, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa'
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios'
import Swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha";


const UserLoginPage = () => {

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    // https://buildfastapi.kollagroup.in/api/v1/admin-login

    const login = () => {

        const login = 'sjddmfcjdnfkh876438knfjhdhf_&bjfdhkfk3njsjdhf%'

        axios.post('https://buildfastapi.kollagroup.in/api/v1/admin-login', {
            password,
            email
        }).then((res) => {
            console.log(res.data.data)
            if (res.data.status === 200) {
               
                sessionStorage.setItem('login', login)
                window.location.reload()
                window.location.href = '/'
                sessionStorage.setItem('superAdmin', 'superLogin')
                
            } else {

                axios.post('https://buildfastapi.kollagroup.in/account-setting/api/v1/user-login', {
                    password,
                    email
                }).then((res) => {
                    console.log(res.data.data)
                    if (res.data.status === 200) {
                        
                        sessionStorage.removeItem('superAdmin')
                        sessionStorage.setItem('login', login)
                        sessionStorage.setItem('UsersAccountSetting', res.data.data[0].UsersAccountSetting)
                        sessionStorage.setItem('LeadManagement', res.data.data[0].LeadManagement)
                        sessionStorage.setItem('Primary', res.data.data[0].Primary)
                        sessionStorage.setItem('Secondary', res.data.data[0].Secondary)
                        sessionStorage.setItem('Allocation', res.data.data[0].Allocation)
                        sessionStorage.setItem('ContactManagement', res.data.data[0].ContactManagement)
                        sessionStorage.setItem('ProjectManagement', res.data.data[0].ProjectManagement)
                        sessionStorage.setItem('ProjectName', res.data.data[0].ProjectName)
                        sessionStorage.setItem('CstmrManagement', res.data.data[0].CstmrManagement)
                        sessionStorage.setItem('Customers', res.data.data[0].Customers)
                        sessionStorage.setItem('Billing', res.data.data[0].Billing)
                        sessionStorage.setItem('Payment', res.data.data[0].Payment)
                        sessionStorage.setItem('Storemanagment', res.data.data[0].Storemanagment)
                        sessionStorage.setItem('PurchaseMaster', res.data.data[0].PurchaseMaster)
                        sessionStorage.setItem('PurchaseEntry', res.data.data[0].PurchaseEntry)
                        sessionStorage.setItem('StoresEntry', res.data.data[0].StoresEntry)
                        sessionStorage.setItem('MIS_Report', res.data.data[0].MIS_Report)
                        sessionStorage.setItem('Analitics', res.data.data[0].Analitics)
                        sessionStorage.setItem('Add', res.data.data[0].Add)
                        sessionStorage.setItem('View', res.data.data[0].View)
                        sessionStorage.setItem('Edit', res.data.data[0].Edit)
                        sessionStorage.setItem('Delete', res.data.data[0].Delete)
                        sessionStorage.setItem('project_name',res.data.data[0].ProjectManagement)
                        

                        if (res.data.data[0].UserType === 'Agent') {
                            sessionStorage.setItem('userType', res.data.data[0].UserType)
                            sessionStorage.setItem('user_name', res.data.data[0].UserName)
                        }else if(res.data.data[0].UserType === 'Sales Executive'){
                            sessionStorage.setItem('userType', res.data.data[0].UserType)
                            sessionStorage.setItem('user_name', res.data.data[0].UserName)
                            sessionStorage.setItem('emailExcutive',res.data.data[0].Email)
                            sessionStorage.setItem('executiveName',res.data.data[0].UserName)
                            sessionStorage.setItem('salesBoard', 'display')
                          
                        }else if(res.data.data[0].UserType === 'Admin'){
                            sessionStorage.setItem('userType', res.data.data[0].UserType)
                            sessionStorage.setItem('user_name', res.data.data[0].UserName)
                            sessionStorage.setItem('admindashboard', '1')
                            // sessionStorage.setItem('emailExcutive',res.data.data[0].Email)
                            // sessionStorage.setItem('executiveName',res.data.data[0].UserName)
                            // sessionStorage.setItem('salesBoard', 'display')
                          
                        }
                        
                        else if(res.data.data[0].UserType === 'Manager'){
                            sessionStorage.setItem('userType', res.data.data[0].UserType)
                            sessionStorage.setItem('user_name', res.data.data[0].UserName)
                            
                            sessionStorage.setItem('mangerdash', '1')
                           
                        }else if(res.data.data[0].UserType==='CRM Head'){
                            sessionStorage.setItem('userType',res.data.data[0].UserType)
                            sessionStorage.setItem('user_name',res.data.data[0].UserName)
                            sessionStorage.setItem('crmdash','1')
                        }
                        else if(res.data.data[0].UserType==='CRM Executive'){
                            sessionStorage.setItem('userType',res.data.data[0].UserType)
                            sessionStorage.setItem('user_name',res.data.data[0].UserName)
                            sessionStorage.setItem('crmExName',res.data.data[0].UserName)
                            sessionStorage.setItem('crmdash','1')
                        }
                        else if(res.data.data[0].UserType==='Accountant'){
                            sessionStorage.setItem('userType',res.data.data[0].UserType)
                            sessionStorage.setItem('user_name',res.data.data[0].UserName)

                            sessionStorage.setItem('accountdash','1')
                        }
                        
                        else {
                            sessionStorage.setItem('user_name', res.data.data[0].UserName)
                        }
                        window.location.reload()
                        window.location.href = '/'
                    } else {
                        if (res.data.status === 202) {
                            sessionStorage.setItem('customerEmail', email)
                            sessionStorage.setItem('customer', 'customer')
                            sessionStorage.removeItem('login')
                            sessionStorage.removeItem('superAdmin')
                            window.location.href = '/customer-dashboard'
                        } else {
                             // Display pop-up for wrong email or password
                             Swal.fire({
                                icon: 'error',
                                title: 'Oops...Error',
                                text: 'Incorrect your email or password!',
                            });
                        }
                    }
                })
            }
        })
    }

 const   onChange = () => {
        // this.setState({ isActiveBtn: true })
    }


    return (
        <div className='card login-main-container' style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, borderRadius: '0.1rem', border: 'none' }}  >
            {/* <div className='shadow' style={{ paddingTop: 9, backgroundColor: 'white', paddingBottom: 1, paddingLeft: 81, paddingRight: 81 }} >
                <div>
                    <p style={{ fontWeight: '400', color: 'rgb(126, 110, 228)', fontSize: 14 }}><b>Effective August 1, 2023, we are retiring some older versions of On-Screen Takeoff and Quick Bid.</b><br />
                        On-Screen Takeoff v.3.95.03.02 and older, and Quick Bid 4.97.03.01 and older will no longer be supported or distributed after August 1. You will not be able to activate a license for these versions, either. For more information, click here.
                        To discuss your available options, call your Customer Success Manager, Monday through Friday, 7am-6pm CST, at (866) 627-6246.
                    </p>
                </div>                                                                                             
            </div> */}
            <div className='navbar3' style={{ paddingTop: 31, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 81, paddingRight: 81 }} >
                <div>

                </div>
            </div>

            <div className='card' style={{ height: '18rem', position: 'relative', justifyContent: 'center', alignItems: 'center', borderRadius: '0.04rem', border: 'none', marginBottom: 10 }} >
                <div className='loginBackground' style={{ opacity: 0.7, backgroundColor: 'black', height: '17rem', borderBottomLeftRadius: '0.7rem', borderBottomRightRadius: '0.7rem' }}>
                </div>
            </div>
            <div className='col-6 mx-auto card auth-form-container' style={{ border: 'none', marginTop: 61, borderRadius: '1.4rem', }}>
                <div className='row p-1' >

                    <div className='col-8 mx-auto p-1' >
                        <img src='https://png.pngtree.com/template/20190926/ourmid/pngtree-home-repair-logo-with-maintenance-tools-and-house-construction-c-image_309799.jpg' width='81px' />
                        <br />
                        <br />
                        <Typography style={{ fontSize: 19, fontWeight: 'bold', color: 'rgb(42, 49, 111)', marginTop: 12, marginBottom: 3 }}>Welcome to BuildFast!</Typography>
                        <Typography style={{ fontSize: 13, color: 'rgb(42, 49, 111)', marginTop: 1, marginBottom: 17 }}>Please sign-in to your account and start the adventure</Typography>
                        <label className='d-flex'>Email <span style={{ color: 'red' }} >*</span></label>
                        <input onChange={(e) => {
                            setEmail(e.target.value)
                        }} style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 25, outline: 'none' }} placeholder='Enter your email' />
                        <label className='d-flex' >Password <span style={{ color: 'red' }} >*</span></label>
                        <input onChange={(e) => {
                            setPassword(e.target.value)
                        }} type='password' style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.4rem', width: '100%', marginBottom: 4, outline: 'none' }} placeholder='Enter your password' />
                        <Link to='/forgot-password' style={{ textDecoration: 'none' }} ><Typography style={{ marginBottom: 50, marginBottom: 39, color: 'rgb(44, 53, 131)' }}>Forgot password ?</Typography></Link>
                        <div className='text-center mt-2 mb-3 g-recaptcha'>

<ReCAPTCHA
    sitekey="6Les3iIqAAAAAGiQLhbYe73whdLCWIG7NOCHTRCD"
    onChange={onChange}
/>
</div>
                        <Button onClick={() => login()} variant="contained" style={{ marginBottom: 35, width: '100%', backgroundColor: 'rgb(44, 53, 131)' }}>Login</Button>
                        {/* <div style={{ marginBottom: 10 }}>
                            <Link to='/create-account' style={{ textDecoration: 'none' }} ><Typography className='d-flex' style={{ textAlign: 'center', color: 'gray' }}>New on our platform? <span style={{ color: 'rgb(44, 53, 131)' }} >Create an account</span></Typography></Link>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='card' style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 28, backgroundColor: 'rgb(126, 110, 228)', borderRadius: '0.02rem' }}>
                {/* <div className='card' style={{ width: '14rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '0.02rem' }}>
                    <FaInstagram size={41} color='#fff'></FaInstagram>
                    <FaFacebookF size={41} color='#fff'></FaFacebookF>
                    <FaYoutubeSquare size={41} color='#fff'></FaYoutubeSquare>
                    <FaTwitterSquare size={41} color='#fff'></FaTwitterSquare>
                </div>
                <div style={{ width: '46rem', backgroundColor: 'white', height: '0.14rem', marginTop: 10 }} >

                </div> */}
                <Typography style={{ marginBottom: 10, color: 'white', fontSize: 18 }}>@2023 Emedha Technologies All Rights Reserved.</Typography>
            </div>
        </div>
    )
}

export default UserLoginPage