import { useEffect, useState } from "react";

import axios from 'axios';
import { TableBody, TablePagination, Typography } from '@mui/material';
import { Button, Col, Form, Dropdown, Row, Container } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const InvoiceReport = () => {
  const [ttowerno, setTowardsTowerNumber] = useState('');
  const [responset, setResponset] = useState([]);
  const [filterInput, setFilterInput] = useState('');
  const [prname, setPrName] = useState('');
  const [projectName, setProjectName] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [viewMode, setViewMode] = useState('Full View'); // Added state for view mode

  const [selectedColumns, setSelectedColumns] = useState([
    { id: 'customerName', label: 'Customer Name', width: 150, isVisible: true },
    { id: 'email', label: 'Email', width: 200, isVisible: true },
    { id: 'flatNo', label: 'Flat No', width: 200, isVisible: true },
    { id: 'floor', label: 'Floor', width: 160, isVisible: true },
    { id: 'installment1', label: 'Installment 1', width: 150, isVisible: true },
    { id: 'installment2', label: 'Installment 2', width: 150, isVisible: true },
    { id: 'installment3', label: 'Installment 3', width: 150, isVisible: true },
    { id: 'installment4', label: 'Installment 4', width: 150, isVisible: true },
    { id: 'installment5', label: 'Installment 5', width: 150, isVisible: true },
    { id: 'installment6', label: 'Installment 6', width: 150, isVisible: true },
    { id: 'installment7', label: 'Installment 7', width: 150, isVisible: true },
    { id: 'installment8', label: 'Installment 8', width: 150, isVisible: true },
    { id: 'installment9', label: 'Installment 9', width: 150, isVisible: true },
    { id: 'installment10', label: 'Installment 10', width: 150, isVisible: true },
    { id: 'invoice1', label: 'Invoice 1', width: 120, isVisible: true },
    { id: 'invoice2', label: 'Invoice 2', width: 120, isVisible: true },
    { id: 'invoice3', label: 'Invoice 3', width: 120, isVisible: true },
    { id: 'invoice4', label: 'Invoice 4', width: 120, isVisible: true },
    { id: 'invoice5', label: 'Invoice 5', width: 120, isVisible: true },
    { id: 'invoice6', label: 'Invoice 6', width: 120, isVisible: true },
    { id: 'invoice7', label: 'Invoice 7', width: 120, isVisible: true },
    { id: 'invoice8', label: 'Invoice 8', width: 120, isVisible: true },
    { id: 'invoice9', label: 'Invoice 9', width: 120, isVisible: true },
    { id: 'invoice10', label: 'Invoice 10', width: 120, isVisible: true },
    { id: 'balance1', label: 'Balance 1', width: 150, isVisible: true },
    { id: 'balance2', label: 'Balance 2', width: 150, isVisible: true },
    { id: 'balance3', label: 'Balance 3', width: 150, isVisible: true },
    { id: 'balance4', label: 'Balance 4', width: 150, isVisible: true },
    { id: 'balance5', label: 'Balance 5', width: 150, isVisible: true },
    { id: 'balance6', label: 'Balance 6', width: 150, isVisible: true },
    { id: 'balance7', label: 'Balance 7', width: 150, isVisible: true },
    { id: 'balance8', label: 'Balance 8', width: 150, isVisible: true },
    { id: 'balance9', label: 'Balance 9', width: 150, isVisible: true },
    { id: 'balance10', label: 'Balance 10', width: 150, isVisible: true },
    { id: 'date1', label: 'Date 1', width: 130, isVisible: true },
    { id: 'date2', label: 'Date 2', width: 130, isVisible: true },
    { id: 'date3', label: 'Date 3', width: 130, isVisible: true },
    { id: 'date4', label: 'Date 4', width: 130, isVisible: true },
    { id: 'date5', label: 'Date 5', width: 130, isVisible: true },
    { id: 'date6', label: 'Date 6', width: 130, isVisible: true },
    { id: 'date7', label: 'Date 7', width: 130, isVisible: true },
    { id: 'date8', label: 'Date 8', width: 130, isVisible: true },
    { id: 'date9', label: 'Date 9', width: 130, isVisible: true },
    { id: 'date10', label: 'Date 10', width: 130, isVisible: true },
    { id: 'loan', label: 'Loan', width: 100, isVisible: true },
    { id: 'tower', label: 'Tower', width: 150, isVisible: true },
    { id: 'ucin', label: 'UCIN', width: 100, isVisible: true },
    { id: 'totalValue', label: 'Total Value', width: 150, isVisible: true },
  ]);

  const [selectedColumnIds, setSelectedColumnIds] = useState(selectedColumns.filter(col => col.isVisible).map(col => col.id));

  useEffect(() => {
    const updatedColumns = selectedColumns.map(col => ({
      ...col,
      isVisible: viewMode === 'Full View' ? true : selectedColumnIds.includes(col.id)
    }));
    setSelectedColumns(updatedColumns);
  }, [viewMode, selectedColumnIds]);

  useEffect(() => {
    // Reset column visibility when the tower changes or view mode changes
    if (viewMode === 'Full View') {
      setSelectedColumns(cols =>
        cols.map(col => ({ ...col, isVisible: true }))
      );
    }
  }, [ttowerno, viewMode]);

  const handleColumnToggle = (columnId) => {
    const updatedColumns = selectedColumns.map(col =>
      col.id === columnId ? { ...col, isVisible: !col.isVisible } : col
    );
    setSelectedColumns(updatedColumns);
  };

  const getFloor = () => {
    axios
      .post('https://buildfastapi.kollagroup.in/project/api/v1/towers-getall')
      .then((res) => {
        if (res.data.status === 200) {
          setResponset(res.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching floor data:', error);
      });
  };

  const getProjectNames = () => {
    axios
      .get('https://buildfastapi.kollagroup.in/project/api/v1/get-basic')
      .then((res) => {
        setProjectName(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFloor();
    getProjectNames();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://buildfastapi.kollagroup.in/create-customer/api/v1/get-sales-data?tower=${ttowerno}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();

      // Combine data from the API into one list
      const combinedList = data.billingData.map(billing => {
        const payments = data.paymentData.filter(payment => payment.ucin === billing.ucin);
        
        return {
          ...billing,
          payments: payments.map(payment => ({
            invoice: payment.invoice,
            paidAmount: payment.paidAmount,
            billdate: payment.billdate,
          })),
        };
      });

      setCombinedData(combinedList); // Store combined data in state
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (prname) {
      fetchData();
    }
  }, [ttowerno]);

//   const downloadPDF = () => {
//     const doc = new jsPDF({
//       orientation: 'landscape',
//     });

//     const columnsPerPage = 10;

//     const generateTable = (columns, rows) => {
//       doc.autoTable({
//         columns: columns.map(col => ({ header: col.label, dataKey: col.id, width: col.width })),
//         body: rows,
//         margin: { top: 10, left: 10 },
//         columnStyles: { 0: { cellWidth: 'auto' } },
//       });
//     };

//     // const getVisibleColumns = () => selectedColumns.filter(col => col.isVisible);
//     // const visibleColumns = getVisibleColumns();
//     // const numPages = Math.ceil(visibleColumns.length / columnsPerPage);

//     const rows = invoiceData.map(row => {
//       const filteredRow = {};
//       visibleColumns.forEach(col => {
//         filteredRow[col.id] = row[col.id];
//       });
//       return filteredRow;
//     });

//     for (let page = 0; page < numPages; page++) {
//       const startColIndex = page * columnsPerPage;
//       const endColIndex = Math.min(startColIndex + columnsPerPage, visibleColumns.length);
//       const columnsToShow = visibleColumns.slice(startColIndex, endColIndex);

//       if (page > 0) {
//         doc.addPage();
//       }

//       generateTable(columnsToShow, rows);
//     }

//     doc.save('tablepdf.pdf');
//   };
const [page, setPage] = useState(0);  // Page number
const [rowsPerPage, setRowsPerPage] = useState(5);  // Rows per page

// Handle page change
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

// Handle rows per page change
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);  // Reset to page 0 when rows per page changes
};

// Paginate data according to the page and rowsPerPage


  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const userType = sessionStorage.getItem("userType");
    const projectNames = sessionStorage.getItem("ProjectName");

    // Filter projectNamesss based on userType and ProjectName
    const filtered = projectName.filter(project => {
      if (userType === "Admin") {
        return project.ProjectName === projectNames;
      }
      return true; // Show all projects for non-admins
    });

    setFilteredProjects(filtered);
  }, [projectName]);


  const [invoiceData, setData] = useState([]);
  useEffect(() => {
    axios.post('https://buildfastapi.kollagroup.in/create-customer/api/v1/payment-outstanding-report', {
      tower: ttowerno
    })
    .then((response) => {
      // If data is successfully fetched, set it to the state
      setData(response.data);
    })
    .catch((error) => {
      // Handle error response from server
      if (error.response && error.response.status === 404) {
        alert("Tower Data not found");
      } else {
        console.error("Error fetching the data", error);
      }
    });
  }, [ttowerno]);
  

  let serialNumber = 1; 


  const downloadInvoicePDF = () => {
    const doc = new jsPDF();
    
    // Define the table headers
    const headers = [
      'Serial No',
      'Name',
      'Flat No',
      'Invoice',
      'Amount',
      'Outstanding Days',
      '30 Days',
      '30-60 Days',
      '60-90 Days',
      '>90 Days',
    ];
  
    // Prepare data for the table
    const tableData = invoiceData.map((row, index) => {
      const agingData = [
        { invoice: row.invoice1, installment: row.installment1, daysOut: row.days_outstanding_1 },
        // ... (include all invoice fields similarly)
      ];
  
      // Logic to calculate aging totals (similar to your existing logic)
      const agingTotals = {
        "30Days": 0,
        "30_60Days": 0,
        "60_90Days": 0,
        ">90Days": 0,
      };
  
      agingData.forEach((item) => {
        if (item.invoice && item.installment !== "0") {
          const daysOut = item.daysOut;
          const amount = parseFloat(item.installment);
  
          if (daysOut <= 30) {
            agingTotals["30Days"] += amount;
          } else if (daysOut > 30 && daysOut <= 60) {
            agingTotals["30_60Days"] += amount;
          } else if (daysOut > 60 && daysOut <= 90) {
            agingTotals["60_90Days"] += amount;
          } else if (daysOut > 90) {
            agingTotals[">90Days"] += amount;
          }
        }
      });
  
      return [
        index + 1,
        row.customerName,
        row.flatNo,
        row.invoice1 || '-',
        row.installment1 || '-',
        row.days_outstanding_1 || '-',
        agingTotals["30Days"] > 0 ? agingTotals["30Days"] : '-',
        agingTotals["30_60Days"] > 0 ? agingTotals["30_60Days"] : '-',
        agingTotals["60_90Days"] > 0 ? agingTotals["60_90Days"] : '-',
        agingTotals[">90Days"] > 0 ? agingTotals[">90Days"] : '-',
      ];
    });
  
    // Add the table to the PDF
    doc.autoTable({
      head: [headers],
      body: tableData,
    });
  
    // Save the PDF
    doc.save('invoices.pdf');
  };


  const paginatedData = invoiceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <div className="card mt-2" style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', alignItems: 'center' }}>
        <div>
          <label style={{fontSize:'13px',color:'gray'}}>Search Filter</label>
          <br/>
          <input
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '20rem', border: '0.7px solid lightgray' }}
            placeholder="Search.."
          />
        </div>
        <div>
          <div className="d-flex flex-row align-items-center p-2 mt-3">
            <Form.Group as={Col} md="6" controlId="validationCustom01" className="mr-2 p-2">
              <Typography style={{ color: 'gray', fontSize: 13 }}>ProjectName</Typography>
              <Form.Select
                required
                onChange={(e) => setPrName(e.target.value)}
                value={prname}
                type="text"
                style={{ padding: '0.42rem', marginBottom: 14 }}
              >
                <option>Select Project</option>
                {filteredProjects.map((item) => (
                  <option key={item.ProjectName} value={item.ProjectName}>
                    {item.ProjectName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please Enter Valid Towards Flat Number</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Typography style={{ color: 'gray', fontSize: 13 }}>Tower</Typography>
              <Form.Select
                required
                value={ttowerno}
                onChange={(e) => setTowardsTowerNumber(e.target.value)}
                type="text"
                style={{ padding: "0.42rem", marginBottom: 14 }}
              >
                <option>Select Tower </option>
                {responset
                  .filter((item) => item.Towers.startsWith(prname)) // Filter towers that start with the project name
                  .map((item, index) => (
                    <option key={index}>{item.Towers}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please Enter Valid Towards Tower Number</Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group as={Col} md="5" controlId="viewMode">
              <Typography style={{ color: 'gray', fontSize: 13 }}>View Mode</Typography>
              <Form.Select
                required
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
                type="text"
                style={{ padding: '0.42rem', marginBottom: 14 }}
              >
                <option value="Full View">Full View</option>
                <option value="Customized View">Customized View</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please select a view mode</Form.Control.Feedback>
            </Form.Group> */}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row align-items-center">
        {viewMode === 'Customized View' && (
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Select Columns
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: '800px', overflowX: 'auto' }}>
              <Container>
                <Row>
                  {selectedColumns.map((col, index) => (
                    <Col key={col.id} xs={6} md={4} lg={3}>
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${col.id}`}
                        label={col.label}
                        checked={selectedColumnIds.includes(col.id)}
                        onChange={() => handleColumnSelection(col.id)}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>


  <TableContainer component={Paper} >
      <Typography variant="h6" gutterBottom sx={{ padding: 2 }}>
        Customer Invoices
      </Typography>
      <Table id="table-to-xls">
  <TableHead>
    <TableRow>
      <TableCell><strong>Serial No</strong></TableCell>
      <TableCell><strong>Name</strong></TableCell>
      <TableCell><strong>Flat No</strong></TableCell>
      <TableCell><strong>Invoice</strong></TableCell>
      <TableCell><strong>Invoice Amount</strong></TableCell>
      <TableCell><strong>Outstanding Days</strong></TableCell>
      <TableCell><strong>30 Days</strong></TableCell>
      <TableCell><strong>30-60 Days</strong></TableCell>
      <TableCell><strong>60-90 Days</strong></TableCell>
      <TableCell><strong> >90 Days</strong></TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  {paginatedData.map((row, index) => {
    const agingData = [
      { invoice: row.invoice1, installment: row.balance1, daysOut: row.days_outstanding_1 },
      { invoice: row.invoice2, installment: row.balance2, daysOut: row.days_outstanding_2 },
      { invoice: row.invoice3, installment: row.balance3, daysOut: row.days_outstanding_3 },
      { invoice: row.invoice4, installment: row.balance4, daysOut: row.days_outstanding_4 },
      { invoice: row.invoice5, installment: row.balance5, daysOut: row.days_outstanding_5 },
      { invoice: row.invoice6, installment: row.balance6, daysOut: row.days_outstanding_6 },
      { invoice: row.invoice7, installment: row.balance7, daysOut: row.days_outstanding_7 },
      { invoice: row.invoice8, installment: row.balance8, daysOut: row.days_outstanding_8 },
      { invoice: row.invoice9, installment: row.balance9, daysOut: row.days_outstanding_9 },
      { invoice: row.invoice10, installment: row.balance10, daysOut: row.days_outstanding_10 }
    ];

    // Logic to determine the aging categories
    const agingTotals = {
      "30Days": 0,
      "30_60Days": 0,
      "60_90Days": 0,
      ">90Days": 0
    };

    // Iterate through each invoice/ installment pair to classify the days into categories
    agingData.forEach((item) => {
      if (item.invoice && item.installment !== "0") {
        const daysOut = item.daysOut;
        const amount = parseFloat(item.installment); // Assuming installment is the amount

        if (daysOut <= 30) {
          agingTotals["30Days"] += amount;
        } else if (daysOut > 30 && daysOut <= 60) {
          agingTotals["30_60Days"] += amount;
        } else if (daysOut > 60 && daysOut <= 90) {
          agingTotals["60_90Days"] += amount;
        } else if (daysOut > 90) {
          agingTotals[">90Days"] += amount;
        }
      }
    });

    return (
      <TableRow key={index}>
        <TableCell>{index + 1}</TableCell> {/* Serial No */}
        <TableCell>{row.customerName}</TableCell>
        <TableCell>{row.flatNo}</TableCell>

        {/* Conditionally render invoices and outstanding days */}
        {row.invoice1 && row.installment1 !== "0" ? (
          <>
            <TableCell>{row.invoice1}</TableCell>
            <TableCell>{parseFloat(row.installment1).toFixed(0)}</TableCell>
            <TableCell>{row.days_outstanding_1}</TableCell>
          </>
        ) : null}

        {/* Add the aging data columns */}
        <TableCell>{agingTotals["30Days"] > 0 ? agingTotals["30Days"].toFixed(0) : "-"}</TableCell>
        <TableCell>{agingTotals["30_60Days"] > 0 ? agingTotals["30_60Days"].toFixed(0) : "-"}</TableCell>
        <TableCell>{agingTotals["60_90Days"] > 0 ? agingTotals["60_90Days"].toFixed(0) : "-"}</TableCell>
        <TableCell>{agingTotals[">90Days"] > 0 ? agingTotals[">90Days"].toFixed(0) : "-"}</TableCell>
      </TableRow>
    );
  })}
</TableBody>

</Table>

    </TableContainer>


    <TablePagination
        rowsPerPageOptions={[5, 10, 15]} // Customize the rows per page options
        component="div"
        count={invoiceData.length} // Total number of rows
        rowsPerPage={rowsPerPage} // Number of rows per page
        page={page} // Current page
        onPageChange={handleChangePage} // Page change handler
        onRowsPerPageChange={handleChangeRowsPerPage} // Rows per page change handler
      />

      <div className="d-flex flex-row align-items-center">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Excel"
          style={{ display: 'none' }} // Hide default button
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginLeft: '2rem', height: '50px' }}
          onClick={downloadInvoicePDF}
        >
          PDF
        </Button>
      </div>
    </>
  );
};

export default InvoiceReport;
