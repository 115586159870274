import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LeadManagementPopup from "../ManagerDashboard/LeadManagementPopup";
import { Col, Form } from 'react-bootstrap';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableBody, Typography, TablePagination } from "@mui/material";

const AgentReport = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  
  const [dataCp, setDataCp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    axios.get('https://buildfastapi.kollagroup.in/api/v1/get-channel-partners')
      .then((response) => {
        setDataCp(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Failed to fetch data');
        setLoading(false);
      });
  }, []);

  const handleRowClick = (channelId) => {
    setSelectedChannelId(channelId);
    axios.get(`https://buildfastapi.kollagroup.in/api/v1/leads-cp?channelId=${channelId}`)
      .then((response) => {
        setPopupData(response.data.data);
        setPopupOpen(true);
      })
      .catch((error) => {
        setError('Failed to fetch leads');
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataCp.length - page * rowsPerPage);

  if (loading) return <Typography variant="h6">Loading...</Typography>;
  if (error) return <Typography variant="h6" color="error">{error}</Typography>;

  return (
    <>
      <Typography variant="h6" style={{ marginTop: 30, color: 'blue' }}>Channel Partner</Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>S.No</TableCell>
              <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Name</TableCell>
              <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Mobile</TableCell>
              <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Project Name</TableCell>
              <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Channel ID</TableCell>
              <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>RERANO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? dataCp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : dataCp
            ).map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.mobile}</TableCell>
                <TableCell>{row.projectName}</TableCell>
                <TableCell onClick={() => handleRowClick(row.channelId)}>{row.channelId}</TableCell>
                <TableCell>{row.rerano}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={dataCp.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {popupOpen && popupData && (
        <>
        <Typography variant="h6" style={{ marginTop: 30, color: 'blue' }}>Channel Partner Lead Data</Typography>
    <TableContainer component={Paper} style={{marginTop:'20px'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Project</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Sales Executive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {popupData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.mobile}</TableCell>
                  <TableCell>{row.ProjectName}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell>{row.salesExecutive}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </>
    )}
    </>
  );
};

export default AgentReport;
