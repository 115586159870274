import React, { useState } from 'react';
import axios from 'axios';

function ReplyBox({ ticketNumber, onCancelReply }) {
  const [replyContent, setReplyContent] = useState("");

  const handleReplySubmit = () => {
    axios
      .post(`https://buildfastapi.kollagroup.in/internal/api/v1/api/tickets/reply/${ticketNumber}`, { replyContent })
      .then((response) => {
        console.log("Reply submitted:", response.data);
        onCancelReply(); // Close the reply box after submission
      })
      .catch((error) => console.error("Error submitting reply:", error));
  };

  return (
    <div style={replyBoxStyle}>
      <h3>Reply to Ticket: {ticketNumber}</h3>
      <textarea
        value={replyContent}
        onChange={(e) => setReplyContent(e.target.value)}
        style={textAreaStyle}
      />
      <button onClick={handleReplySubmit} style={buttonStyle}>Submit Reply</button>
      <button onClick={onCancelReply} style={buttonStyle}>Cancel</button>
    </div>
  );
}

const replyBoxStyle = {
  margin: '20px 0',
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  backgroundColor: '#f9f9f9',
};

const textAreaStyle = {
  width: '100%',
  height: '100px',
  margin: '10px 0',
  padding: '10px',
  borderRadius: '4px',
  border: '1px solid #ccc',
};

const buttonStyle = {
  backgroundColor: "#4CAF50", // Green background
  border: "none", // No border
  color: "white", // White text
  padding: "15px 32px", // Padding
  textAlign: "center", // Centered text
  textDecoration: "none", // No underline
  display: "inline-block", // Display inline-block
  fontSize: "16px", // Font size
  margin: "4px 2px", // Margin
  cursor: "pointer", // Pointer cursor on hover
  borderRadius: "12px", // Rounded corners
};

export default ReplyBox;
