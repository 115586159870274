import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  FaCheck,
  FaEdit,
  FaEye,
  FaMobileAlt,
  FaPhone,
  FaTrash,
  FaUserCircle,
  FaWindowClose,
} from "react-icons/fa";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TableBody,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Pagination from "@mui/material/Pagination";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import FilterListIcon from "@mui/icons-material/FilterList";
import MailBody from '../MailBody/MailBody'
import Message from '../Message/Message';
import ReplyBox from "../ReplyBox/ReplyBox";

console.log(sessionStorage.getItem("project_name"));

// const PrName = sessionStorage.getItem("ProjectName");
const AdminLogin=sessionStorage.getItem('superAdmin')
// console.log(PrName);

const AdminCommunication = () => {
  const [tickets, setTickets] = useState([]);


  const [selectedTicket, setSelectedTicket] = useState(null); // State for the selected ticket for reply

  React.useEffect(() => {
    axios
      .get("https://buildfastapi.kollagroup.in/internal/api/v1/api/tickets")
      .then((response) => {
        const crmTickets = response.data.filter(
          (ticket) => ticket.receiver === "Admin"
        );
        setTickets(crmTickets);
      })
      .catch((error) => console.error("Error fetching tickets:", error));
  }, []);

  const handleReply = (ticketNumber) => {
    setSelectedTicket(ticketNumber); // Set the selected ticket for reply
  };

  const handleCancelReply = () => {
    setSelectedTicket(null); // Clear the selected ticket for reply
  };


  // "https://buildfastapi.kollagroup.in/internal/api/v1/"

  React.useEffect(() => {
    axios
      .get("https://buildfastapi.kollagroup.in/internal/api/v1/api/tickets")   
      .then((response) => {
        const crmTickets = response.data.filter(
          (ticket) => ticket.receiver === "Admin"
        );
        setTickets(crmTickets);
      })
      .catch((error) => console.error("Error fetching tickets:", error));
  }, []);



  const handleCancel = (ticketNumber) => {
    console.log("Cancel ticket:", ticketNumber);
  };

  const handleSubmit = (ticketNumber) => {
    console.log("Submit ticket:", ticketNumber);
    
  };



  const handleTicketClosed = (ticketNumber) => {
    setTickets(tickets.filter(ticket => ticket.ticket_number !== ticketNumber));
  };

  return (
    <>
      <div>
        <h1 style={{color:'blue',textAlign:'center',marginBottom:'25px'}}>Internal Communication</h1>
        <MailBody />
        <h2 style={{color:'black'}}>Tickets</h2>
        <ul>
          {tickets.map((ticket) => (
            <Message
              key={ticket.id}
              ticket={ticket}
              onReply={handleReply}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              onTicketClosed={handleTicketClosed}
            />
          ))}
        </ul>
        {selectedTicket && (
        <ReplyBox 
          ticketNumber={selectedTicket} 
          onCancelReply={handleCancelReply} 
        />
      )}
      </div>
    </>
  );
};

export default AdminCommunication;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import MailBody from "../MailBody/MailBody";
// import Message from "../Message/Message";
// import Modal from "@mui/material/Modal";
// import { Button, TextField } from "@mui/material";

// const Admin = () => {
//   const [tickets, setTickets] = useState([]);
//   const [showReplyModal, setShowReplyModal] = useState(false);
//   const [replyMessage, setReplyMessage] = useState("");
//   const [replyTicketNumber, setReplyTicketNumber] = useState(null);

//   const fetchTickets = () => {
//     axios
//       .get("http://localhost:3003/api/tickets")
//       .then((response) => {
//         const adminTickets = response.data.filter(
//           (ticket) => ticket.receiver === "Admin"
//         );
//         setTickets(adminTickets);
//       })
//       .catch((error) => console.error("Error fetching tickets:", error));
//   };

//   useEffect(() => {
//     fetchTickets();
//   }, []);

//   const handleReply = (ticketNumber) => {
//     setShowReplyModal(true);
//     setReplyTicketNumber(ticketNumber);
//   };

//   const handleReplySubmit = () => {
//     axios
//       .post(`http://localhost:3003/api/tickets/reply/${replyTicketNumber}`, { replyContent: replyMessage })
//       .then((response) => {
//         console.log("Reply submitted:", response.data);
//         setShowReplyModal(false);
//         setReplyMessage("");
//         setReplyTicketNumber(null);
//         fetchTickets(); // Fetch tickets again to update the list with the reply
//       })
//       .catch((error) => console.error("Error submitting reply:", error));
//   };

//   const handleCancel = (ticketNumber) => {
//     console.log("Cancel ticket:", ticketNumber);
//   };

//   const handleSubmit = (ticketNumber) => {
//     console.log("Submit ticket:", ticketNumber);
//   };

//   const handleTicketClosed = (ticketNumber) => {
//     setTickets(tickets.filter(ticket => ticket.ticket_number !== ticketNumber));
//   };

//   return (
//     <>
//       <div>
//         <h1 style={{ color: "blue", textAlign: "center", marginBottom: "25px" }}>
//           Admin Internal Communication
//         </h1>
//         <MailBody />
//         <h2 style={{ color: "black" }}>Tickets</h2>
//         <ul>
//           {tickets.map((ticket) => (
//             <Message
//               key={ticket.id}
//               ticket={ticket}
//               onReply={handleReply}
//               onCancel={handleCancel}
//               onSubmit={handleSubmit}
//               onTicketClosed={handleTicketClosed}
//             />
//           ))}
//         </ul>

//         <Modal open={showReplyModal} onClose={() => setShowReplyModal(false)}>
//           <div
//             style={{
//               padding: 20,
//               backgroundColor: "white",
//               margin: "auto",
//               width: "50%",
//               top: "50%",
//               transform: "translateY(-50%)",
//               position: "absolute",
//             }}
//           >
//             <h2>Reply to Ticket</h2>
//             <TextField
//               fullWidth
//               label="Reply"
//               value={replyMessage}
//               onChange={(e) => setReplyMessage(e.target.value)}
//               multiline
//               rows={4}
//               variant="outlined"
//               style={{ marginBottom: 20 }}
//             />
//             <Button variant="contained" color="primary" onClick={handleReplySubmit}>
//               Submit Reply
//             </Button>
//           </div>
//         </Modal>
//       </div>
//     </>
//   );
// };

// export default Admin;
