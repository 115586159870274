import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, TableBody, Typography, colors } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import MuiAlert from "@material-ui/lab/Alert";
import { FaCheck, FaEdit, FaEye, FaMobileAlt, FaPhone, FaTrash, FaUserCircle, FaWindowClose } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Pagination from '@mui/material/Pagination';
import Swal from 'sweetalert2';


function LeadTransction() {
    const [leadData, setLeadData] = useState([]);


    const [pname, setPName] = useState('')
    const [Pemail, setPEmail] = useState('')
    const [pmobile, setPMobile] = useState('')
    const [alternatemob, setAlternateMob] = useState('')
    const [aadharnumber, setAadharNumber] = useState('')
    const [pannumber, setPanNumber] = useState('')
    const [address, setAddress] = useState('')
    const [currentaddress, setCurrentAddress] = useState('')
    const [tower, setTower] = useState('')
    const [floor, setFloor] = useState('')
    const [flatno, setFlatNo] = useState('')
    const [facing, setFacing] = useState('')
    const [corner, setCorner] = useState('')
    const [amountpaid, setAmountPaid] = useState('')
    const [financeType, setFinanceType] = useState('')


    const [id, setId] = useState('')
    const [isVeiw, setIsVeiw] = useState()
    const [response, setResponse] = useState()



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };
    const [open, setOpen] = useState(false)


    const [Validated, setValidated] = useState(false);

    const [open1, setOpen1] = useState(false)

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_basic()
        }

        setValidated(true);
    };

    const [Validatedb, setValidatedb] = useState(false);

    const formValidationb = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            blocktabsubmit()
        }

        setValidatedb(true);
    };

    const [Validatedt, setValidatedt] = useState(false);

    const formValidationt = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            towertabsubmit()
        }

        setValidatedt(true);
    };

    const [Validatedf, setValidatedf] = useState(false);

    const formValidationf = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_floortsubmit()
        }

        setValidatedf(true);
    };


    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [subValue, setSubValue] = React.useState('1');
    const handleSubChange = (event, newSubValue) => {
        setSubValue(newSubValue);
    };

    const [data, setData] = useState([])

    const getlm = () => {
        axios.get('https://buildfastapi.kollagroup.in/api/v1/lead-data').then((res) => {

            setData(res.data.data)
            console.log(res.data.data);

        })
    }

    React.useEffect(() => {
        getlm()
    }, [])


    const [page, setPage] = useState(5)
    const [cpage, setCpage] = useState(1)

    const pagehandle = (e, val) => {
        setCpage(val);
    }

    const indexOflpage = cpage * page;
    const indexOffpage = indexOflpage - page;
    const currentpost = data.slice(indexOffpage, indexOflpage)

    const intrstTab = (id) => {
        console.log(id);
          axios.post('https://buildfastapi.kollagroup.in/api/v1/intrst-tab',{id}).then((res => {
           if(res.data.statusCode === 200){
            getlm()
            Swal.fire({
                icon: 'success',
                title: "Successfull !",
                text: "Selected customer is intrested!",
                confirmButtonColor: "rgb(126, 110, 228)",
                cancelButtonColor: "#d33",
                showCancelButton: false,
                confirmButtonText: "Ok"
            }).then((result) => {
                if (result.value) {
                    return
                }
            })
           }
          }))
    }

    const notintrstTab = (id) => {
        axios.post('https://buildfastapi.kollagroup.in/api/v1/notintrst-tab',{id}).then((res => {
            if(res.data.statusCode === 200){
                getlm()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Selected customer is Not-intrested!",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
               }
            }
        ))
  }

  const callbackTab = (id) => {
    axios.post('https://buildfastapi.kollagroup.in/api/v1/callback-tab',{id}).then((res => {
            if(res.data.statusCode === 200){
                getlm()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Selected customer said to callBack!",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
          }))
}

const convrtintr = (id) => {
    axios.post('https://buildfastapi.kollagroup.in/api/v1/cnvrttinstrest',{id}).then((res => {
            if(res.data.statusCode === 200){
                getlm()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Selected customer converted to intrest!",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
          }))
}

    return (
        <div>

            <div className='leadManagementTable'>
                <Paper elevation={0} >

                    <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                        <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                        <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >

                        </div>
                        <div>

                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', color: 'rgb(86, 63, 146)' }} >
                                        <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary" >
                                            <Tab label="Add Lead" value="1" style={{ color: 'rgb(86, 63, 146)' }} />
                                            <Tab label="Intrested" value="2" style={{ color: 'rgb(86, 63, 146)' }} />
                                            <Tab label=" Call Back" value="3" style={{ color: 'rgb(86, 63, 146)' }} />
                                            <Tab label="Not Intrested" value="4" style={{ color: 'rgb(86, 63, 146)' }} />

                                        </TabList>
                                    </Box>
                                    <TabPanel value="1" >
                                        <div style={{ width: "58rem" }} >


                                            <Table style={{ position: 'relative', top: '1.3rem' }}>
                                                <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                                    <TableRow>

                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Name
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Email
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Mobile
                                                        </TableCell>

                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 57, fontWeight: '600' }}
                                                        >
                                                            Gender
                                                        </TableCell>
                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 57, fontWeight: '600' }}
                                                        >
                                                            Timings
                                                        </TableCell>

                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 21, fontWeight: '600', width: 21 }}
                                                        >
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        currentpost 
                                                            .map((item => (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.name}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.email}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.mobile}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.gender}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12, width: 100 }}>{item.pretime}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                                        <div style={{ display: 'flex' }} >
                                                                            <div>
                                                                                <IconButton
                                                                                    id="fade-button"
                                                                                    aria-controls={open2 ? 'fade-menu' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded={open2 ? 'true' : undefined}
                                                                                    onClick={(e) => {
                                                                                        handleClick(e.currentTarget)
                                                                                        setId(item.Id)
                                                                                    }}
                                                                                >
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <Menu
                                                                                    id="fade-menu"
                                                                                    MenuListProps={{
                                                                                        'aria-labelledby': 'fade-button',
                                                                                    }}
                                                                                    anchorEl={anchorEl}
                                                                                    open={open2}
                                                                                    onClose={handleClose2}
                                                                                    TransitionComponent={Fade}
                                                                                >
                                                                                    {
                                                                                        (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                                intrstTab(id)
 
                                                                                                
                                                                                            }}> Intrested</MenuItem>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                                callbackTab(id)
                                                                                                
                                                                                            }}>Call Back</MenuItem>
                                                                                        )

                                                                                    }


                                                                                    {
                                                                                        (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (

                                                                                            <MenuItem onClick={() => notintrstTab(id)} style={{ color: 'gray' }} > Not Intrested</MenuItem>
                                                                                        )
                                                                                    }

                                                                                </Menu>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )))
                                                    }

                                                </TableBody>
                                            </Table>

                                            <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div>

                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2" >
                                        <div style={{ width: "58rem" }} >


                                            <Table style={{ position: 'relative', top: '1.3rem' }}>
                                                <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                                    <TableRow>

                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Name
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Email
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Mobile
                                                        </TableCell>

                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 57, fontWeight: '600' }}
                                                        >
                                                            Gender
                                                        </TableCell>
                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 57, fontWeight: '600' }}
                                                        >
                                                            Timings
                                                        </TableCell>

                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 21, fontWeight: '600', width: 21 }}
                                                        >
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                         data
                                                         .filter((item => item.intrestTab === 1))
                                                            .map((item => (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.name}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.email}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.mobile}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.gender}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12, width: 100 }}>{item.pretime}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

<div style={{ display: 'flex' }} >
                                                                            <div>
                                                                                <IconButton
                                                                                    id="fade-button"
                                                                                    aria-controls={open2 ? 'fade-menu' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded={open2 ? 'true' : undefined}
                                                                                    onClick={(e) => {
                                                                                        handleClick(e.currentTarget)
                                                                                        setId(item.Id)
                                                                                    }}
                                                                                >
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <Menu
                                                                                    id="fade-menu"
                                                                                    MenuListProps={{
                                                                                        'aria-labelledby': 'fade-button',
                                                                                    }}
                                                                                    anchorEl={anchorEl}
                                                                                    open={open2}
                                                                                    onClose={handleClose2}
                                                                                    TransitionComponent={Fade}
                                                                                >
                                                                                    {
                                                                                        (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={() => {

                                                                                                // handleClose2()
                                                                                                // viewData(id)
                                                                                                // setOpen1(true)
                                                                                            }}> Intrested</MenuItem>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                                // viewData(id)
                                                                                                // handleClose2()
                                                                                                // setIsVeiw(true)
                                                                                            }}>Call Back</MenuItem>
                                                                                        )

                                                                                    }


                                                                                    {
                                                                                        (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (

                                                                                            <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} > Not Intrested</MenuItem>
                                                                                        )
                                                                                    }

                                                                                </Menu>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )))
                                                    }

                                                </TableBody>
                                            </Table>

                                            <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div>

                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3" >
                                        <div style={{ width: "58rem" }} >


                                            <Table style={{ position: 'relative', top: '1.3rem' }}>
                                                <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                                    <TableRow>

                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Name
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Email
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Mobile
                                                        </TableCell>

                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 57, fontWeight: '600' }}
                                                        >
                                                            Gender
                                                        </TableCell>
                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 57, fontWeight: '600' }}
                                                        >
                                                            Timings
                                                        </TableCell>

                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 21, fontWeight: '600', width: 21 }}
                                                        >
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        data
                                                        .filter((item => item.callBackTab === 1))
                                                            .map((item => (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.name}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.email}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.mobile}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.gender}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12, width: 100 }}>{item.pretime}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                                        <div style={{ display: 'flex' }} >
                                                                            <div>
                                                                                <IconButton
                                                                                    id="fade-button"
                                                                                    aria-controls={open2 ? 'fade-menu' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded={open2 ? 'true' : undefined}
                                                                                    onClick={(e) => {
                                                                                        handleClick(e.currentTarget)
                                                                                        setId(item.Id)
                                                                                    }}
                                                                                >
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <Menu
                                                                                    id="fade-menu"
                                                                                    MenuListProps={{
                                                                                        'aria-labelledby': 'fade-button',
                                                                                    }}
                                                                                    anchorEl={anchorEl}
                                                                                    open={open2}
                                                                                    onClose={handleClose2}
                                                                                    TransitionComponent={Fade}
                                                                                >
                                                                                    {
                                                                                        (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={() => {

                                                                                                // handleClose2()
                                                                                                // viewData(id)
                                                                                                // setOpen1(true)
                                                                                            }}> CallBack Reminder</MenuItem>
                                                                                        )
                                                                                    }
                                                                                    
                                                                                </Menu>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )))
                                                    }

                                                </TableBody>
                                            </Table>

                                            <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div>

                                        </div>
                                    </TabPanel>
                                    <TabPanel value="4" >
                                        <div style={{ width: "58rem" }} >


                                            <Table style={{ position: 'relative', top: '1.3rem' }}>
                                                <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                                    <TableRow>

                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Name
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Email
                                                        </TableCell>
                                                        <TableCell
                                                            align={"start"}
                                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                                        >
                                                            Mobile
                                                        </TableCell>

                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 57, fontWeight: '600' }}
                                                        >
                                                            Gender
                                                        </TableCell>
                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 57, fontWeight: '600' }}
                                                        >
                                                            Timings
                                                        </TableCell>

                                                        <TableCell

                                                            align={"left"}
                                                            style={{ top: 21, fontWeight: '600', width: 21 }}
                                                        >
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                         data
                                                         .filter((item => item.notIntrstTab === 1))
                                                            .map((item => (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.name}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.email}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.mobile}
                                                                    </TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12 }}>{item.gender}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12, width: 100 }}>{item.pretime}</TableCell>
                                                                    <TableCell align={"start"}
                                                                        style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

<div style={{ display: 'flex' }} >
                                                                            <div>
                                                                                <IconButton
                                                                                    id="fade-button"
                                                                                    aria-controls={open2 ? 'fade-menu' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded={open2 ? 'true' : undefined}
                                                                                    onClick={(e) => {
                                                                                        handleClick(e.currentTarget)
                                                                                        setId(item.Id)
                                                                                    }}
                                                                                >
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <Menu
                                                                                    id="fade-menu"
                                                                                    MenuListProps={{
                                                                                        'aria-labelledby': 'fade-button',
                                                                                    }}
                                                                                    anchorEl={anchorEl}
                                                                                    open={open2}
                                                                                    onClose={handleClose2}
                                                                                    TransitionComponent={Fade}
                                                                                >
                                                                                   
                                                                                    {
                                                                                        (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                                convrtintr(id)
                                                                                               
                                                                                            }}>Convert To Intrest</MenuItem>
                                                                                        )

                                                                                    }


                                                                                  

                                                                                </Menu>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )))
                                                    }

                                                </TableBody>
                                            </Table>

                                            <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div>

                                        </div>
                                    </TabPanel>

                                </TabContext>
                            </Box>

                        </div>

                    </TableContainer>
                </Paper>

            </div>


        </div>
    )
}

export default LeadTransction

