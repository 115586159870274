import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, TableBody, Typography , Menu, MenuItem} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2';
import { FaDotCircle } from 'react-icons/fa';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Floors from './Floors';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import { FaEdit, FaMapMarkerAlt, FaMarker, FaTrash, FaUser, FaUserAlt } from 'react-icons/fa';
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';



export default function Floors_Or_Unit() {


    const [Blocks, setBlocks] = useState("")
    const [ft, setFt] = useState("")
    const [ProjectName, setProjectName] = useState("")
    const [Floor, setFloor] = useState()
    const [selectType, setSelectType] = useState('')
    const [floorname, setFloorname] = useState([])
    const [id, setId] = useState()

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pnval = searchParams.get('id');
     
    const floorData = (a) => {
        console.log(a);
        setFloorname(a)
    }

    const viewfloor = () => {
        axios.post('https://buildfastapi.kollagroup.in/project/api/v1/view-floor',{
            id
        })
        .then((res => {
            console.log("cd",res.data.ProjectName);
            setOpen1(true)
            setProjectName(res.data.ProjectName)
            setBlocks(res.data.Blocks)
            setFloorname(res.data.selectType)
            setFt(res.data.Towers)
            setFloor(res.data.Floor_Unit)
        }))
    }

    const asg = () => {
        console.log("sasd");
    }


    const add_blocks = () => {
        // const floors = Array.prototype.map.call(floorname, function (item) { return item.attachment }).join(",");
        console.log({
            ProjectName, Blocks, Floor_Unit: Floor, Towers:ft, selectType:floorname 
        });
        axios.post("https://buildfastapi.kollagroup.in/project/api/v1/add-floor", {
            ProjectName, Blocks, Floor_Unit: Floor, Towers:ft, selectType:floorname 
        }).then((res) => {
            
                setOpen(false)
                get_floor()
                setBlocks("")
                setProjectName("")
                setSelectType('')
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            
        }).catch((error) => {
            console.error(error);
            setOpen(false);
    
            Swal.fire({
                icon: 'error',
                title: "Error!",
                text: "Floors already exist  in the selected Tower. please try again.",
                confirmButtonColor: "#d33",
                cancelButtonColor: "rgb(126, 110, 228)",
                showCancelButton: false,
                confirmButtonText: "Ok"
            });
        });
    }

    const [response, setResponse] = useState([])

    const get_floor = () => {
        axios.post("https://buildfastapi.kollagroup.in/project/api/v1/get-pwfloor",{text:pnval}).then((res) => {
            if (res.data.status === 200) {
                setResponse(res.data.data)
            }
        })
    }


    const [response1, setResponse1] = useState([])

    const get_basic = () => {
        axios.get("https://buildfastapi.kollagroup.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse1(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basic()
        get_floor()
       
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };

    

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_blocks()
        }

        setValidated(true);
    };

    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataDelete(id)
            }
        })
    }

    const basicDataDelete = (id) => {
        axios.post('https://buildfastapi.kollagroup.in/project/api/v1/delete-floor', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                get_floor()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Basic data is deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }


    const editAlert = () => {
        setOpen1(false)
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                blockEdit(id)
            }
        })
    }

    const blockEdit = (id) => {
       
        axios.post('https://buildfastapi.kollagroup.in/project/api/v1/floor-edit', {
            ProjectName, Blocks, Towers:ft, selectType:floorname ,id
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.status === 200) {
                    get_floor()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Basic data is updated successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                }
            }
        })
    }

    const searchCommon = (text) => {
        axios.post('https://buildfastapi.kollagroup.in/project/api/v1/search-floor', {
            text
        }).then((res) => {

            setResponse(res.data)

        })
    }
    const [open1, setOpen1] = useState(false)
    const [response2, setResponse2] = useState([])

    const get_block = (value) => {
        setProjectName('')
        setBlocks('')
        axios.post("https://buildfastapi.kollagroup.in/project/api/v1/projectwise-blocks", {
            value
        }).then((res) => {

            setResponse2(res.data)
            setProjectName(value)
           res.data.forEach(data => {
            console.log(data.Blocks);
           });
           console.log(res.data)

        })
    }

    const [responset, setResponset] = useState([])

    const get_tower = (value1) => {
        
        axios.post("https://buildfastapi.kollagroup.in/project/api/v1/projectwise-towers", {
            value1
        }).then((res) => {

            setResponset(res.data)
           console.log(res.data)
           console.log("asdsad",value1);
           setBlocks(value1)

        })
    }

    const [blog, setBlog] = useState('');
    
    const handlefloor = (e) => {
        const newBlocks = parseInt(e.target.value);
        if (!isNaN(newBlocks) && newBlocks !== '' && Number(newBlocks) >=0 && Number(newBlocks) <= 99)  {
          setBlog(newBlocks)
        }
       else{
        setBlog('')
       }
     };

    const renderInputs = () => {
        const input = parseInt(blog)
        return Array.from({length: input}, (_, index) => (
            <>
            <Typography style={{ color: 'gray', fontSize: 13 }}></Typography>
            <Form.Control
            key={index}
            required
            type="text"
            style={{ padding: '0.42rem', marginBottom: 14 }}
        />
        </>
        ))
    };


    const [page, setPage] = useState(5)
    const [cpage, setCpage] = useState(1)

    const pagehandle = (e, val) => {
        setCpage(val);
    }

    const indexOflpage = cpage * page;
    const indexOffpage = indexOflpage - page;
    const currentpost = response.slice(indexOffpage, indexOflpage)

    return (
        <div>


            <div className='leadManagementTable'>
            <ArrowBackIcon onClick={()=>history.back()}/>
                        <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Floors </Typography>
                <Paper elevation={0} >

                    <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >


                        <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                        <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                            <div>

                                <input onChange={(e) => searchCommon(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                            </div>
                            <div>
                                {/* <Button onClick={() => setOpen(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add Floor</Button> */}
                            </div>
                        </div>
                        <Table style={{ position: 'relative', top: '1.3rem' }}>
                            <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                <TableRow>
                                   
                                    {/* <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Project Name
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Blocks
                                    </TableCell>

                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Tower
                                    </TableCell> */}
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Floors
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Name of Floors
                                    </TableCell>

                                    <TableCell

                                        align={"left"}
                                        style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {response
                                    .map((row) => {

                                        if (row.lead === 'Not Customer') {
                                            color = 'red'
                                            widths = false
                                        }

                                        if (row.lead === 'Customer') {
                                            color = 'green'
                                            widths = true
                                        }

                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                
                                                {/* <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.ProjectName}</TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaDotCircle style={{ marginRight: 3, color: 'white', marginTop: '-0.05rem', fontSize: 9 }} />{row.Blocks} </button>
                                                </TableCell>

                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaDotCircle style={{ marginRight: 3, color: 'white', marginTop: '-0.05rem', fontSize: 9 }} />{row.Towers} </button>
                                                </TableCell> */}
                                                <TableCell align={"start"}
                                                    style={{ top: 57 }}>
                                                    <div style={{ border: `3px solid rgb(126, 110, 228)`, padding: '0.1rem', alignItems: 'center', justifyContent: 'center', display: 'flex', height: 35, width: 37, borderRadius: '50%' }}>
                                                        <FaDotCircle style={{ color: 'rgb(126, 110, 228)', fontSize: 24 }} />
                                                    </div>
                                                </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaDotCircle style={{ marginRight: 3, color: 'white', marginTop: '-0.05rem', fontSize: 9 }} />{row.selectType}</button>
                                                </TableCell>

                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                    <div style={{ display: 'flex' }} >
                                                        {/* <Button onClick={() => deleteAlert(row.id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.8rem' }} >Delete</Button> */}
                                                        
                                                        <div>
                                                            <IconButton
                                                                id="fade-button"
                                                                aria-controls={open2 ? 'fade-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open2 ? 'true' : undefined}
                                                                onClick={(e) => {
                                                                    handleClick(e.currentTarget)
                                                                    setId(row.id)
                                                                }}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                id="fade-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'fade-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open2}
                                                                onClose={handleClose2}
                                                                TransitionComponent={Fade}
                                                            >
                                                                {
                                                                      (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                        <MenuItem style={{ color: 'gray' }}  onClick={() =>{
                                                                            viewfloor(id)
                                                                            // setOpen1(true)
                                                                            // asg()
                                                                        } }><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>
                                                                      )
                                                                }
                                                                {
                                                                      (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (

                                                                          <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} ><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                                      )
                                                                }

                                                            

                                                            </Menu>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {
                            response.length === 0 && (
                                <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                    <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                    <Typography>0 customers</Typography>
                                </Box>
                            )
                        }
                    </TableContainer>
                </Paper>
                <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                    <Pagination count={Math.ceil(response.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                </div>
            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "28rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Add Floor</Typography>
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => get_block(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {
                                        ProjectName !== "" && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Block</Typography>
                                                <Form.Select
                                                    required
                                                    onChange={(e) => {get_tower(e.target.value)
                                                   }}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select Block Name</option>
                                                   

                                                    {
                                                        response2.map((data,index) => (
                                                            <React.Fragment key={index}>
                                                            {data.Blocks.split(',').map((block, blockIndex) => (
                                                                <option key={blockIndex}>{block.trim()}</option>
                                                            ))}
                                                            </React.Fragment>
                                                        ))
                                                        
                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )

                                       
                                    }

                                           {
                                            Blocks !== "" && (
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Towers</Typography>
                                                <Form.Select
                                                    required
                                                    onChange={(e) => setFt(e.target.value)}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select Tower Name</option>
                                                   
                                                      
                                                    {
                                                        responset.map((data,index) => (
                                                            <React.Fragment key={index}>
                                                            {data.Towers.split(',').map((block, blockIndex) => (
                                                                <option key={blockIndex}>{block.trim()}</option>
                                                            ))}
                                                            </React.Fragment>
                                                        ))
                                                        
                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            )
                                           }

                                    {
                                        ft !== "" && (

                                           
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>No Of Floors</Typography>
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        style={{ padding: '0.42rem', marginBottom: 14, }}
                                                    /> 
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Floors
                                                    </Form.Control.Feedback>
                                                   <Floors floorData={floorData}/>
                                                </Form.Group>
                                            
                                            // <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            //     {
                                            //         selectType === '' && (
                                            //             <FormControl>
                                            //                 <FormLabel id="demo-radio-buttons-group-label">Select Type</FormLabel>
                                            //                 <RadioGroup
                                            //                     aria-labelledby="demo-radio-buttons-group-label"
                                            //                     defaultValue="female"
                                            //                     name="radio-buttons-group"
                                            //                     className='d-flex'
                                            //                 >
                                            //                     <FormControlLabel onChange={(e) => setSelectType(e.target.value)} value="Floor" control={<Radio />} label="Floor" />
                                            //                     <FormControlLabel onChange={(e) => setSelectType(e.target.value)} value="Unit" control={<Radio />} label="Unit" />
                                            //                 </RadioGroup>
                                            //             </FormControl>
                                            //         )
                                            //     }
                                            //     <Typography style={{ color: 'gray', fontSize: 13 }}>{selectType}</Typography>
                                            //     {
                                            //         selectType !== '' && (
                                            //             <Form.Control
                                            //                 required
                                            //                 onChange={handlefloor}

                                            //                 type= {`${selectType === 'Unit' ? 'text' : 'number'}`}

                                            //                 style={{ padding: '0.42rem', marginBottom: 14, }}

                                            //             />
                                            //         )
                                            //     }            
                                            //     <Form.Control.Feedback type="invalid">
                                            //         Please Enter Valid Blocks
                                            //     </Form.Control.Feedback>
                                            //     { renderInputs()}
                                            // </Form.Group>
                                        )
                                    }
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setOpen(false)
                                            setBlocks('')
                                            setProjectName('')
                                            setSelectType('')
                                        }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            
            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open1}
                    onClose={() => setOpen1(!open1)}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "28rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Update Floor</Typography>
                                    {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Updating floor details will receive a privacy audit.</Typography> */}
                                </div>
                                <Row className="mb-3">

                                    {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name dsa{id}</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => get_block(e.target.value)}
                                            type="text"
                                            value={ProjectName}

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group> */}

                                    {/* {
                                        ProjectName !== "" && ( */}
                                            {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Block</Typography>
                                                <Form.Select
                                                    required
                                                    value={Blocks}
                                                    onChange={(e) => {get_tower(e.target.value)
                                                   }}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select Block Name</option>
                                                   

                                                    {
                                                        response2.map((data,index) => (
                                                            <React.Fragment key={index}>
                                                            {data.Blocks.split(',').map((block, blockIndex) => (
                                                                <option key={blockIndex}>{block.trim()}</option>
                                                            ))}
                                                            </React.Fragment>
                                                        ))
                                                        
                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group> */}
                                        {/* )

                                       
                                    } */}

                                           {/* {
                                            Blocks !== "" && ( */}
                                                {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Towers</Typography>
                                                <Form.Select
                                                    required
                                                    value={ft}
                                                    onChange={(e) => setFt(e.target.value)}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select Tower Name</option>
                                                   
                                                      
                                                    {
                                                        responset.map((data,index) => (
                                                            <React.Fragment key={index}>
                                                            {data.Towers.split(',').map((block, blockIndex) => (
                                                                <option key={blockIndex}>{block.trim()}</option>
                                                            ))}
                                                            </React.Fragment>
                                                        ))
                                                        
                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group> */}
                                            {/* )
                                           } */}

                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}> Floor Names</Typography>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        value={floorname}
                                                        onChange={(e) => setFloorname(e.target.value)}
                                                        style={{ padding: '0.42rem', marginBottom: 14, }}
                                                    /> 
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Floors
                                                    </Form.Control.Feedback>
                                                   {/* <Floors floorData={floorData}/> */}
                                                </Form.Group>
                                            

                                  
                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button onClick={() => editAlert(id)}  style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setOpen1(false)
                                            
                                        }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            
        </div>
    );
}