
import React,{useEffect, useState} from 'react'
import { BsFillArchiveFill } from 'react-icons/bs'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';

import { Box, Button, Menu, MenuItem, TableBody, TablePagination, Typography, useScrollTrigger } from '@mui/material';
import { Navbar, Container, Nav, NavDropdown,Dropdown } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { FaEdit, FaMapMarkerAlt, FaMarker, FaUserCircle, FaTrash, FaCheck, FaEye, FaPhone } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import axios from 'axios';
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
} from 'react-share';
import LeadManagementPopup from './LeadManagementPopup';


function AdminDashboard() {

  const [id, setId] = React.useState();
  // const [date, setDate] = React.useState()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
      setAnchorEl(event);
  };
  const handleClose2 = () => {
      setAnchorEl(null);
  };


  const [projectName, setProjectName] = useState("");
  const [ttowerno, setTowardsTowerNumber] = useState("");
  
  const [responset, setResponset] = useState([]);
  
  const get_floor = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/project/api/v1/towers-getall")
      .then((res) => {
        if (res.data.status === 200) {
          setResponset(res.data.data);
          console.log(res.data.data);
        }
      });
  };
  
  React.useEffect(() => {
  
    get_floor();
  
  }, []);


  const [response4, setResponse4] = React.useState([]);

  console.log(response4)

  // https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor

  const get_floors = (ttowerno) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/project/api/v1/blockwise-floor",
        {
          value1: ttowerno,
        }
      )
      .then((res) => {
        setResponse4(res.data);
        // setTower(value1);
        //select_floor();
      });
  };




  const text = sessionStorage.getItem('user_name')



  const PrName = sessionStorage.getItem("ProjectName");
  console.log(PrName)

  const text1 = sessionStorage.getItem("emailExcutive");
  const PrName1 = sessionStorage.getItem("ProjectName");

  console.log(text)
  const [data, setData] = React.useState([])

  const[tcont, setTcount] = React.useState('')
  localStorage.setItem('managerCount',tcont.totalRecords)
  const[closedCount,setClosedCount]=React.useState('')

  React.useEffect(() => {
      // POST request with text and PrName
      axios.post('https://buildfastapi.kollagroup.in/api/v1/managerleadname', {
        projectName: PrName,
      })
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.error(err);
      });
    
      // GET request with text and PrName
      axios.get(`https://buildfastapi.kollagroup.in/api/v1/managerleadcount?projectName=${PrName}`)
      .then(res => {
        console.log(res.data);
        setTcount(res.data);
      })
      .catch(err => {
        console.error(err);
      });
    }, []);

    React.useEffect(() => {
      // POST request with text and PrName
      axios.post('https://buildfastapi.kollagroup.in/api/v1/closedmanagerleadname', {

        projectName: PrName
      })
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.error(err);
      });
    
      // GET request with text and PrName
      axios.get(`https://buildfastapi.kollagroup.in/api/v1/managerleadcountclosed?projectName=${PrName1}`)
      .then(res => {
        console.log(res.data);
        setClosedCount(res.data);
      })
      .catch(err => {
        console.error(err);
      });
    }, []);




    const [popupOpen, setPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const [selectedChannelId, setSelectedChannelId] = useState(null);
  

  
    const handleRowClick = (channelId) => {
      setSelectedChannelId(channelId);
      axios.get(`https://buildfastapi.kollagroup.in/api/v1/leads-cp?channelId=${channelId}`)
        .then((response) => {
          setPopupData(response.data.data);
          setPopupOpen(true);
        })
        .catch((error) => {
          setError('Failed to fetch leads');
        });
    };
  
    const [selectedFloor, setSelectedFloor] = useState('');

    const handleFloorChange =async (e) => {
      const floor = e.target.value;
      setSelectedFloor(floor);

    }


    // https://buildfastapi.kollagroup.in/api/v1/api/project-details?ProjectName=${PrName}&TowerNumber=${ttowerno}&floor=${selectedFloor}
    // http://localhost:3002/api/project-details?ProjectName=${PrName}&TowerNumber=${ttowerno}&floor=${selectedFloor}

const [fetchedData, setFetchedData] = useState([]); // Initialize with an empty array

const fetchData = async () => {
try {
  const response = await fetch(`https://buildfastapi.kollagroup.in/api/v1/api/project-details?ProjectName=${PrName}&TowerNumber=${ttowerno}&floor=${selectedFloor}`);
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  const data = await response.json();
  setFetchedData(data); // Update state with fetched data
} catch (error) {
  console.error('Error fetching data:', error);
  // Handle error (e.g., show error message)
}
};

useEffect(() => {
get_floors(ttowerno)
if (ttowerno && PrName && selectedFloor) {
  fetchData();
}
}, [ttowerno, PrName,selectedFloor]); // Dependencies for useEffect

useEffect(() => {
console.log(fetchedData); // Log fetchedData whenever it changes
}, [fetchedData]); // Run this effect whenever fetchedData changes



const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(10);



const startIndex = page * rowsPerPage;
const endIndex = startIndex + rowsPerPage;



const handleChangePage = (event, newPage) => {
setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
setRowsPerPage(parseInt(event.target.value, 10));
setPage(0);
};


const [open, setOpen] = useState(false);
const [selectedUnit, setSelectedUnit] = useState(null);

const handleOpen = (unit) => {
  setSelectedUnit(unit);
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};


const [filterInput, setFilterInput] = useState('');
const [filteredData, setFilteredData] = useState(fetchedData);

React.useEffect(() => {
  setFilteredData(fetchedData);
  updateAvailableUnitsCount(ttowerno);
}, [fetchedData]);


const handleFilterInputChange = (event) => {
  const value = event.target.value.toLowerCase();
  setFilterInput(value);
  const filtered = fetchedData.filter(
    (item) =>
      item.Facing.toLowerCase().includes(value) ||
      item.unitsizeNum.toLowerCase().includes(value) ||
      item.unit_name.toLowerCase().includes(value)
  );
  setFilteredData(filtered);
  setPage(0); // Reset page when applying filters
};


const [availableUnitsCount, setAvailableUnitsCount] = useState(0);


const updateAvailableUnitsCount = (tower) => {
  const filteredUnits = fetchedData.filter(item => item.tower === tower && item.status === 'Available');
  setAvailableUnitsCount(filteredUnits.length);
};



const [dataCp, setDataCp] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

useEffect(() => {
  // Fetch data from the API
  axios.get('https://buildfastapi.kollagroup.in/api/v1/get-channel-partners')
    .then((response) => {
      setDataCp(response.data.data);
      setLoading(false);
    })
    .catch((error) => {
      setError('Failed to fetch data');
      setLoading(false);
    });
}, []);

if (loading) return <Typography variant="h6">Loading...</Typography>;
if (error) return <Typography variant="h6" color="error">{error}</Typography>;

 

  return (
      <>
      <sidebar>
          
      </sidebar>
      <main>
          

          <div className='main-cards'>
              <div className='card-box d-flex flex-column justify-content-center' style={{backgroundColor:'rgb(168, 158, 230)',color: "white",height:'135px' }}>
                  <div className='card-inner ' >
                      <h3>No.Of Leads</h3>
                      <BsFillArchiveFill className="card-icon" />
                      <h6>{tcont.totalRecords}</h6>
                  </div>
              </div>
              <div className='card-box d-flex flex-column justify-content-center' style={{backgroundColor:'rgb(141, 242, 151)',color: "white" }}>
                  <div className='card-inner text-center'>
                      <h3>No.Of Leads Closed </h3>
                      <BsFillArchiveFill className="card-icon" />
                      <h6>{closedCount.totalRecords}</h6>

                  </div>
              </div>
              <div className='card-box d-flex flex-column justify-content-center' style={{backgroundColor:'rgb(250, 170, 178)',color: "white" }}>
                  <div className='card-inner text-center'>
                      <h3>No.Units Available </h3>
                      <BsFillArchiveFill className="card-icon" />
                      <h6>{availableUnitsCount}</h6>

                  </div>
                  </div>
          </div>

     
        {/* <Typography style={{ marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> */}
      <div className='d-flex flex-row align-items-center p-2'>
      <div>
        <input
          value={filterInput}
          onChange={handleFilterInputChange}
          style={{
            height: '2.3rem',
            color: 'gray',
            borderRadius: '0.3rem',
            outline: 'none',
            width: '15rem',
            border: '0.7px solid lightgray',
          }}
          placeholder="Search...."
        />
      </div>
        <Form.Group as={Col} md="3" controlId="validationCustom01" className='mr-2 p-2'>
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      ProjectName
                    </Typography>
                    <Form.Select
                      required
                      onChange={(e) => setProjectName(e.target.value)}
                      type="text"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Project</option>
                      <option>{PrName}</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Towards Flat Number
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom01" >
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Tower{" "}
                    </Typography>
                    <Form.Select
                      required
                      value={ttowerno}
                      onChange={(e) => setTowardsTowerNumber(e.target.value)}
                      type="text"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Tower </option>
                      {responset
                        .filter((item) => item.Towers.startsWith(projectName)) // Filter towers that start with the project name
                        .map((item) => (
                          <option>{item.Towers}</option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Towards Tower Number
                    </Form.Control.Feedback>
                  </Form.Group>
                  
                  <Form.Group as={Col} md="3" controlId="validationCustom01" className='mr-2 p-2'>
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Floor
                  </Typography>
                  <Form.Select
                    required
                  onChange={handleFloorChange}
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  >
                    <option>Select Floor</option>
                    {[
                      ...new Set(response4.map((data) => data.floor.trim())),
                    ].map((floor, index) => (
                      <option key={index}>{floor}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please Enter project name
                  </Form.Control.Feedback>
                </Form.Group>


                  </div>

      

          <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
          <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
              {/* <div>

                  <input onChange={(e) => searchCommon(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search user..' />
              </div> */}

          </div>
          <Paper sx={{ width: '100%', overflowX: 'auto' }}>
  
      <div>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ borderTop: "1px solid lightgray" }}>
        <TableRow>
          <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Floor</TableCell>
          <TableCell align="start" style={{ minWidth: 170, fontSize: 14, fontWeight: "600" }}>Unit Name</TableCell>
          <TableCell align="start" style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}>Status</TableCell>
          <TableCell align="start" style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}>Unit Size</TableCell>
          <TableCell align="start" style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}>Facing</TableCell>
          <TableCell align="start" style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}>Corner</TableCell>
          <TableCell align="start" style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}>Total Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredData.slice(startIndex,endIndex).map((row, index) => {
           console.log(`Row ${index + 1} - Mortgage:`, row.Mortgage);
           return(
          <TableRow key={index}   style={{
              backgroundColor: row.Mortgage === 1 ? 'lightgray':'null'
         
            }}>
            <TableCell align="start" style={{ fontSize: 12, minWidth: 160 }}>{row.floor}</TableCell>
            <TableCell align="start" style={{ fontSize: 12, minWidth: 170 }}>{row.unit_name}</TableCell>
            <TableCell align="start" style={{
              fontSize: 12,
              minWidth: 0,
              fontWeight: "bold",
              color: row.status === 'Blocked' ? 'gray' : row.status === 'Booked' ? 'red' : 'green'
            }}>{row.status}</TableCell>
            <TableCell align="start" style={{ fontSize: 12 }}>{row.unitsizeNum}</TableCell>
            <TableCell align="start" style={{ fontSize: 12 }}>{row.Facing}</TableCell>
            <TableCell align="start" style={{ fontSize: 12 }}>{row.cornor}</TableCell>
            <TableCell align="start" style={{ fontSize: 12, cursor: 'pointer', color: 'blue' }}
                        onClick={() => handleOpen(row)}>{row.totalvalue}</TableCell>
          </TableRow>
           )
        })}
      </TableBody>
    </Table>

    {/* Popup Dialog for Detailed View */}
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{color:"rgb(126, 110, 228)"}}>{selectedUnit ? `${selectedUnit.unit_name} Details` : ''}</DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Facing Amount:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.facingAmount : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Corner Amount:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.cornerAmount : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Base Price:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.BasePrice : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Advance Maintenance Amount:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.advMaitanceAmt : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Car Parking Amount:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.carpousAmt : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Caution Non Refundable Amount:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.cautionNonRefAmt : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Caution Refundable Amount:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.cautionRefAmt : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Clubhouse Amount:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.clubHouseAmt : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Water Electricity Amount:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.waterElectricityAmt : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Floor Rise Amount:</TableCell>
              <TableCell>{selectedUnit ? selectedUnit.floorRiseAmount : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold',color:'green' }}>Total:</TableCell>
              <TableCell style={{ fontWeight: 'bold',color:'green' }}>{selectedUnit ? selectedUnit.totalvalue : ''}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
        {/* <Button onClick={() => alert(`Sharing details of ${selectedUnit.unit_name} to customer...`)} color="primary">Share</Button>
         */}
           <EmailShareButton
          subject={`Details of ${selectedUnit ? selectedUnit.unit_name : ''}`}
          body={`Details of ${selectedUnit ? selectedUnit.unit_name : ''}:\n\n` +
            `Facing Amount: ${selectedUnit ? selectedUnit.facingAmount : ''}\n` +
            `Corner Amount: ${selectedUnit ? selectedUnit.cornerAmount : ''}\n` +
            `Base Price: ${selectedUnit ? selectedUnit.BasePrice : ''}\n` +
            `Advance Maintenance Amount: ${selectedUnit ? selectedUnit.advMaitanceAmt : ''}\n` +
            `Car Parking Amount: ${selectedUnit ? selectedUnit.carpousAmt : ''}\n` +
            `Caution Non Refundable Amount: ${selectedUnit ? selectedUnit.cautionNonRefAmt : ''}\n` +
            `Caution Refundable Amount: ${selectedUnit ? selectedUnit.cautionRefAmt : ''}\n` +
            `Clubhouse Amount: ${selectedUnit ? selectedUnit.clubHouseAmt : ''}\n` +
            `Water Electricity Amount: ${selectedUnit ? selectedUnit.waterElectricityAmt : ''}\n` +
            `Floor Rise Amount: ${selectedUnit ? selectedUnit.floorRiseAmount : ''}\n` +
            `Total Value: ${selectedUnit ? selectedUnit.totalvalue : ''}\n`}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>

        <WhatsappShareButton
          title={`Details of ${selectedUnit ? selectedUnit.unit_name : ''}`}
          url={window.location.href}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </DialogActions>
    </Dialog>
  </div>
    <TablePagination
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      count={fetchedData.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>
          

         
      </main>

      <Typography variant="h6" style={{marginTop:30,color:'blue'}}>Channel Partner</Typography>

      <TableContainer component={Paper} >
      
    <Table>
      <TableHead> 
        <TableRow>
          <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>S.NO</TableCell>
          <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Name</TableCell>
          <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Mobile</TableCell>
          <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Project Name</TableCell>

          <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Channel ID</TableCell>
      
          <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>RERANO</TableCell>
   
        </TableRow>
      </TableHead>
      <TableBody>
        {dataCp.filter((row=>row.projectName===PrName)).map((row,index) => (
          <TableRow key={row.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.mobile}</TableCell>
            <TableCell>{row.projectName}</TableCell>
            <TableCell key={row.id} onClick={() => handleRowClick(row.channelId)}>{row.channelId}</TableCell>
    
            <TableCell>{row.rerano}</TableCell>
            
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>

  {popupOpen && popupData && (
    <>
    <Typography variant="h6" style={{ marginTop: 30, color: 'blue' }}>Channel Partner Lead Data</Typography>
  <TableContainer component={Paper} style={{marginTop:'20px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Sales Executive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {popupData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.mobile}</TableCell>
                <TableCell>{row.ProjectName}</TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell>{row.salesExecutive}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
  )}

    {/* <LeadManagementPopup
      open={popupOpen}
      onClose={() => setPopupOpen(false)}
      data={popupData}
    />
       */}
      </>
  )
}

export default AdminDashboard;
