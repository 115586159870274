// import React from 'react'

// function Wellcome() {
//   return (
//     <div style={{color:'black'}}>
//       <center> <h1>Welcome</h1></center>

//       <img src={require('./img/kolla1.jpg')} alt='Description of the image'/>
//       <img src={require('./img/kolla2.jpg')} alt='Description of the image'/>
//       <img src={require('./img/kolla3.jpg')} alt='Description of the image'/>

//     </div>
//   )
// }

// export default Wellcome


import * as React from 'react';
import Paper from '@mui/material/Paper';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useState } from 'react';
import { MdHomeWork } from "react-icons/md";
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';


export default function Basic() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event);
  };



  const [open, setOpen] = useState(false)



  return (
    <div>


      <div className='leadManagementTable'>
        <Paper elevation={0} >

          {/* <Navbar className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home"><MdHomeWork />  </Navbar.Brand>
        <Navbar.Toggle />
       
      </Container>
    </Navbar> */}
          <div style={{ color: 'black' }}>
            {/* <React.Fragment>
              <Box sx={{ maxWidth: '95%', backgroundColor: 'lightsteelblue', borderRadius: '2%' }}>
                <h1 style={{ marginLeft: '10%', fontSize: '5rem' }}><strong>Nile Vally</strong></h1>
                <Typography style={{ fontSize: '3rem', marginLeft: '10%', marginTop: '10%' }}> <MdHomeWork />  Real Estate Agency</Typography> <br />

                <p style={{ fontSize: '2rem', marginLeft: '10%' }}>The Right.... <br /> Place Of House finding....!</p>
              </Box>
            </React.Fragment> <br />
            <div style={{ display: 'flex', gap: '3%' }}>
              <img style={{ marginLeft: '2%', width: '40%' }} src={require('./img/kolla3.jpg')} alt='Description of the image' />
              <img style={{ width: '50%' }} src={require('./img/kolla1.jpg')} alt='Description of the image' />
            </div><br />
            <img style={{ marginLeft: '20%' }} src={require('./img/banner-1.jpg')} alt='Description of the image' /><br /> */}


            <img style={{maxWidth:'100%'}} src={require('./img/kolla2.jpg')} alt='Description of the image' />

          </div>
        </Paper>
      </div>


    </div>
  );
}