import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Typography } from '@mui/material';
import axios from 'axios';

const Alerts = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    axios.get('http://localhost:3003/blockform/countdown')
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleCancel = (id) => {
    // Handle cancel action (you need to define the logic)
    console.log('Cancel action for ID:', id);
  };

  return (
    <>
    <Typography variant="h4" gutterBottom color='rgb(126, 110, 228)'>
    Alerts
  </Typography>
    <TableContainer component={Paper}>
    
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Name</TableCell>
            <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Email</TableCell>
            <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Mobile</TableCell>
            <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Flat No</TableCell>
            <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Sales Executive Email</TableCell>
            <TableCell align="start" style={{ minWidth: 160, fontSize: 14, fontWeight: "600" }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.mobile}</TableCell>
              <TableCell>{row.tflatNo}</TableCell>
              <TableCell>{row.salesExecutiveEmail}</TableCell>
              <TableCell>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleCancel(row.id)}
                >
                  Cancel
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};

export default Alerts;
