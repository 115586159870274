import React, { useEffect } from 'react'
import { useState } from 'react'

export default function Towerblocks({floorData}) {
    const [inputList, setInputList] = useState(['']);

    const onChangeHandle = (e, index) => {
      const list = [...inputList];
      list[index] = e.target.value;
      setInputList(list);
    };
  
    useEffect(() => {
      floorData(inputList);
    }, [inputList]);
  
    const handleAddClick = () => {
      setInputList([...inputList, '']);
    };
  
    const removeInputField = (index) => {
      const rows = [...inputList];
      rows.splice(index, 1);
      setInputList(rows);
    };
  

  return (
    <>
    <div style={{ borderRadius: 3, borderColor: 'rgb(210, 195, 195)', borderWidth: 0.9, borderStyle: 'solid', padding: 4, marginBottom: 5 }}>
      <div style={{ width: '100%', borderRadius: 3, justifyContent: 'center', alignItems: 'center', elevation: 0, marginBottom: 1 }}>
        <p style={{ color: 'rgb(133, 100, 100)', fontWeight: '500', textTransform: 'uppercase' }}>Towers</p>
        <p style={{ color: 'grey', marginTop: -20 }}>You can add as many Towers as you want</p>
      </div>

      <div style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {inputList.map((data, index) => (
          <input
            key={index}
            placeholder="tower name"
            style={{
              width: '100%',
              height: 42,
              borderRadius: 3,
              backgroundColor: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 0.5,
              borderColor: data === '' ? 'rgb(97, 125, 133)' : 'green',
              borderStyle: 'solid',
              marginTop: index === 0 ? 0 : 8,
              paddingLeft: 4,
            }}
            onChange={(e) => {
              onChangeHandle(e, index);
            }}
          />
        ))}
      </div>

      <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
        <div
          onClick={() => {
            handleAddClick();
          }}
          style={{
            width: '48.6%',
            height: 42,
            borderRadius: 3,
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 0.5,
            borderStyle: 'solid',
            marginTop: 8,
            backgroundColor: 'green',
            cursor: 'pointer',
          }}
        >
          <p style={{ fontWeight: 500, color: 'white', textAlign: 'center', marginTop: 8 }}>+ Add More</p>
        </div>

        {inputList.length > 1 && (
          <div
            onClick={() => {
              removeInputField(inputList.length - 1);
            }}
            style={{
              width: '48.6%',
              height: 42,
              borderRadius: 3,
              backgroundColor: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 0.5,
              borderStyle: 'solid',
              marginTop: 8,
              backgroundColor: 'red',
              cursor: 'pointer',
            }}
          >
            <p style={{ fontWeight: 500, color: 'white', textAlign: 'center', marginTop: 8 }}>Remove</p>
          </div>
        )}
      </div>
    </div>
  </>
  )
}
