// import React, { useState } from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import axios from 'axios';

// const CustomerDetailsModal = ({ ucin, show, handleClose }) => {
//   const [customerDetails, setCustomerDetails] = useState(null);

//   React.useEffect(() => {
//     if (ucin) {
//       axios.get(`https://buildfastapi.kollagroup.in/create-customer/api/v1/customer/${ucin}`)
//         .then(response => setCustomerDetails(response.data))
//         .catch(error => console.error('Error fetching customer details:', error));
//     }
//   }, [ucin]);

//   if (!customerDetails) return null;

//   console.log(customerDetails)


  // const modalStyle = {
  //   zIndex: 10000// Adjust the zIndex value as needed
  // };

//   return (
//     <Modal show={show} onHide={handleClose} centered style={modalStyle}>
//       <Modal.Header closeButton>
//         <Modal.Title>Customer Details</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <p><strong>Customer Name:</strong> {customerDetails.customerName}</p>
//         <p><strong>Email:</strong> {customerDetails.email}</p>
//         <p><strong>Mobile Number:</strong> {customerDetails.mobileNumber}</p>
//         <p><strong>Aadhar No:</strong> {customerDetails.aadharNo}</p>
//         <p><strong>PAN No:</strong> {customerDetails.panNo}</p>
//         <p><strong>Address:</strong> {customerDetails.address}</p>
//         <p><strong>Flat No:</strong> {customerDetails.flatNo}</p>
//         <p><strong>Project Name:</strong> {customerDetails.projectName}</p>
//         <p><strong>UCINO:</strong> {customerDetails.ucin}</p>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default CustomerDetailsModal;


import React, { useState, useEffect } from 'react';
import { Modal, Button, ModalHeader ,ModalFooter, ModalBody} from 'react-bootstrap';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper

} from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import { Interests } from '@mui/icons-material';


const CustomerDetailsModal = ({ ucin, show, handleClose }) => {
  const [customerData, setCustomerData] = useState(null);
 
  const [loading, setLoading] = useState(false);


  const modalStyle = {
    width: '100%', // Adjust the width as needed
   // Optional: Set a maximum width for responsiveness
    margin: 'auto' ,
 
    zIndex:10000// Center the modal horizontally
  };

  const [billingData, setBillingData] = useState(null);
  const [paymentData, setPaymentData] = useState([]);
  const [interestData, setInterestData] = useState([]);


  // https://buildfastapi.kollagroup.in/create-customer/api/v1/customer/${ucin}

  useEffect(() => {
    if (ucin) {
      axios.get(`https://buildfastapi.kollagroup.in/create-customer/api/v1/customer/${ucin}`)
        .then(response => {
          const { customer, billing, payments,interest } = response.data;
          console.log('Fetched Data:', customer, billing, payments,interest);
          setCustomerData(customer);
          setBillingData(billing);
          setPaymentData(payments);
          setInterestData(interest) // Ensure paymentData is set correctly
        })
        .catch(error => {
          console.error('Error fetching customer data:', error);
        });
    }
  }, [ucin]);

  useEffect(() => {
    console.log('billingData:', billingData);
    console.log('paymentData:', paymentData);
    console.log('interestData:', interestData);
  }, [billingData, paymentData,interestData]);



  // const formatDate = (dateString) => {
  //   if (!dateString) return 'N/A';
  //   const date = new Date(dateString);
  //   const day = date.getDate().toString().padStart(2, '0');
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   const year = date.getFullYear().toString();
  //   return `${day}-${month}-${year}`;
  // };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A'; // Handle null or undefined dates
  
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };
  

//03-09-2024


//   const renderPaymentInvoiceCells = () => {
//     let paymentInvoiceCells = [];
  
//     // Ensure paymentData is an array before proceeding
//     if (!Array.isArray(paymentData) || paymentData.length === 0) {
//       console.log('paymentData is empty or not an array:', paymentData);
//     }
  
//     // Loop through all possible invoices in billingData (invoice1 to invoice10)
//     for (let i = 1; i <= 10; i++) {
//       const invoiceKey = `invoice${i}`;
//       const balanceKey = `balance${i}`;
//       const dateKey = `date${i}`;
//       const gstTotalKey=`gstTotal${i}`
  
//       // Check if billingData has invoice details for current invoice number
//       if (billingData && billingData[invoiceKey] !== '0') {
//         // Determine the bill date to display (use date from billingData)
//         const billDate = billingData[dateKey] ? new Date(billingData[dateKey]).toLocaleDateString() : 'N/A';
  
//         // Determine the balance to display (use balance from billingData)
//         // const balance = billingData[balanceKey] ? billingData[balanceKey] : '0';
//         const balance = billingData[balanceKey] ? Math.round(parseFloat(billingData[balanceKey])) : '0';
// console.log(balance); //
  
//         // Find all payment entries corresponding to the current invoice in paymentData
//         const paymentEntries = paymentData.filter(item => item.invoice === billingData[invoiceKey]);
  
//         if (paymentEntries.length === 0) {
//           // No payment data found for this invoice, display a row with 'N/A' for payment details
//           paymentInvoiceCells.push(
//             <TableRow key={`${invoiceKey}-no-payment-entry`}>
//               <TableCell>{billingData[invoiceKey]}</TableCell>
//               <TableCell>{billingData[gstTotalKey]}</TableCell>
//               <TableCell>N/A</TableCell> {/* Display 'N/A' for paid amount if no payment entry */}
//               <TableCell>N/A</TableCell> {/* Display 'N/A' for check date */}
//               <TableCell>{formatDate(billDate)}</TableCell>
//               <TableCell>N/A</TableCell> {/* Display 'N/A' for check date */}
//               <TableCell>{balance}</TableCell>
//             </TableRow>
//           );
//         } else {
//           // Create a table row for each payment entry found
//           paymentEntries.forEach(item => {
//             paymentInvoiceCells.push(
//               <TableRow key={`${invoiceKey}-${item.id}`}>
//                 <TableCell>{billingData[invoiceKey]}</TableCell>
//                 <TableCell>{billingData[gstTotalKey]}</TableCell>
//                 <TableCell>{item.paidAmount}</TableCell>
//                 <TableCell>{item.status}</TableCell>
//                 <TableCell>{formatDate(billDate)}</TableCell>
//                 <TableCell>{formatDate(item.checkdate)}</TableCell>
//                 <TableCell>{balance}</TableCell>
//               </TableRow>
//             );
//           });
//         }
//       }
//     }
  
//     return paymentInvoiceCells;
//   };
// const renderPaymentInvoiceCells = () => {
//   let paymentInvoiceCells = [];

//   // Ensure paymentData and interestData are arrays before proceeding
//   if (!Array.isArray(paymentData) || paymentData.length === 0) {
//     console.log('paymentData is empty or not an array:', paymentData);
//   }
//   if (!Array.isArray(interestData) || interestData.length === 0) {
//     console.log('interestData is empty or not an array:', interestData);
//   }

//   // Loop through all possible invoices in billingData (invoice1 to invoice10)
//   for (let i = 1; i <= 10; i++) {
//     const invoiceKey = `invoice${i}`;
//     const balanceKey = `balance${i}`;
//     const dateKey = `date${i}`;
//     const gstTotalKey = `gstTotal${i}`;

//     // Check if billingData has invoice details for current invoice number
//     if (billingData && billingData[invoiceKey] !== '0') {
//       // Determine the bill date to display (use date from billingData)
//       const billDate = billingData[dateKey] ? new Date(billingData[dateKey]).toLocaleDateString() : 'N/A';

//       // Determine the balance to display (use balance from billingData)
//       const balance = billingData[balanceKey] ? Math.round(parseFloat(billingData[balanceKey])) : '0';

//       // Find all payment entries corresponding to the current invoice in paymentData
//       const paymentEntries = paymentData.filter(item => item.invoice === billingData[invoiceKey]);

//       // Find all interest entries corresponding to the current invoice in interestData
//       const interestEntries = interestData.filter(item => item.invoice === billingData[invoiceKey]);

//       // Add rows for each payment entry found
//       if (paymentEntries.length === 0) {
//         paymentEntries.forEach(item => {
//           paymentInvoiceCells.push(
//             <TableRow key={`${invoiceKey}-no-payment-entry`}>
//               <TableCell>{billingData[invoiceKey]}</TableCell>
//               <TableCell>{billingData[gstTotalKey]}</TableCell>
//               <TableCell>{item.paidAmount}</TableCell>
//               <TableCell>{item.status}</TableCell>
//               <TableCell>{formatDate(billDate)}</TableCell>
//               <TableCell>{formatDate(item.checkdate)}</TableCell>
//               <TableCell>{balance}</TableCell>
//               <TableCell>N/A</TableCell> {/* No interest details for this payment row */}
//               <TableCell>N/A</TableCell> {/* No interest details for this payment row */}
//               <TableCell>N/A</TableCell> {/* No interest details for this payment row */}
//               <TableCell>N/A</TableCell> {/* No interest details for this payment row */}
//             </TableRow>
//           );
//         });
//       }

//       // Add rows for each interest entry found
//       if (interestEntries.length > 0) {
//         interestEntries.forEach(item => {
//           paymentInvoiceCells.push(
//             <TableRow key={`${invoiceKey}-interest-${item.id}`}>
//               <TableCell>{item.invoice}</TableCell>
//               <TableCell>{item.installmentAmount}</TableCell>
//               <TableCell>N/A</TableCell>
//               <TableCell>N/A</TableCell> {/* Assuming no direct status for interest */}
//               <TableCell>{formatDate(billDate)}</TableCell> {/* Display the same bill date as payments */}
//               <TableCell>N/A</TableCell> {/* Assuming no paid date for interest */}
//               <TableCell>{item.TotalAmount}</TableCell>
//               <TableCell>{item.interestAmount}</TableCell> {/* Display interest amount */}
//               <TableCell>{item.TotalAmount}</TableCell> {/* Display total amount */}
//               <TableCell>{formatDate(item.interestDate)}</TableCell> {/* Display interest date */}
//               <TableCell>{item.daysCount}</TableCell> {/* Display days count */}
//             </TableRow>
//           );
//         });
//       }
//     }
//   }

//   return paymentInvoiceCells;
// };

const renderPaymentInvoiceCells = () => {
  let paymentInvoiceCells = [];

  // Ensure paymentData and interestData are arrays before proceeding
  if (!Array.isArray(paymentData) || paymentData.length === 0) {
    console.log('paymentData is empty or not an array:', paymentData);
  }
  if (!Array.isArray(interestData) || interestData.length === 0) {
    console.log('interestData is empty or not an array:', interestData);
  }

  // Loop through all possible invoices in billingData (invoice1 to invoice10)
  for (let i = 1; i <= 10; i++) {
    const invoiceKey = `invoice${i}`;
    const balanceKey = `balance${i}`;
    const dateKey = `date${i}`;
    const gstTotalKey = `gstTotal${i}`;

    // Check if billingData has invoice details for current invoice number
    if (billingData && billingData[invoiceKey] && billingData[invoiceKey] !== '0') {
      // Determine the bill date to display (use date from billingData)
      const billDate = billingData[dateKey] ? new Date(billingData[dateKey]).toLocaleDateString() : 'N/A';

      // Determine the balance to display (use balance from billingData)
      const balance = billingData[balanceKey] ? Math.round(parseFloat(billingData[balanceKey])) : '0';

      // Find all payment entries corresponding to the current invoice in paymentData
      const paymentEntries = paymentData.filter(item => item.invoice === billingData[invoiceKey]);

      // Find all interest entries corresponding to the current invoice in interestData
      const interestEntries = interestData.filter(item => item.invoice === billingData[invoiceKey]);

      // Always add a row for the invoice, regardless of payments or interests
      paymentInvoiceCells.push(
        <TableRow key={`${invoiceKey}`}>
          <TableCell>{billingData[invoiceKey]}</TableCell>
          <TableCell>{billingData[gstTotalKey]}</TableCell>
          <TableCell>{paymentEntries.length > 0 ? paymentEntries[0].paidAmount : 'N/A'}</TableCell>
          <TableCell>{paymentEntries.length > 0 ? paymentEntries[0].status : 'N/A'}</TableCell>
          <TableCell>{formatDate(billDate)}</TableCell>
          <TableCell>{paymentEntries.length > 0 ? formatDate(paymentEntries[0].checkdate) : 'N/A'}</TableCell>
          <TableCell>{balance}</TableCell>
          <TableCell>{interestEntries.length > 0 ? interestEntries[0].interestAmount : 'N/A'}</TableCell>
          <TableCell>{interestEntries.length > 0 ? interestEntries[0].TotalAmount : 'N/A'}</TableCell>
          <TableCell>{interestEntries.length > 0 ? formatDate(interestEntries[0].interestDate) : 'N/A'}</TableCell>
          <TableCell>{interestEntries.length > 0 ? interestEntries[0].daysCount : 'N/A'}</TableCell>
        </TableRow>
      );
    }
  }

  return paymentInvoiceCells;
};


  
  const hasBillingData = billingData && Object.keys(billingData).some(key => key.startsWith('installment') && billingData[key] !== '0');

  // Render the table only if there's matching billing data
  if (hasBillingData) {
    return (
      // <Modal show={show} onHide={handleClose} centered size="xl" style={modalStyle}>
      //   <ModalHeader closeButton>
      //     <Modal.Title>Customer Details</Modal.Title>
      //   </ModalHeader>
      //   <ModalBody>
      //     <TableContainer component={Paper}>
      //       <Table>
      //         <TableHead>
      //           <TableRow>
      //             <TableCell><strong>Invoice No</strong></TableCell>
      //             <TableCell><strong>Invoice Amount</strong></TableCell>
      //             <TableCell><strong>Paid Amount</strong></TableCell>
      //             <TableCell><strong>Status</strong></TableCell>
      //             <TableCell><strong>Bill Date</strong></TableCell>
      //             <TableCell><strong>Paid Date</strong></TableCell>
      //             <TableCell><strong>Balance</strong></TableCell>
      //           </TableRow>
      //         </TableHead>
      //         <TableBody>
      //           {/* Render payment invoices */}
      //           {renderPaymentInvoiceCells(billingData, paymentData)}
      //         </TableBody>
      //       </Table>
      //     </TableContainer>
      //   </ModalBody>
      //   <ModalFooter>
      //     <Button variant="contained" onClick={handleClose}>
      //       Close
      //     </Button>
      //   </ModalFooter>
      // </Modal>
      <Modal show={show} onHide={handleClose} centered size="xl" style={modalStyle}>
      <Modal.Header closeButton>
        <Modal.Title>Customer Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Invoice No</strong></TableCell>
                <TableCell><strong>Invoice Amount</strong></TableCell>
                <TableCell><strong>Paid Amount</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell><strong>Bill Date</strong></TableCell>
                <TableCell><strong>Paid Date</strong></TableCell>
                <TableCell><strong>Balance</strong></TableCell>
                <TableCell><strong>Interest Amount</strong></TableCell>
                <TableCell><strong>Total Amount</strong></TableCell>
                <TableCell><strong>Interest Date</strong></TableCell>
                <TableCell><strong>Days Count</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderPaymentInvoiceCells()}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    );
  } else {
    return null; // Render nothing if there's no matching billing data
  }
};



// const renderPaymentInvoiceCells = (billingData, paymentData) => {
//   let paymentInvoiceCells = [];

//   // Loop through all possible invoices in billingData (invoice1 to invoice10)
//   for (let i = 1; i <= 10; i++) {
//     const invoiceKey = `invoice${i}`;
//     const balanceKey = `balance${i}`;
//     const dateKey = `date${i}`;

//     // Check if billingData has invoice details for current invoice number
//     if (billingData && billingData[invoiceKey] !== '0') {
//       // Determine the bill date to display (use date from billingData)
//       const billDate = billingData[dateKey] ? new Date(billingData[dateKey]).toLocaleDateString() : 'N/A';

//       // Determine the balance to display (use balance from billingData)
//       const balance = billingData[balanceKey] ? billingData[balanceKey] : '0';

//       // Initialize paidAmount to '0'
//       let paidAmount = '0';

//       console.log(paymentData[invoiceKey])
//       // Check if paymentData has the current invoice and if it has a paidAmount
//       if (paymentData && paymentData[invoiceKey] && paymentData[invoiceKey].paidAmount) {
//         paidAmount = paymentData[invoiceKey].paidAmount;
//       }

//       // Create a table row with invoice number, paid amount, bill date, and balance
//       paymentInvoiceCells.push(
//         <TableRow key={`invoice-${i}`}>
//           <TableCell>{billingData[invoiceKey]}</TableCell>
//           <TableCell>{paidAmount}</TableCell>
//           <TableCell>{billDate}</TableCell>
//           <TableCell>{balance}</TableCell>
//         </TableRow>
//       );
//     }
//   }

//   return paymentInvoiceCells;
// };

// const hasBillingData = billingData && Object.keys(billingData).some(key => key.startsWith('installment') && billingData[key] !== '0');

// // Check if there's any payment data
// const hasPaymentData = paymentData && Object.keys(paymentData).length > 0;

// // Render the table only if there's matching data
// if (hasBillingData) {
//   return (
//     <Modal show={show} onHide={handleClose} centered size="xl" style={modalStyle}>
//       <ModalHeader closeButton>
//         <Modal.Title>Customer Details</Modal.Title>
//       </ModalHeader>
//       <ModalBody>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell><strong>Invoice No</strong></TableCell>
//                 <TableCell><strong>Paid Amount</strong></TableCell>
//                 <TableCell><strong>Bill Date</strong></TableCell>
//                 <TableCell><strong>Balance</strong></TableCell>
//                 <TableCell><strong>Paid Date</strong></TableCell>
               
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {/* Render payment invoices */}
//               {renderPaymentInvoiceCells(billingData, paymentData)}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </ModalBody>
//       <ModalFooter>
//         <Button variant="contained" onClick={handleClose}>
//           Close
//         </Button>
//       </ModalFooter>
//     </Modal>
//   );
// } else {
//   return null; // Render nothing if there's no matching data
// }
// };

export default CustomerDetailsModal;

{/* <TableContainer component={Paper}>
<Table>
  <TableHead>
    <TableRow>
      <TableCell><strong>Customer Name</strong></TableCell>
      <TableCell><strong>Finance Type</strong></TableCell>
      {/* Render dynamic headers based on loan type */}
      // {customerData.loan === 'PRE EMI' ? (
      //   <>
      //     <TableCell><strong>Installment 1</strong></TableCell>
      //     <TableCell><strong>Installment 2</strong></TableCell>
      //     <TableCell><strong>Installment 3</strong></TableCell>
      //     <TableCell><strong>Payment Invoice 1</strong></TableCell>
      //     <TableCell><strong>Payment Invoice 2</strong></TableCell>
      //     <TableCell><strong>Payment Invoice 3</strong></TableCell>
      //     <TableCell><strong>Paid Amount 1</strong></TableCell>
      //      <TableCell><strong>Bill Date 1</strong></TableCell>
      //      <TableCell><strong>Status</strong></TableCell>
      //   </>
      // ) : (
      //   <>
      //     <TableCell><strong>Installment 1</strong></TableCell>
      //     <TableCell><strong>Installment 2</strong></TableCell>
      //     <TableCell><strong>Installment 3</strong></TableCell>
      //     <TableCell><strong>Installment 4</strong></TableCell>
      //     <TableCell><strong>Installment 5</strong></TableCell>
      //     <TableCell><strong>Installment 6</strong></TableCell>
      //     <TableCell><strong>Installment 7</strong></TableCell>
      //     <TableCell><strong>Installment 8</strong></TableCell>
      //     <TableCell><strong>Installment 9</strong></TableCell>
      //     <TableCell><strong>Installment 10</strong></TableCell>
      //     <TableCell><strong>Invoice No 1</strong></TableCell>
      // <TableCell><strong>Paid Amount 1</strong></TableCell>
      // <TableCell><strong>Bill Date 1</strong></TableCell>
      // <TableCell><strong>Invoice No 2</strong></TableCell>
      // <TableCell><strong>Paid Amount 2</strong></TableCell>
      // <TableCell><strong>Bill Date 2</strong></TableCell>
      // <TableCell><strong>Invoice No 3</strong></TableCell>
      // <TableCell><strong>Paid Amount 3</strong></TableCell>
      // <TableCell><strong>Bill Date 3</strong></TableCell>
      // <TableCell><strong>Invoice No 4</strong></TableCell>
      // <TableCell><strong>Paid Amount 4</strong></TableCell>
      // <TableCell><strong>Bill Date 4</strong></TableCell>
      // <TableCell><strong>Invoice No 5</strong></TableCell>
      // <TableCell><strong>Paid Amount 5</strong></TableCell>
      // <TableCell><strong>Bill Date 5</strong></TableCell>
      // <TableCell><strong>Invoice No 6</strong></TableCell>
      // <TableCell><strong>Paid Amount 6</strong></TableCell>
      // <TableCell><strong>Bill Date 6</strong></TableCell>
      // <TableCell><strong>Invoice No 7</strong></TableCell>
      // <TableCell><strong>Paid Amount 7</strong></TableCell>
      // <TableCell><strong>Bill Date 7</strong></TableCell>
      // <TableCell><strong>Invoice No 8</strong></TableCell>
      // <TableCell><strong>Paid Amount 8</strong></TableCell>
      // <TableCell><strong>Bill Date 8</strong></TableCell>
      // <TableCell><strong>Invoice No 9</strong></TableCell>
      // <TableCell><strong>Paid Amount 9</strong></TableCell>
      // <TableCell><strong>Bill Date 9</strong></TableCell>
      // <TableCell><strong>Invoice No 10</strong></TableCell>
      // <TableCell><strong>Paid Amount 10</strong></TableCell>
      // <TableCell><strong>Bill Date 10</strong></TableCell>
      // <TableCell><strong>Status</strong></TableCell>

//         </>
//       )}
 
//     </TableRow>
//   </TableHead>
//   <TableBody>
//     <TableRow>
//       {/* Customer Data */}
//       <TableCell>{customerData.customerName}</TableCell>
//       <TableCell>{customerData.loan}</TableCell>
      
//       {/* Billing Data Installments */}
//       {renderInstallmentCells(billingData)}
      
//       {/* Payment Data Invoices */}
//       {renderPaymentInvoiceCells(billingData, paymentData)}
//     </TableRow>
//   </TableBody>
// </Table>
// </TableContainer> */}