import react from 'react'
import { FaFacebook, FaFacebookF, FaInstagram, FaSignInAlt, FaTwitter, FaTwitterSquare, FaYoutube, FaYoutubeSquare } from 'react-icons/fa'
import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const ForgotPassworEmailSent = () => {
    return (
        <div className='card login-main-container' style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, borderRadius: '0.1rem', border: 'none' }}  >
            {/* <div className='shadow' style={{ paddingTop: 9, backgroundColor: 'rgba(40, 12, 99, 1)', paddingBottom: 1, paddingLeft: 81, paddingRight: 81 }} >
                <div>
                    <p style={{ fontWeight: '400', color: 'white',fontSize:12 }}><b>Effective August 1, 2023, we are retiring some older versions of On-Screen Takeoff and Quick Bid.</b><br />
                        On-Screen Takeoff v.3.95.03.02 and older, and Quick Bid 4.97.03.01 and older will no longer be supported or distributed after August 1. You will not be able to activate a license for these versions, either. For more information, click here.
                        To discuss your available options, call your Customer Success Manager, Monday through Friday, 7am-6pm CST, at (866) 627-6246.
                    </p>
                </div>
            </div> */}
            <div className='navbar3' style={{ paddingTop: 31, backgroundColor: 'white', paddingBottom: 15, paddingLeft: 81, paddingRight: 81 }} >
                <div>
                     
                </div>
            </div>
            
            <div className='card' style={{ height: '18rem', position: 'relative', justifyContent: 'center', alignItems: 'center', borderRadius: '0.04rem', border: 'none',marginBottom:10}} >
                <div className='loginBackground' style={{ opacity: 0.7, backgroundColor: 'black', height: '21rem' }}>
                </div>
                {/* <h1 style={{ color: 'rgb(4, 110, 249)', opacity: 2, fontWeight: 'bold', position: 'relative', zIndex: 1000,marginTop:41 }}>Login/Register</h1> */}
            </div>
            <div className='col-10 mx-auto card shadow' style={{ border: 'none', marginTop: 61 }}>
                <div className='row p-1' >
                    <div className='col-4 mx-auto p-1' >
                        <Typography style={{ fontSize: 31, fontWeight: 'bold', color: 'rgba(41, 16, 105, 1)', marginTop: 12 }}>Forgot Password</Typography>
                        <TextField type='email' style={{ width: '100%', marginBottom: 34, marginTop: 79 }} id="standard-basic" label="Email (User Name)* " placeholder='Enter your email' variant="standard" />
                        <Button variant="contained" style={{ marginBottom: 50, width: '7.7rem' }}>Submit</Button>
                        <div>
                            <Link to='/' ><Button style={{ marginBottom: 50 }}>Login your account ?</Button></Link>
                        </div> 
                    </div>
                    <div className='col-6 mx-auto' style={{ borderLeft: '1px solid lightgray', paddingLeft: 30 }} >
                        <Typography style={{ fontWeight: 'bold', fontSize: 31, color: 'rgba(41, 16, 105, 1)', marginBottom: 30, marginTop: 12 }}>
                            Activation/Registration
                        </Typography>
                        <Typography style={{ marginBottom: 27, fontSize: 19 }}>
                            PlanSwift version 10.2 and newer requires users to activate with an email address and password.
                        </Typography>
                        <Typography style={{ marginBottom: 71, fontSize: 19 }}>
                            If you have not registered or don't remember your activation information, click below to get started.
                        </Typography>
                        <Button variant="contained" style={{ marginBottom: 50, width: '11rem' }}>Get started</Button>
                    </div>

                </div>
            </div>
            <div className='card' style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 28, backgroundColor: 'rgba(40, 12, 99, 1)', borderRadius: '0.02rem' }}>
                {/* <div className='card' style={{ width: '14rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none', backgroundColor: 'rgba(40, 12, 99, 1)', borderRadius: '0.02rem' }}>
                    <FaInstagram size={41} color='#fff'></FaInstagram>
                    <FaFacebookF size={41} color='#fff'></FaFacebookF>
                    <FaYoutubeSquare size={41} color='#fff'></FaYoutubeSquare>
                    <FaTwitterSquare size={41} color='#fff'></FaTwitterSquare>
                </div>
                <div style={{ width: '46rem', backgroundColor: 'white', height: '0.14rem', marginTop: 10 }} >

                </div> */}
                <Typography style={{ marginBottom: 10, color: 'white', fontSize: 18 }}>@2023 Emedha Technologies All Rights Reserved.</Typography>
            </div>
        </div>
    )
}

export default ForgotPassworEmailSent