
import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';

// export default function UnitBlocks({ unitData }) {
//   const [inputList, setInputList] = useState(['']);
//   const [corneru, setCorneru] = useState('')
//   const [floorw, setFloorw] = useState('')
//   const [facg, setFcg] = useState('')

//   const onChangeHandle = (e, index) => {
//     const list = [...inputList];
//     list[index] = e.target.value;
//     setInputList(list);
//   };

//   useEffect(() => {
//     unitData(inputList,corneru,floorw,facg);
//   }, [inputList,corneru,floorw,facg]);

//   const handleAddClick = () => {
//     setInputList([...inputList, '']);
//   };

//   const removeInputField = (index) => {
//     const rows = [...inputList];
//     rows.splice(index, 1);
//     setInputList(rows);
//   };

//   const [inputValue, setInputValue] = useState('');
//   const [options] = useState(['East', 'West']);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   return (
//     <>
//       <div style={{ borderRadius: 3, borderColor: 'rgb(210, 195, 195)', borderWidth: 0.9, borderStyle: 'solid', padding: 4, marginBottom: 5 }}>
//         <div style={{ width: '100%', borderRadius: 3, justifyContent: 'center', alignItems: 'center', elevation: 0, marginBottom: 1 }}>
//           <p style={{ color: 'rgb(133, 100, 100)', fontWeight: '500', textTransform: 'uppercase' }}>Unit</p>
//           <p style={{ color: 'grey', marginTop: -20 }}>You can add as many units as you want</p>
//         </div>


//         <div style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
//           <label>Unit:</label>
//           <label style={{marginLeft:'18%'}}>Corner:</label>
//           <label style={{marginLeft:'14%'}}>Floorwise:</label>
//           <label style={{marginLeft:'8%'}}>Facing:</label>
//           {inputList.map((data, index) => (
//             <div key={index} style={{ display: 'flex', marginBottom: 8, width: '100%' }}>
              
//               <input
//                 placeholder="unit name"
//                 style={{
//                   width: '25%',
//                   height: 42,
//                   borderRadius: 3,
//                   backgroundColor: 'white',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   borderWidth: 0.5,
//                   borderColor: data.Description === '' ? 'rgb(97, 125, 133)' : 'green',
//                   borderStyle: 'solid',
//                   paddingRight: 4,
//                 }}
//                 value={data.Description}
//                 onChange={(e) => {
//                   onChangeHandle(e, index, 'Description');
//                 }}
//               />
             
           
//               <select
//                 style={{
//                   width: '25%',
//                   height: 42,
//                   borderRadius: 3,
//                   backgroundColor: 'white',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   borderWidth: 0.5,
//                   borderColor: data.Amount === '' ? 'rgb(97, 125, 133)' : 'green',
//                   borderStyle: 'solid',
//                   paddingLeft: 4,
//                 }}
//                 onChange={(e) => setCorneru(e.target.value)}
//               >
//                 <option>Select Corner</option>
//                 <option>Yes</option>
//                 <option>No</option>
//               </select>
//               <datalist id="options-list">
//                 {options.map((option, index) => (
//                   <option key={index} value={option} />
//                 ))}
//               </datalist>
//               <select
//                 style={{
//                   width: '25%',
//                   height: 42,
//                   borderRadius: 3,
//                   backgroundColor: 'white',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   borderWidth: 0.5,
//                   borderColor: data.Amount === '' ? 'rgb(97, 125, 133)' : 'green',
//                   borderStyle: 'solid',
//                   paddingLeft: 4,
//                 }}
//                 onChange={(e) => setFloorw(e.target.value)}
//               >
//                 <option>select floorwise</option>
//                 <option>Yes</option>
//                 <option>No</option>
//               </select>
//               <datalist id="options-list">
//                 {options.map((option, index) => (
//                   <option key={index} value={option} />
//                 ))}
//               </datalist>
//                 <select
//                   style={{
//                     width: '25%',
//                     height: 42,
//                     borderRadius: 3,
//                     backgroundColor: 'white',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     borderWidth: 0.5,
//                     borderColor: data.Amount === '' ? 'rgb(97, 125, 133)' : 'green',
//                     borderStyle: 'solid',
//                     paddingLeft: 4,
//                   }}
//                   onChange={(e) => setFcg(e.target.value)}
//                 > 
//                   <option>Select Facing</option>
//                   <option>East</option>
//                   <option>West</option>
//                 </select> 
//                 <datalist id="options-list">
//                   {options.map((option, index) => (
//                     <option key={index} value={option} />
//                   ))}
//                 </datalist>
//               </div>
           
//           ))}
//         </div>

//         <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
//           <div
//             onClick={() => {
//               handleAddClick();
//             }}
//             style={{
//               width: '48.6%',
//               height: 42,
//               borderRadius: 3,
//               backgroundColor: 'white',
//               justifyContent: 'center',
//               alignItems: 'center',
//               borderWidth: 0.5,
//               borderStyle: 'solid',
//               marginTop: 8,
//               backgroundColor: 'green',
//               cursor: 'pointer',
//             }}
//           >
//             <p style={{ fontWeight: 500, color: 'white', textAlign: 'center', marginTop: 8 }}>+ Add More</p>
//           </div>

//           {inputList.length > 1 && (
//             <div
//               onClick={() => {
//                 removeInputField(inputList.length - 1);
//               }}
//               style={{
//                 width: '48.6%',
//                 height: 42,
//                 borderRadius: 3,
//                 backgroundColor: 'white',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 borderWidth: 0.5,
//                 borderStyle: 'solid',
//                 marginTop: 8,
//                 backgroundColor: 'red',
//                 cursor: 'pointer',
//               }}
//             >
//               <p style={{ fontWeight: 500, color: 'white', textAlign: 'center', marginTop: 8 }}>Remove</p>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

export default function UnitBlocks({ unitData }) {
  const [units, setUnits] = useState([{ unit_name: '', Cornor: '', Floorwise: '', Facing: '' }]);

  const onChangeHandle = (e, index, field) => {
    const updatedUnits = [...units];
    updatedUnits[index][field] = e.target.value;
    setUnits(updatedUnits);
  };

  const handleAddClick = () => {
    setUnits([...units, { unit_name: '', Cornor: '', Floorwise: '', Facing: '' }]);
  };

  const removeInputField = (index) => {
    const updatedUnits = [...units];
    updatedUnits.splice(index, 1);
    setUnits(updatedUnits);
  };

  useEffect(() => {
    unitData(units);
  }, [units]);

  return (
    <>
      <div style={{ borderRadius: 3, borderColor: 'rgb(210, 195, 195)', borderWidth: 0.9, borderStyle: 'solid', padding: 4, marginBottom: 5 }}>
        <div style={{ width: '100%', borderRadius: 3, justifyContent: 'center', alignItems: 'center', elevation: 0, marginBottom: 1 }}>
          <p style={{ color: 'rgb(133, 100, 100)', fontWeight: '500', textTransform: 'uppercase' }}>Unit</p>
          <p style={{ color: 'grey', marginTop: -20 }}>You can add as many units as you want</p>
        </div>

        <div style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
          {units.map((unit, index) => (
            <div key={index} style={{ display: 'flex', marginBottom: 8, width: '100%' }}>
              <input
                placeholder="unit name"
                style={{
                  width: '25%',
                  height: 42,
                  borderRadius: 3,
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 0.5,
                  borderColor: unit.unit_name === '' ? 'rgb(97, 125, 133)' : 'green',
                  borderStyle: 'solid',
                  paddingRight: 4,
                }}
                value={unit.unit_name}
                onChange={(e) => onChangeHandle(e, index, 'unit_name')}
              />

              <select
                style={{
                  width: '25%',
                  height: 42,
                  borderRadius: 3,
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 0.5,
                  borderColor: unit.Cornor === '' ? 'rgb(97, 125, 133)' : 'green',
                  borderStyle: 'solid',
                  paddingLeft: 4,
                }}
                value={unit.Cornor}
                onChange={(e) => onChangeHandle(e, index, 'Cornor')}
              >
                <option value="">Select Corner</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>

              <select
                style={{
                  width: '25%',
                  height: 42,
                  borderRadius: 3,
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 0.5,
                  borderColor: unit.Floorwise === '' ? 'rgb(97, 125, 133)' : 'green',
                  borderStyle: 'solid',
                  paddingLeft: 4,
                }}
                value={unit.Floorwise}
                onChange={(e) => onChangeHandle(e, index, 'Floorwise')}
              >
                <option value="">Select Floorwise</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>

              <select
                style={{
                  width: '25%',
                  height: 42,
                  borderRadius: 3,
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 0.5,
                  borderColor: unit.Facing === '' ? 'rgb(97, 125, 133)' : 'green',
                  borderStyle: 'solid',
                  paddingLeft: 4,
                }}
                value={unit.Facing}
                onChange={(e) => onChangeHandle(e, index, 'Facing')}
              >
                <option value="">Select Facing</option>
                <option value="East">East</option>
                <option value="West">West</option>
              </select>
            </div>
          ))}
        </div>

        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
          <div
            onClick={handleAddClick}
            style={{
              width: '48.6%',
              height: 42,
              borderRadius: 3,
              backgroundColor: 'green',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 0.5,
              borderStyle: 'solid',
              marginTop: 8,
              cursor: 'pointer',
            }}
          >
            <p style={{ fontWeight: 500, color: 'white', textAlign: 'center', marginTop: 8 }}>+ Add More</p>
          </div>

          {units.length > 1 && (
            <div
              onClick={() => removeInputField(units.length - 1)}
              style={{
                width: '48.6%',
                height: 42,
                borderRadius: 3,
                backgroundColor: 'red',
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 0.5,
                borderStyle: 'solid',
                marginTop: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ fontWeight: 500, color: 'white', textAlign: 'center', marginTop: 8 }}>Remove</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
