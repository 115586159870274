import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, TableBody, Typography } from '@mui/material';
import { FaCheck, FaMobileAlt, FaRupeeSign, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import CreateCustomer from './BookCustomer';
import Pagination from '@mui/material/Pagination';

export default function Customer() {

    const [customerRes, setCustomerResponse] = React.useState([])


    const customerObj = () => {
        axios.get('https://buildfastapi.kollagroup.in/create-customer/api/v1/customers').then((res) => {
            setCustomerResponse(res.data)
        })
    }

    const  customerSearch = (text) => {
        axios.post('https://buildfastapi.kollagroup.in/create-customer/api/v1/search', {
            text
        }).then((res) => {
            setCustomerResponse(res.data)
        })
    }

    React.useEffect(() => {
        customerObj()
    }, [])

    const [bookIsCustomer, setIsBookCustomer] = React.useState(false)

    const [response, setResponse] = useState([])

    const [page, setPage] = useState(5)
    const [cpage, setCpage] = useState(1)

    const pagehandle = (e, val) => {
        setCpage(val);
    }

    const indexOflpage = cpage * page;
    const indexOffpage = indexOflpage - page;
    const currentpost = response.slice(indexOffpage, indexOflpage)

    return (
        <div>


            <div className={!bookIsCustomer ? `leadManagementTable`: ''}>
                {
                    !bookIsCustomer && (
                        <Paper elevation={0} >
                            <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                                {/* <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> */}

                                <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                                <div className='card mt-3' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div>
                                        <input onChange={(e) => customerSearch(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                                    </div>
                                    <div>
                                        <Button onClick={() => setIsBookCustomer(true)} style={{ width: '12rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Book Customer</Button>
                                    </div>
                                </div>

                                <Table style={{ position: 'relative', top: '1.3rem', overflow: 'scroll' }}>
                                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                        <TableRow>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Name
                                            </TableCell>
                                            
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Mobile
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Block
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Tower
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Floor
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                               Flat/Unit.No
                                            </TableCell>
                                            {/* <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Customers
                                            </TableCell> */}
                                            {/* <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Instalment3
                                            </TableCell>*/}


                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                            >
                                                Action
                                            </TableCell> 
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {currentpost
                                            .map((row) => {

                                                let color = ''
                                                let widths = ''

                                                if (row.lead === 'Not Customer') {
                                                    color = 'red'
                                                    widths = false
                                                }

                                                if (row.lead === 'Customer') {
                                                    color = 'green'
                                                    widths = true
                                                }


                                                let downPayment = ''
                                                let inst1 = ''
                                                let inst2 = ''
                                                let inst3 = ''

                                                downPayment = row.budget * (30 / 100)
                                                inst1 = row.budget * (20 / 100)
                                                inst2 = row.budget * (20 / 100)
                                                inst3 = row.budget * (30 / 100)


                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57 }}>
                                                            <div style={{ border: `3px solid ${color}`, padding: '0.1rem', alignItems: 'center', justifyContent: 'center', display: 'flex', height: 35, width: 37, borderRadius: '50%', overflow: 'hidden' }}>
                                                                <img src={`${row.flatImage}`} width='35px' />
                                                            </div>
                                                        </TableCell> */}
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12, fontWeight: 'bold' }}>{row.customerName}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', cursor: 'auto', paddingLeft:5, paddingRight:5 }}><FaMobileAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }} />{row.mobileNumber}</button>
                                                        </TableCell>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto',paddingLeft:5, paddingRight:5 }}>{row.block}</button>
                                                        </TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', color: 'rgb(126, 110, 228)', cursor: 'auto', paddingLeft:5, paddingRight:5 }}>{row.Tower}</button>
                                                        </TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', color: 'rgb(126, 110, 228)', cursor: 'auto', paddingLeft:5, paddingRight:5 }}>{row.floor}</button>
                                                        </TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', color: 'rgb(126, 110, 228)', cursor: 'auto', paddingLeft:5, paddingRight:5 }}>{row.unitNo}</button>
                                                        </TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', color: 'rgb(126, 110, 228)', cursor: 'auto', paddingLeft:5, paddingRight:5 }}></button>
                                                        </TableCell>
                                                        {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', color: 'rgb(126, 110, 228)', cursor: 'auto', paddingLeft:5, paddingRight:5 }}><FaRupeeSign style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }} />{inst3} <FaTimes style={{color:'red'}} /></button>
                                                        </TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ fontSize: 12, color: "rgb(126, 110, 228) " }}>
                                                            <div style={{ display: 'flex' }} >
                                                                <button className='shadow' style={{ fontSize: 10.7, border: '2px solid orange', backgroundColor: 'orange', borderRadius: '0.3rem', color: 'white', cursor: 'auto', paddingLeft:5, paddingRight:5 }}>Progress </button>
                                                            </div>
                                                        </TableCell> */}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {
                                    customerRes.length === 0 && (
                                        <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                            <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                            <Typography>0 Customers</Typography>
                                        </Box>
                                    )
                                }
                            </TableContainer>
                        </Paper>
                        
                    )
                }
                {
                    bookIsCustomer && (
                        <CreateCustomer></CreateCustomer>
                    )
                }
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                    <Pagination count={Math.ceil(customerRes.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                </div>
           
        </div>
    );
}