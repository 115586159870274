// import React, { useRef, forwardRef, useEffect } from 'react';
// import ReactToPrint from 'react-to-print';
// import { IoPrintSharp } from "react-icons/io5";
// import './index.css'

// const PrintContent = forwardRef(({ selectedSpecs, handleRemoveSelectedSpec }, ref) => {
//   useEffect(() => {
//     console.log('PrintContent rendered with specs:', selectedSpecs);
//   }, [selectedSpecs]);

//   return (
//     <div ref={ref}>
//       {selectedSpecs.length > 0 ? (
//         selectedSpecs.map((spec, index) => (
//           <div key={index}>
//             <h3>{spec.heading}</h3>
//             <p>{spec.description}</p>
//             <button type="button" onClick={() => handleRemoveSelectedSpec(index)}>Remove</button>
//           </div>
//         ))
//       ) : (
//         <p style={{marginTop:'25px'}}>No specifications selected.</p>
//       )}
//     </div>
//   );
// });

// const PrintComponent = ({ selectedSpecs, handleRemoveSelectedSpec }) => {
//   const componentRef = useRef();

//   useEffect(() => {
//     console.log('PrintComponent rendered with specs:', selectedSpecs);
//   }, [selectedSpecs]);

//   return (
//     <div>
//       <div className='container-printer'>
//       <ReactToPrint
//         trigger={() => <button type="button"  className="print-button">
//         Print Selected Specifications <IoPrintSharp style={{marginLeft:"20px",color:'black',fontSize:'20px'}} /></button>}
//         content={() => componentRef.current}
//       /></div>
    
//       <PrintContent ref={componentRef} selectedSpecs={selectedSpecs} handleRemoveSelectedSpec={handleRemoveSelectedSpec} />
//     </div>
//   );
// };

// export default PrintComponent;


// import React from 'react';
// import { PDFDownloadLink, Document, Page, Text, View } from '@react-pdf/renderer';
// import { IoPrintSharp } from "react-icons/io5";
// import './index.css';

// const PDFContent = ({ selectedSpecs }) => (
//   <Document>
//     <Page>
//       <View>
//         {selectedSpecs.length > 0 ? (
//           selectedSpecs.map((spec, index) => (
//             <View key={index}>
//               <Text>{spec.heading}</Text>
//               <Text>{spec.description}</Text>
//             </View>
//           ))
//         ) : (
//           <Text>No specifications selected.</Text>
//         )}
//       </View>
//     </Page>
//   </Document>
// );

// const PrintComponent = ({ selectedSpecs }) => {
//   return (
//     <div>
//       <div className='container-printer'>
//         <PDFDownloadLink
//           document={<PDFContent selectedSpecs={selectedSpecs} />}
//           fileName="selectedSpecs.pdf"
//         >
//           {({ blob, url, loading, error }) =>
//             loading ? 'Generating PDF...' : (
//               <button type="button" className="print-button">
//                 Generate PDF <IoPrintSharp style={{ marginLeft: "20px", color: 'black', fontSize: '20px' }} />
//               </button>
//             )
//           }
//         </PDFDownloadLink>
//       </div>
//     </div>
//   );
// };

// export default PrintComponent;


import React from 'react';
import { PDFDownloadLink, Document, Page, Text, View } from '@react-pdf/renderer';
import { IoPrintSharp } from "react-icons/io5";
import './index.css';


  const PDFContent = ({ selectedSpecs }) => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.container}>
        <Text style={{textAlign:'center',marginBottom:'20px'}}>Selected Specifications</Text>
          {selectedSpecs.length > 0 ? (
            selectedSpecs.map((spec, index) => (
              <View key={index} style={styles.specification}>
               
                <Text style={styles.heading}>{spec.heading}</Text>
                <Text style={styles.description}>{spec.description}</Text>
              </View>
            ))
          ) : (
            <Text style={styles.noSpecs}>No specifications selected.</Text>
          )}
        </View>
      </Page>
    </Document>
  );
  
  // Define styles object
  const styles = {
    page: {
      padding: 20, 
    },
    container: {
      marginBottom: 20, 
    },
    specification: {
      marginBottom: 15, 
    },
    heading: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 12, 
      color:'rgb(126, 110, 228)',
    },
    description: {
      fontSize: 14,
      lineHeight: 1.5, 
    },
    noSpecs: {
      fontSize: 14,
      fontStyle: 'italic',
    },
  };

const PrintComponent = ({ selectedSpecs }) => {
  return (
    <div>
      {selectedSpecs.length > 0 && (
        <div className="selected-specifications">
          <h2 style={{marginTop:'20px',marginBottom:'20px',textAlign:'center'}}>Selected Specifications</h2>
          <ul>
            {selectedSpecs.map((spec, index) => (
              <li key={index}>
                <strong>{spec.heading}</strong>: {spec.description}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className='container-printer'>
        <PDFDownloadLink
          document={<PDFContent selectedSpecs={selectedSpecs} />}
          fileName="selectedSpecs.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Generating PDF...' : (
              <button type="button" className="print-button">
                Generate PDF <IoPrintSharp style={{ marginLeft: "20px", color: 'black', fontSize: '20px' }} />
              </button>
            )
          }
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default PrintComponent;
