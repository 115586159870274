import '../node_modules/bootstrap/dist/css/bootstrap.css'
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import MainDashboard from './Container/Dashboard/Dashboard/Dashboard';
import UserLoginPage from './Container/Component/Auth/Login/UserLoginPage';
import ForgotPassworEmailSent from './Container/Component/Auth/PasswordSetup/PasswordEmailSend/ForgotPassworEmailSent';
import UserSignupPage from './Container/Component/Auth/Signup/Signup';
import CreateCustomer from './Container/Dashboard/CstmrManagement/Customers';
import LandingPage from './Container/LandingPage/LandingPage';
import CustomerDasboard from './CustomerDashboard/CustomerDashboard';


function App() {
  return (
    <div className="App">
      {
        sessionStorage.getItem('login') && (
          <BrowserRouter>
            <MainDashboard></MainDashboard>
          </BrowserRouter>
        )
      }
      {
        !sessionStorage.getItem('login') && !sessionStorage.getItem('customer') && (
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<UserLoginPage />} />
              <Route path='/forgot-password' element={<ForgotPassworEmailSent />} />
              {/* <Route path='/create-account' element={<UserSignupPage />} /> */}
            </Routes>
          </BrowserRouter>
        )
      }
      {
        sessionStorage.getItem('customer') && (
          <BrowserRouter>
            <Routes>
              <Route path='/customer-dashboard' element={<CustomerDasboard />} />
            </Routes>
          </BrowserRouter>
        )
      }
    </div>

  );
}

export default App;
