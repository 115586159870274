import axios from 'axios'
import React, { useEffect } from 'react'
import { FaAccusoft, FaAdn, FaCheckCircle, FaUsers, FaWindowClose } from 'react-icons/fa'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'Auto',
    color: theme.palette.text.secondary,
}));

const TopCard = () => {

    return (
        <Box sx={{ flexGrow: 1 }}>

            <Grid container spacing={2}>
            <Grid style={{ height: '8rem', marginBottom: '0.8rem', }} item xs={4}>
                    <div className='topCard' >
                        <Item elevation={0} sx={{ height: '8.6rem', position: 'relative' }}>
                            <Typography style={{ marginBottom: 3 }}>Projects</Typography>
                            <div style={{ position: 'absolute', right: '0.8rem', top: '0.7rem', padding: '0.4rem', borderRadius: '0.3rem', backgroundColor: 'rgb(213, 208, 246)', marginBottom:4 }}>
                                <ManageAccountsOutlinedIcon style={{ position: 'relative', color: 'rgb(126, 110, 228)', fontSize:38 }}   > </ManageAccountsOutlinedIcon>
                            </div>
                            <br/>
                            <Typography color='green' style={{fontWeight:'500'}}>32</Typography>
                            <br />
                            <Typography>Total project order</Typography>
                        </Item>
                    </div>
                </Grid>
                <Grid style={{ height: '10rem', marginBottom: '0.8rem' }} item xs={8}>
                    <div className='topCard'>
                        <Item elevation={0} sx={{ height: '8.6rem', position: 'relative' }}>
                            <Typography style={{ marginBottom: 3, fontSize:21 }}>Project Status</Typography>
                            <Grid container spacing={2}>
                                <Grid style={{ height: '8rem', marginBottom: '0.8rem' }} item xs={4}>
                                    <div >
                                        <Item elevation={0} sx={{ height: '5rem', position: 'relative' }}>
                                            <div className='card' style={{ border: 'none', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '10.7rem', position: 'absolute', left: '0rem', bottom: '0.3rem', }}>
                                                <div style={{ padding: '0.4rem', borderRadius: '50%', backgroundColor: 'lightgreen' }}>
                                                    <FaCheckCircle style={{ position: 'relative', color: 'green', fontSize: 22 }} />
                                                </div>
                                                <div>
                                                    <Typography color='green' style={{ fontWeight: '500' }}>32</Typography>
                                                    <Typography style={{ fontSize: 15 }}>Complete Projects</Typography>
                                                </div>
                                            </div>
                                        </Item>
                                    </div>
                                </Grid>
                                <Grid style={{ height: '8rem', marginBottom: '0.8rem' }} item xs={4}>
                                    <div >
                                        <Item elevation={0} sx={{ height: '5rem', position: 'relative' }}>
                                            <div className='card' style={{ border: 'none', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '10.8rem', position: 'absolute', left: '0rem', bottom: '0.3rem', }}>
                                                <div style={{ padding: '0.4rem', borderRadius: '50%', backgroundColor: 'rgb(244, 236, 193)' }}>
                                                    <FaCheckCircle style={{ position: 'relative', color: 'gold', fontSize: 22 }} />
                                                </div>
                                                <div>
                                                    <Typography color='green' style={{ fontWeight: '500', color:'gold' }}>32</Typography>
                                                    <Typography style={{ fontSize: 15 }}>Upcoming Projects</Typography>
                                                </div>
                                            </div>
                                        </Item>
                                    </div>
                                </Grid>
                                <Grid style={{ height: '8rem', marginBottom: '0.8rem' }} item xs={4}>
                                    <div >
                                        <Item elevation={0} sx={{ height: '5rem', position: 'relative' }}>
                                            <div className='card' style={{ border: 'none', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '10.4rem', position: 'absolute', left: '0rem', bottom: '0.3rem', }}>
                                                <div style={{ padding: '0.4rem', borderRadius: '50%', backgroundColor: 'rgb(175, 175, 254)' }}>
                                                    <FaCheckCircle style={{ position: 'relative', color: 'blue', fontSize: 22 }} />
                                                </div>
                                                <div>
                                                    <Typography color='green' style={{ fontWeight: '500', color:'blue' }}>32</Typography>
                                                    <Typography style={{ fontSize: 15 }}>Progress Projects</Typography>
                                                </div>
                                            </div>
                                        </Item>
                                    </div>
                                </Grid>
                            </Grid>
                        </Item>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )

}


export default TopCard